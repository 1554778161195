import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    border: 0,
    '&:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
    },
    '&:first-of-type': {
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    '&:last-of-type': {
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  padding: theme.spacing(0.5, 1),
  fontSize: '0.75rem',
  fontWeight: 'bold',
  minWidth: '32px',
  height: '24px',
}));

const KeyMetricsAggregateButtonGroup = ({ aggregate, setAggregate }) => {
  const handleChange = (event, newAggregate) => {
    if (newAggregate !== null) {
      setAggregate(newAggregate);
    }
  };

  return (
    <StyledToggleButtonGroup
      size="small"
      value={aggregate}
      exclusive
      onChange={handleChange}
      aria-label="aggregation"
    >
      <StyledToggleButton value="quarterly" aria-label="quarterly">
        Q
      </StyledToggleButton>
      <StyledToggleButton value="semi-annual" aria-label="semi-annual">
        SA
      </StyledToggleButton>
      <StyledToggleButton value="annual" aria-label="annual">
        A
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default KeyMetricsAggregateButtonGroup;
