import { TrashIcon } from '@heroicons/react/24/solid';
import React from 'react';

const UploadFileList = ({ files, handleRemoveFile, upload_labels }) =>
  files.length > 0 && (
    <div className="mb-6">
      <h2 className="text-xl font-semibold mb-4">
        {upload_labels.SELECTED_FILES}
      </h2>
      <ul className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg shadow-inner">
        {files.map((file, index) => (
          <li key={index} className="flex justify-between items-center mb-2">
            <span>{file.name}</span>
            <button
              onClick={() => handleRemoveFile(index)}
              className="text-red-500"
            >
              <TrashIcon className="h-5 w-5" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );

export default UploadFileList;
