import {
  Close as CloseIcon,
  ContentCopy,
  Info as InfoIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  ABOUT_MODAL_TITLE,
  APP_NAME,
  CLOSE_BUTTON_TEXT,
  COPYRIGHT_MSG,
  CURRENT_VERSION,
  RELEASE_NOTES,
  RELEASE_NOTES_TITLE,
  SYSTEM_INFO_COPIED_TOOLTIP,
  SYSTEM_INFO_COPY_TOOLTIP,
} from '../../labels/about_labels';

const SystemInfo = ({ onCopy }) => {
  const [systemInfo, setSystemInfo] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const environment =
      process.env.NODE_ENV === 'production' ? 'Production' : 'Development';
    const browserInfo = `${navigator.userAgent}`;
    const info = `${environment} v${CURRENT_VERSION}\nBrowser: ${browserInfo}`;
    setSystemInfo(info);
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(systemInfo).then(() => {
      setCopied(true);
      onCopy();
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-700 p-2 rounded mb-4">
      <Typography className="text-sm text-gray-700 dark:text-gray-300 mr-2">
        {systemInfo.split('\n').map((line, index) => (
          <span key={index} className="block">
            {line}
          </span>
        ))}
      </Typography>
      <Tooltip
        title={copied ? SYSTEM_INFO_COPIED_TOOLTIP : SYSTEM_INFO_COPY_TOOLTIP}
        arrow
      >
        <IconButton
          onClick={handleCopy}
          size="small"
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <ContentCopy fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const AboutMenuItem = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopy = () => {
    console.log('System info copied to clipboard');
  };

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          {ABOUT_MODAL_TITLE}
        </Typography>
        <InfoIcon
          fontSize="small"
          sx={{ color: theme => theme.palette.text.secondary }}
        />
      </MenuItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="about-modal-title"
        aria-describedby="about-modal-description"
      >
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-lg max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <Typography
              id="about-modal-title"
              variant="h6"
              component="h2"
              className="text-gray-900 dark:text-gray-100"
            >
              {ABOUT_MODAL_TITLE} {APP_NAME}
            </Typography>

            <IconButton
              onClick={handleClose}
              size="small"
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <CloseIcon />
            </IconButton>
          </div>

          <Divider className="mb-4" />

          <SystemInfo onCopy={handleCopy} />

          <Typography
            variant="body2"
            className="mb-4 text-gray-700 dark:text-gray-300"
          >
            {COPYRIGHT_MSG}
          </Typography>
          <Divider style={{ marginBottom: '2rem' }} />

          <Typography
            variant="subtitle2"
            className="text-gray-900 dark:text-gray-100 font-semibold"
            style={{ marginBottom: '0.5rem' }}
          >
            {RELEASE_NOTES_TITLE}
          </Typography>
          <List className="max-h-96 overflow-y-auto mb-4 border border-gray-200 dark:border-gray-700 rounded">
            {RELEASE_NOTES.map((note, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider />}
                <ListItem className="py-2">
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        className="text-gray-900 dark:text-gray-100"
                      >
                        {note.version}{' '}
                        <span className="text-gray-500 dark:text-gray-400 text-xs">
                          ({note.date})
                        </span>
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        className="text-gray-600 dark:text-gray-400 mt-1"
                      >
                        {note.description}
                      </Typography>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>

          <div className="flex justify-center items-center mt-2">
            <button
              onClick={handleClose}
              className="px-3 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 transition-colors"
            >
              {CLOSE_BUTTON_TEXT}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AboutMenuItem;
