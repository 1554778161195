import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';

const PortfolioEntitiesCard = ({ title, entities }) => {
  const getIcon = type => {
    switch (type) {
      case 'company':
        return <BusinessIcon fontSize="small" />;
      case 'account':
        return <AccountBalanceIcon fontSize="small" />;
      case 'project':
        return <AssignmentIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const totalEntities = entities.length;

  const renderEntityItem = (entity, index) => (
    <React.Fragment key={entity.id || index}>
      {index > 0 && <Divider variant="fullWidth" component="li" />}
      <ListItem disablePadding>
        <ListItemIcon sx={{ minWidth: 30 }}>
          {getIcon(entity.type)}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2" noWrap>
              {entity.name}
            </Typography>
          }
          secondary={
            <Box component="span" display="flex" justifyContent="space-between">
              <Typography variant="caption" color="text.secondary">
                {entity.type}
              </Typography>
              {entity.value && (
                <Typography variant="caption" fontWeight="medium">
                  {parseFloat(entity.value).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              )}
            </Box>
          }
        />
      </ListItem>
    </React.Fragment>
  );

  return (
    <Card
      elevation={2}
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="h4" fontWeight="bold">
            {totalEntities}
          </Typography>
        </Box>
        {entities && entities.length > 0 && (
          <Box
            sx={{
              mt: 1,
              maxHeight: '150px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '2px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            <List dense disablePadding>
              {entities.map((entity, index) => renderEntityItem(entity, index))}
            </List>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default PortfolioEntitiesCard;
