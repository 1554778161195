import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import * as Papa from 'papaparse';
import React, { useCallback, useEffect, useState } from 'react';

import { fetchCurrentUserProfile, fetchUserAccounts } from '../api/fetchers';
import { uploadFiles } from '../api/upload';
import DashboardLayout from '../components/layouts/DashboardLayout';
import Loading from '../components/ui-modals/LoadingUpload';
import Toast from '../components/ui-modals/ToastMessage';
import useTheme from '../hooks/useTheme';
import { upload_labels } from '../labels/upload_labels';
import { csv_data_example } from '../shared/constants';
import calculateSummary from '../utils/calculateSummary';
import { handleUploadError } from '../utils/errorHandler';
import ActivityUploadTracker from './upload/ActivityUploadTracker';
import PostUploadDialog from './upload/PostUploadDialog';
import UploadDataPreview from './upload/UploadDataPreview';
import { UploadStatusAlert, WarningDialog } from './upload/UploadDialogs';
import UploadFileDropZone from './upload/UploadFileDropZone';
import UploadFileList from './upload/UploadFileList';
import UploadFormatGuideDialog from './upload/UploadFormatGuideDialog';

const UploadPage = () => {
  const [files, setFiles] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [summary, setSummary] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [showDataFormatGuide, setShowDataFormatGuide] = useState(false);
  const [refreshActivityTracker, setRefreshActivityTracker] = useState(0);
  const [showPostUploadDialog, setShowPostUploadDialog] = useState(false);
  const [projectIdsForNotification, setProjectIdsForNotification] = useState(
    [],
  );
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const { theme } = useTheme();

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const fetchCurrentUserAndAccount = useCallback(async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const userAccounts =
        (await fetchUserAccounts(currentUserProfile.id)) || [];
      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUserAndAccount();
  }, [fetchCurrentUserAndAccount]);

  const handleUserChange = useCallback(async user => {
    setSelectedUser(user);
    try {
      const userAccounts = (await fetchUserAccounts(user.id)) || [];
      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount ? defaultAccount : '');
    } catch (error) {
      console.error('Error fetching user accounts:', error);
    }
  }, []);

  // Handle file selection
  const handleFileChange = e => {
    if (parsedData.length > 0) {
      setShowWarning(true);
      setNewFiles([...e.target.files]);
    } else {
      setFiles([...e.target.files]);
      handleParseFiles([...e.target.files]);
    }
  };

  // Handle drag and drop
  const handleDrop = e => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files).filter(file =>
      file.name.endsWith('.csv'),
    );
    if (parsedData.length > 0) {
      setShowWarning(true);
      setNewFiles(droppedFiles);
    } else {
      setFiles(droppedFiles);
      handleParseFiles(droppedFiles);
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleRemoveFile = index => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      handleClearUploads();
    } else {
      handleParseFiles(updatedFiles);
    }
  };

  // Parse files and validate data
  const handleParseFiles = async selectedFiles => {
    if (selectedFiles.length === 0) {
      setToast({
        open: true,
        message: 'Please select at least one file.',
        severity: 'warning',
      });
      return;
    }

    setIsLoading(true);
    setUploadStatus(null);

    try {
      const parsedResults = await Promise.all(selectedFiles.map(parseFile));
      setParsedData(parsedResults);
      setIsReviewing(true);
      const summaryData = calculateSummary(parsedResults);
      setSummary(summaryData);
    } catch (error) {
      handleUploadError(error, setUploadStatus);
    } finally {
      setIsLoading(false);
    }
  };

  const parseFile = file => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: 'greedy',
        dynamicTyping: true,
        complete: results => {
          if (results.errors.length) {
            reject(results.errors);
          } else {
            // Normalize keys to lowercase
            const dataWithLowercasedKeys = results.data.map(row => {
              const lowercasedRow = {};
              for (const key in row) {
                lowercasedRow[key.toLowerCase()] = row[key];
              }
              return lowercasedRow;
            });

            // Perform validation on data with normalized keys
            const validationErrors = validateData(dataWithLowercasedKeys);
            if (validationErrors.length > 0) {
              reject(validationErrors);
            } else {
              resolve({ fileName: file.name, data: dataWithLowercasedKeys });
            }
          }
        },
        error: error => reject(error),
      });
    });
  };

  const validateData = data => {
    const requiredColumns = ['kpi_slug', 'kpi_name', 'project_id'];
    const errors = [];

    // Create a case-insensitive mapping of headers
    const headerMap = Object.keys(data[0] || {}).reduce((acc, header) => {
      acc[header.toLowerCase()] = header;
      return acc;
    }, {});

    // Check if the required columns exist in the CSV (case-insensitive)
    const missingColumns = requiredColumns.filter(
      col =>
        !Object.prototype.hasOwnProperty.call(headerMap, col.toLowerCase()),
    );

    if (missingColumns.length > 0) {
      errors.push(`Missing required columns: ${missingColumns.join(', ')}`);
      return errors; // Return early if columns are missing
    }

    // Check for date columns (any column after the first three)
    const dateColumns = Object.keys(headerMap).slice(3);
    if (dateColumns.length === 0) {
      errors.push(
        'Missing date columns. There should be at least one date column after kpi_slug, kpi_name, and project_id.',
      );
      return errors;
    }

    data.forEach((row, index) => {
      requiredColumns.forEach(column => {
        const actualColumn = headerMap[column.toLowerCase()];
        if (!row[actualColumn] && row[actualColumn] !== 0) {
          errors.push(`Row ${index + 1}: Missing value for '${column}'.`);
        }
      });

      // Check if at least one date column has a value
      const hasDateValue = dateColumns.some(dateCol => {
        const actualDateCol = headerMap[dateCol];
        return row[actualDateCol] !== undefined && row[actualDateCol] !== '';
      });
      if (!hasDateValue) {
        errors.push(`Row ${index + 1}: Missing value for all date columns.`);
      }
    });

    return errors;
  };

  // Handle commit to database
  const handleCommit = async () => {
    setIsLoading(true);

    try {
      const data = await uploadFiles(files);
      if (data) {
        const projectIds = Array.from(
          new Set(
            parsedData.flatMap(file => file.data.map(row => row.project_id)),
          ),
        );
        console.log('Extracted projectIds:', projectIds);
        setProjectIdsForNotification(projectIds);

        updateStateAfterUpload(data);
        setRefreshActivityTracker(prev => prev + 1);
        setShowPostUploadDialog(true);
      } else {
        handleUploadError(
          new Error('No data returned from API'),
          setUploadStatus,
        );
      }
    } catch (error) {
      handleUploadError(error, setUploadStatus);
    } finally {
      setIsLoading(false);
    }
  };

  const updateStateAfterUpload = data => {
    if (data && data.status === 'success') {
      setSummary(null);
      setFiles([]);
      setParsedData([]);
      setIsReviewing(false);
      setUploadStatus({ success: true, message: data.message });
    } else {
      setUploadStatus({
        success: false,
        message: data ? data.message : 'Unknown error',
      });
    }
  };

  const handleClearUploads = () => {
    setSummary(null);
    setFiles([]);
    setParsedData([]);
    setIsReviewing(false);
    setUploadStatus(null);
  };

  const handleProceedWithNewFiles = () => {
    setShowWarning(false);
    handleClearUploads();
    setFiles(newFiles);
    handleParseFiles(newFiles);
    setNewFiles([]);
  };

  // Open and close data format guide
  const handleOpenDataFormatGuide = () => {
    setShowDataFormatGuide(true);
  };

  const handleCloseDataFormatGuide = () => {
    setShowDataFormatGuide(false);
  };

  // handle toast messages after upload
  const handlePostUploadDialogClose = (message, severity) => {
    setShowPostUploadDialog(false);
    if (message && severity) {
      setToast({
        open: true,
        message,
        severity,
      });
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast({ ...toast, open: false });
  };

  return (
    <DashboardLayout
      title="Admin Upload Panel"
      onUserChange={handleUserChange}
      selectedUser={selectedUser}
    >
      <Box
        sx={{ backgroundColor: 'white', padding: 2, borderRadius: 1, mb: 2 }}
      >
        <Typography variant="h6" color="primary" gutterBottom>
          Manage data uploads and notify investors of project updates.
        </Typography>
      </Box>

      <div className="flex flex-col h-full">
        <div className="flex flex-col lg:flex-row mb-4">
          <div
            className="flex-grow bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-4 lg:mb-0 lg:mr-4 overflow-y-auto"
            style={{ minHeight: '400px' }}
          >
            {/* Upload Section */}
            <div className="flex items-center justify-between mb-6">
              <h1 className="text-2xl font-bold">
                {upload_labels.UPLOAD_CSV_FILES}
              </h1>
              <Tooltip title="Data Format Guide">
                <IconButton onClick={handleOpenDataFormatGuide}>
                  <InformationCircleIcon className="h-6 w-6 text-blue-500" />
                </IconButton>
              </Tooltip>
            </div>

            {/* Data Format Guide Modal */}
            <UploadFormatGuideDialog
              open={showDataFormatGuide}
              onClose={handleCloseDataFormatGuide}
              csvDataExample={csv_data_example}
            />

            {/* Drag and Drop Zone */}
            <UploadFileDropZone
              handleDrop={handleDrop}
              handleDragOver={handleDragOver}
              handleFileChange={handleFileChange}
              upload_labels={upload_labels}
            />

            {/* File Preview and Validation */}
            <UploadFileList
              files={files}
              handleRemoveFile={handleRemoveFile}
              upload_labels={upload_labels}
            />

            {/* Upload Status */}
            <UploadStatusAlert
              uploadStatus={uploadStatus}
              onClose={() => setUploadStatus(null)}
            />

            {/* Loading Indicator */}
            {isLoading && (
              <Loading message="Processing files, please wait..." />
            )}

            {/* Warning Dialog */}
            <WarningDialog
              open={showWarning}
              onClose={() => setShowWarning(false)}
              onProceed={handleProceedWithNewFiles}
              upload_labels={upload_labels}
            />

            {/* Notification Dialog */}
            <PostUploadDialog
              open={showPostUploadDialog}
              onClose={handlePostUploadDialogClose}
              projectIds={projectIdsForNotification}
            />

            <Toast
              open={toast.open}
              message={toast.message}
              severity={toast.severity}
              onClose={handleCloseToast}
            />
          </div>
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <ActivityUploadTracker refresh={refreshActivityTracker} />
          </div>
        </div>

        {/* Data Preview */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <UploadDataPreview
            isReviewing={isReviewing}
            parsedData={parsedData}
            summary={summary}
            upload_labels={upload_labels}
            handleCommit={handleCommit}
            handleClearUploads={handleClearUploads}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default UploadPage;
