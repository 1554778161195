const dataGridStyles = {
  root: {
    backgroundColor: 'var(--bg-secondary)',
    color: 'var(--foreground)',
  },
  cell: {
    borderBottom: '1px solid var(--border-color)',
  },
  columnHeader: {
    backgroundColor: 'var(--bg-secondary)',
    color: 'var(--foreground)',
    borderBottom: '1px solid var(--border-color)',
  },
  columnHeaders: {
    backgroundColor: 'var(--bg-secondary)',
    color: 'var(--foreground)',
    borderBottom: '1px solid var(--border-color)',
  },
  filler: {
    backgroundColor: 'var(--bg-secondary)',
    color: 'var(--foreground)',
    borderBottom: '1px solid var(--border-color)',
  },
  footerContainer: {
    backgroundColor: 'var(--bg-secondary)',
    color: 'var(--foreground)',
    borderTop: '1px solid var(--border-color)',
  },
  footerCell: {
    backgroundColor: 'var(--bg-secondary)',
    color: 'var(--foreground)',
    borderTop: '1px solid var(--border-color)',
  },
  rowLastVisible: {
    backgroundColor: 'var(--bg-secondary) !important',
    color: 'var(--foreground) !important',
    borderTop: '1px solid var(--border-color) !important',
  },
  aggregationColumnHeader: {
    backgroundColor: 'var(--bg-secondary) !important',
    color: 'var(--foreground) !important',
  },
  rowAggregation: {
    backgroundColor: 'var(--bg-secondary) !important',
    color: 'var(--foreground) !important',
    borderTop: '1px solid var(--border-color) !important',
  },
  toolbarContainer: {
    backgroundColor: 'var(--bg-secondary)',
    color: 'var(--foreground)',
  },
  pinnedRows: {
    backgroundColor: 'var(--bg-secondary)',
    color: 'var(--foreground)',
  },
  overlay: {
    backgroundColor: 'var(--bg-secondary)',
  },
  cellEditing: {
    backgroundColor: 'var(--bg-secondary)',
    color: 'var(--foreground)',
  },
  buttonRoot: {
    color: 'var(--foreground)',
  },
  checkboxRoot: {
    color: 'var(--foreground)',
  },
  checkbox: {
    color: 'var(--foreground) !important',
  },
  tablePaginationRoot: {
    color: 'var(--foreground)',
  },
  tablePaginationSelectIcon: {
    color: 'var(--foreground)',
  },
  tablePaginationActions: {
    iconButtonRoot: {
      color: 'var(--foreground)',
    },
  },
  menuIcon: {
    color: 'var(--foreground)',
  },
  iconButtonContainer: {
    color: 'var(--foreground)',
  },
  columnHeaderTitleContainer: {
    color: 'var(--foreground)',
  },
  checkboxInput: {
    color: 'var(--foreground) !important',
  },
};

export default dataGridStyles;
