import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import {
  fetchCurrentUserProfile,
  fetchUserAccounts,
  fetchUserCompanies,
  fetchUsers,
} from '../api/fetchers';
import AdminAccountCompanyPanel from '../components/admin/AdminAccountCompanyPanel';
import AdminAccountPanel from '../components/admin/AdminAccountPanel';
import AdminCompanyPanel from '../components/admin/AdminCompanyPanel';
import AdminProjectCompanyPanel from '../components/admin/AdminProjectCompanyPanel';
import AdminUserAccountPanel from '../components/admin/AdminUserAccountPanel';
import AdminUserPanel from '../components/admin/AdminUserPanel';
import AuthStatus from '../components/auth/AuthStatus';
import DashboardLayout from '../components/layouts/DashboardLayout';
import useTheme from '../hooks/useTheme';

const Settings = () => {
  const [users, setUsers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  useEffect(() => {
    const loadData = async () => {
      try {
        const [usersData, accountsData, companiesData] = await Promise.all([
          fetchUsers(),
          fetchUserAccounts(),
          fetchUserCompanies(),
        ]);
        setUsers(usersData);
        setAccounts(accountsData);
        setCompanies(companiesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    loadData();
  }, []);

  const fetchCurrentUserAndAccount = useCallback(async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const userAccounts =
        (await fetchUserAccounts(currentUserProfile.id)) || [];
      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUserAndAccount();
  }, [fetchCurrentUserAndAccount]);

  const handleUserChange = useCallback(async user => {
    setSelectedUser(user);
    try {
      const userAccounts = await fetchUserAccounts(user.id);
      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user accounts:', error);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <DashboardLayout
      title="Admin Settings Panel"
      onUserChange={handleUserChange}
      selectedUser={selectedUser}
    >
      <Box
        sx={{ backgroundColor: 'white', padding: 2, borderRadius: 1, mb: 2 }}
      >
        <Typography variant="h6" color="primary" gutterBottom>
          Manage users, accounts, companies, and project stakes.
        </Typography>
      </Box>

      <Box className="bg-white dark:bg-card p-4 rounded shadow" mb={3}>
        <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label="Users" />
          <Tab label="Accounts" />
          <Tab label="Companies" />
          <Tab label="User-Accounts" />
          <Tab label="Account-Companies" />
          <Tab label="Company-Projects" />
        </Tabs>
        <Box sx={{ height: 'calc(100% - 48px)' }}>
          {selectedTab === 0 && <AdminUserPanel />}
          {selectedTab === 1 && <AdminAccountPanel />}
          {selectedTab === 2 && <AdminCompanyPanel />}
          {selectedTab === 3 && <AdminUserAccountPanel />}
          {selectedTab === 4 && <AdminAccountCompanyPanel />}
          {selectedTab === 5 && <AdminProjectCompanyPanel />}
        </Box>
      </Box>
      <AuthStatus />
    </DashboardLayout>
  );
};

export default Settings;
