import { PieChart } from '@mui/x-charts/PieChart';
import React, { useState } from 'react';

import '../../styles/index.css';

const valueFormatter = item => {
  if (item && item.value !== undefined) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(item.value);
  }
  return '$0';
};

const EquityDistributionPieChart = ({ portfolioData }) => {
  const [activeTab, setActiveTab] = useState('equity');

  const getProjectValue = (project, isReturns) => {
    if (isReturns) {
      const equityDist =
        project.kpis.find(kpi => kpi.slug === 'PR_EQTDST')?.value || 0;
      const profitDist =
        project.kpis.find(kpi => kpi.slug === 'PR_PFTDST')?.value || 0;
      const interestDist =
        project.kpis.find(kpi => kpi.slug === 'PR_INTDST')?.value || 0;
      return (
        parseFloat(equityDist) +
        parseFloat(profitDist) +
        parseFloat(interestDist)
      );
    } else {
      const equityContribution = project.kpis.find(
        kpi => kpi.slug === 'PR_EQTCNT',
      );
      return equityContribution ? parseFloat(equityContribution.value) : 0;
    }
  };

  const projectData = portfolioData
    .flatMap(company =>
      company.projects.map(project => ({
        id: `${company.id}-${project.id}`,
        value: getProjectValue(project, activeTab === 'returns'),
        label: `${company.name} - ${project.name}`,
      })),
    )
    .filter(project => project.value > 0);

  const companyData = portfolioData
    .map(company => ({
      id: company.id,
      value: company.projects.reduce(
        (sum, project) =>
          sum + getProjectValue(project, activeTab === 'returns'),
        0,
      ),
      label: company.name,
    }))
    .filter(company => company.value > 0);

  return (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
      <div className="mb-4">
        <div className="flex border-b justify-center">
          <button
            className={`py-2 px-4 ${activeTab === 'equity' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            onClick={() => setActiveTab('equity')}
          >
            Equity Contribution
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'returns' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            onClick={() => setActiveTab('returns')}
          >
            Capital Returns
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center">
        {/* <div className="w-2/3 ml-2"> Added margin-left
          <h3 className="text-md font-semibold mb-2">Project-level Allocation</h3>
          <PieChart
            series={[
              {
                data: projectData,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30 },
                valueFormatter,
              },
            ]}
            height={300}
            width={400}
            slotProps={{
              legend: { hidden: true },
            }}
            sx={{ padding: '10px' }}}
          />
        </div> */}
        <h3 className="text-md font-semibold mb-2">Company-level Allocation</h3>
        <PieChart
          series={[
            {
              data: companyData,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30 },
              valueFormatter,
            },
          ]}
          height={400}
          width={400}
          margin={{ top: 20, right: 20, left: 20 }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
          sx={{ padding: '10px' }}
        />
      </div>
    </div>
  );
};

export default EquityDistributionPieChart;
