import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext } from 'react';

import ThemeContext from '../../contexts/ThemeContext';
import '../../styles/index.css';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
  marginBottom: theme.spacing(2),
  '& .MuiInputBase-root': {
    borderRadius: '4px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
  },
  '& .MuiSelect-icon': {
    right: 8,
    color: theme.palette.mode === 'dark' ? 'var(--border-light)' : 'inherit',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: '4px 8px',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'var(--bg-secondary-dark)'
        : 'transparent',
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    fontSize: '0.75rem',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    borderColor:
      theme.palette.mode === 'dark' ? '#ffffff' : 'var(--border-dark)',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '0.75rem',
}));

const KpiDropdownTiny = ({
  kpiNames,
  selectedKpiSlug,
  onKpiSelect,
  label,
  selectorIndex,
}) => {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <StyledFormControl fullWidth>
      <Typography
        variant="caption"
        sx={{
          marginBottom: '4px',
          color: isDarkMode ? 'var(--border-light)' : 'textSecondary',
        }}
      >
        {label}
      </Typography>

      <StyledSelect
        value={selectedKpiSlug}
        onChange={event => onKpiSelect(selectorIndex, event.target.value)}
      >
        {Array.isArray(kpiNames) && kpiNames.length > 0 ? (
          kpiNames.map(kpi => (
            <StyledMenuItem key={kpi.slug} value={kpi.slug}>
              {kpi.name}
            </StyledMenuItem>
          ))
        ) : (
          <StyledMenuItem disabled>No KPI data available</StyledMenuItem>
        )}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default KpiDropdownTiny;
