import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { app_paths } from '../App';
import {
  fetchCurrentUserProfile,
  fetchUserAccounts,
  fetchUserProjects,
} from '../api/fetchers';
import AdminProjectPanel from '../components/admin/AdminProjectPanel';
import DashboardLayout from '../components/layouts/DashboardLayout';
import useTheme from '../hooks/useTheme';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const fetchCurrentUserAndProjects = useCallback(async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const userProjects = await fetchUserProjects(currentUserProfile.id);
      setProjects(userProjects);

      const userAccounts = await fetchUserAccounts(currentUserProfile.id);
      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');

      return currentUserProfile;
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUserAndProjects();
  }, [fetchCurrentUserAndProjects]);

  const handleUserChange = useCallback(async user => {
    setSelectedUser(user);
    setLoading(true);
    try {
      const userProjects = await fetchUserProjects(user.id);
      setProjects(userProjects);

      const userAccounts = await fetchUserAccounts(user.id);
      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'project_type', headerName: 'Project Type', width: 150 },
    { field: 'project_sub_type', headerName: 'Project Subtype', width: 150 },
    {
      field: 'start_date',
      headerName: 'Start Date',
      width: 120,
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      width: 120,
    },
    {
      field: 'disclaimer',
      headerName: 'Disclaimer',
      width: 200,
    },
  ];

  return (
    <DashboardLayout
      title="Admin Project Panel"
      onUserChange={handleUserChange}
      selectedUser={selectedUser}
    >
      <Box
        sx={{ backgroundColor: 'white', padding: 2, borderRadius: 1, mb: 2 }}
      >
        <Typography variant="h6" color="primary" gutterBottom>
          View user projects or manage all projects using the admin panel.
        </Typography>
      </Box>

      <Box
        className="bg-white dark:bg-card p-4 rounded shadow flex-grow"
        mb={3}
      >
        <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label="User Projects" />
          <Tab label="Projects Admin" />
        </Tabs>

        <Box sx={{ height: 'calc(100% - 48px)' }}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 400,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {selectedTab === 0 && (
                <Box sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={projects}
                    columns={columns}
                    pageSize={25}
                    rowsPerPageOptions={[10, 25, 50]}
                    checkboxSelection
                    disableSelectionOnClick
                  />
                </Box>
              )}

              {selectedTab === 1 && (
                <AdminProjectPanel
                  projects={projects}
                  setProjects={setProjects}
                  userId={selectedUser?.id}
                />
              )}
            </>
          )}
        </Box>
      </Box>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(app_paths.PROJECTS_DASHBOARD_PATH)}
        >
          View Project Dashboard
        </Button>
      </Box>
    </DashboardLayout>
  );
};

export default Projects;
