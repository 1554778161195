import { Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import React, { useMemo } from 'react';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#8884D8',
  '#82CA9D',
  '#A4DE6C',
  '#D0ED57',
];

const InvestmentStakeholdersChart = ({ data }) => {
  const theme = useTheme();

  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => b.value - a.value);
  }, [data]);

  if (!data || data.length === 0) {
    return (
      <Paper
        elevation={3}
        sx={{ p: 3, bgcolor: theme.palette.background.paper }}
      >
        <Typography variant="h6" gutterBottom>
          No data available
        </Typography>
        <Typography>Please provide valid data for the chart.</Typography>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 3, bgcolor: theme.palette.background.paper }}>
      <Typography variant="h6" gutterBottom>
        Investment Stakeholders
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: ['column', 'row'], height: 500 }}
      >
        <Box sx={{ width: ['100%', '40%'], height: '100%' }}>
          <PieChart
            series={[
              {
                data: sortedData.map((item, index) => ({
                  id: index,
                  value: item.value,
                  label: item.name,
                  color: COLORS[index % COLORS.length],
                })),
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30 },
              },
            ]}
            height={500}
            slotProps={{
              legend: { hidden: true },
            }}
          />
        </Box>
        <Box sx={{ width: ['100%', '60%'], height: '100%' }}>
          <BarChart
            dataset={sortedData}
            yAxis={[{ scaleType: 'band', dataKey: 'name' }]}
            xAxis={[
              {
                scaleType: 'linear',
                max: 100,
                steps: 5,
                tickNumber: 6,
                formatLabel: value => `${value}%`,
              },
            ]}
            series={[
              {
                dataKey: 'value',
                valueFormatter: v => `${v.toFixed(2)}%`,
              },
            ]}
            layout="horizontal"
            height={500}
            margin={{ left: 200 }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default InvestmentStakeholdersChart;
