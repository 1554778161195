import { ArrowBackIos, ArrowForwardIos, Home } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FooterWithLinks } from '../labels/footers';
import { MainHeader } from '../labels/headers';
import { legalLabels } from '../labels/legal_labels';

const PrivacyPolicy = () => {
  const [selectedSection, setSelectedSection] = useState(
    legalLabels.privacyPolicy.sections[0],
  );
  const lastUpdated = legalLabels.lastUpdated;

  const sections = legalLabels.privacyPolicy.sections;

  const handleNext = () => {
    const currentIndex = sections.indexOf(selectedSection);
    const nextIndex = (currentIndex + 1) % sections.length;
    setSelectedSection(sections[nextIndex]);
  };

  const handlePrevious = () => {
    const currentIndex = sections.indexOf(selectedSection);
    const previousIndex =
      (currentIndex - 1 + sections.length) % sections.length;
    setSelectedSection(sections[previousIndex]);
  };

  const renderContent = () => {
    const content = legalLabels.privacyPolicy.content[selectedSection];
    if (typeof content === 'string') {
      return (
        <>
          <Typography variant="h5" gutterBottom color="white">
            {selectedSection}
          </Typography>
          <Typography color="white">{content}</Typography>
        </>
      );
    } else if (Array.isArray(content)) {
      return (
        <>
          <Typography variant="h5" gutterBottom color="white">
            {selectedSection}
          </Typography>
          {content.map((item, index) => (
            <Typography key={index} color="white">
              • {item}
            </Typography>
          ))}
        </>
      );
    } else if (typeof content === 'object') {
      return (
        <>
          <Typography variant="h5" gutterBottom color="white">
            {selectedSection}
          </Typography>
          {Object.entries(content).map(([key, value]) => (
            <React.Fragment key={key}>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                color="white"
              >
                {key}:
              </Typography>
              <Typography color="white">{value}</Typography>
            </React.Fragment>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white relative overflow-hidden flex flex-col">
      {/* Background gradient */}
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-indigo-500 via-indigo-500 to-teal-300 z-0"></div>
      </div>

      <MainHeader />

      {/* Main Content */}
      <main className="flex-grow relative z-10">
        <Box
          sx={{
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '24px',
            position: 'relative',
            zIndex: 20,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={9}>
              <Typography
                variant="h4"
                gutterBottom
                fontWeight="bold"
                color="white"
              >
                Privacy Policy
              </Typography>
              <Typography
                variant="subtitle1"
                color="yellow"
                marginLeft={2}
                gutterBottom
              >
                Last Updated: {lastUpdated}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  component={Link}
                  to="/"
                  startIcon={<Home />}
                  color="inherit"
                  size="small"
                >
                  Back to Home
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <List component="nav" sx={{ borderRight: '1px solid #e0e0e0' }}>
                {sections.map(text => (
                  <ListItem
                    button
                    key={text}
                    selected={selectedSection === text}
                    onClick={() => setSelectedSection(text)}
                    sx={{
                      borderLeft:
                        selectedSection === text ? '2px solid #009688' : 'none',
                      backgroundColor:
                        selectedSection === text
                          ? 'rgba(0, 150, 136)'
                          : 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 150, 136)',
                      },
                    }}
                  >
                    <ListItemText
                      primary={text}
                      primaryTypographyProps={{
                        color: 'white',
                        fontWeight:
                          selectedSection === text ? 'bold' : 'normal',
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={9}>
              <Paper
                elevation={3}
                sx={{
                  padding: '24px',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRight: '1px solid rgba(255, 255, 255, 0.1)',
                  position: 'relative',
                  color: 'white', // Keep text color white for better contrast
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    display: 'flex',
                  }}
                >
                  <IconButton
                    onClick={handlePrevious}
                    size="small"
                    sx={{ color: 'white' }}
                  >
                    <ArrowBackIos fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={handleNext}
                    size="small"
                    sx={{ color: 'white' }}
                  >
                    <ArrowForwardIos fontSize="small" />
                  </IconButton>
                </Box>
                {renderContent()}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </main>

      <FooterWithLinks />
    </div>
  );
};

export default PrivacyPolicy;
