import { Box, Paper, Typography } from '@mui/material';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useState } from 'react';

import { fetchKPIsForProject } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';

const DataTablePage = ({ projectId, title }) => {
  const [projectKPIs, setProjectKPIs] = useState([]);
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchKPIsForProject(projectId);
        setProjectKPIs(response);
      } catch (error) {
        console.error('Error fetching project KPIs:', error);
      }
    };
    loadData();
  }, [projectId]);

  const formatCurrency = value => {
    if (value == null) return '-';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const rows = useMemo(() => {
    const groupedData = projectKPIs.reduce((acc, kpi) => {
      const date = new Date(kpi.date);
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();
      const key = `${kpi.kpi_name}`;
      if (!acc[key]) {
        acc[key] = { id: key, kpi_name: kpi.kpi_name, sparklineData: [] };
      }
      acc[key][`${month} ${year}`] = parseFloat(kpi.amount_value);
      acc[key].sparklineData.push(parseFloat(kpi.amount_value) || 0);
      return acc;
    }, {});
    return Object.values(groupedData);
  }, [projectKPIs]);

  const columns = useMemo(() => {
    if (rows.length === 0) return [];
    const dateColumns = Object.keys(rows[0])
      .filter(
        key => key !== 'id' && key !== 'kpi_name' && key !== 'sparklineData',
      )
      .sort((a, b) => new Date(a) - new Date(b))
      .map(key => ({
        field: key,
        headerName: key,
        width: 120,
        renderCell: params => formatCurrency(params.value),
      }));
    return [
      { field: 'kpi_name', headerName: 'KPI', width: 200, pinned: 'left' },
      {
        field: 'sparkline',
        headerName: 'Snapshot',
        width: 150,
        renderCell: params => {
          const data = params.row?.sparklineData ?? [];

          if (!Array.isArray(data)) {
            return <Typography variant="body2">Invalid Data</Typography>;
          }

          if (data.length === 0) {
            return <Typography variant="body2">No Data</Typography>;
          }

          try {
            return (
              <SparkLineChart
                width={100}
                height={40}
                data={data}
                plotType="bar"
              />
            );
          } catch (error) {
            return (
              <Typography variant="body2">Error: {error.message}</Typography>
            );
          }
        },
      },
      ...dateColumns,
    ];
  }, [rows]);

  if (projectKPIs.length === 0) return <div>Loading...</div>;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        bgcolor: isDarkMode ? 'background.paper' : 'background.default',
      }}
    >
      <Typography variant="body2" gutterBottom>
        Project: {projectKPIs[0]?.project_name || 'N/A'}
      </Typography>
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar,
          }}
          initialState={{
            pinnedColumns: { left: ['kpi_name'] },
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              color: isDarkMode ? 'text.primary' : 'text.secondary',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: isDarkMode
                ? 'background.default'
                : 'background.paper',
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default DataTablePage;
