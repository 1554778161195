import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useCallback, useEffect, useState } from 'react';

import {
  fetchAllKPIs,
  fetchCurrentUserProfile,
  fetchReportData,
  fetchUserAccounts,
  fetchUserCompanies,
  fetchUserProjects,
} from '../api/fetchers';
import GeneratePDFButton from '../components/buttons/GeneratePDFButton';
import DashboardLayout from '../components/layouts/DashboardLayout';
import CompaniesFilterDropdown from '../components/ui-elements/CompaniesFilterDropdown';
import KPIFilterDropdown from '../components/ui-elements/KpiFilterDropdown';
import ReportPreview from '../components/ui-elements/ReportPreview';
import { useAdminMode } from '../contexts/AdminModeContext';
import useTheme from '../hooks/useTheme';
import { KPI_SLUGS } from '../shared/constants';
import '../styles/index.css';

const DEFAULT_CUSTOMIZATION_OPTIONS = {
  companyIds: [],
  projectIds: [],
  startDate: new Date(new Date().getFullYear() - 2, 0, 1),
  endDate: new Date(new Date().getFullYear() + 2, 11, 31),
  kpiSlugs: [KPI_SLUGS.PR_EQTCNT, KPI_SLUGS.PR_EQTDST, KPI_SLUGS.PR_PFTDST],
};

const ReportBuilder = ({ userId }) => {
  const { isAdmin, adminModeActive } = useAdminMode();
  const [selectedUser, setSelectedUser] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [reportData, setReportData] = useState(null);
  const [customizationOptions, setCustomizationOptions] = useState(
    DEFAULT_CUSTOMIZATION_OPTIONS,
  );
  const [kpis, setKpis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useTheme();

  const resetToDefaults = useCallback(() => {
    setSelectedCompanyIds([]);
    setCustomizationOptions(DEFAULT_CUSTOMIZATION_OPTIONS);
  }, []);

  const fetchData = useCallback(
    async (userId, options) => {
      if (!userId) return;
      try {
        setIsLoading(true);
        const formattedOptions = {
          companyIds: selectedCompanyIds,
          kpiSlugs: customizationOptions.kpiSlugs,
          startDate: customizationOptions.startDate
            ? customizationOptions.startDate.toISOString().split('T')[0]
            : null,
          endDate: customizationOptions.endDate
            ? customizationOptions.endDate.toISOString().split('T')[0]
            : null,
          projectIds: [],
        };
        const data = await fetchReportData(userId, formattedOptions);
        setReportData(data);
      } catch (error) {
        console.error('Error fetching report data:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedCompanyIds, customizationOptions],
  );

  useEffect(() => {
    if (
      selectedUser &&
      selectedCompanyIds.length > 0 &&
      customizationOptions.kpiSlugs.length > 0
    ) {
      const updatedOptions = {
        ...customizationOptions,
        companyIds: selectedCompanyIds,
      };
      fetchData(selectedUser.id, updatedOptions);
    }
  }, [selectedUser, selectedCompanyIds, customizationOptions, fetchData]);

  const fetchInitialData = useCallback(
    async user => {
      try {
        setLoading(true);
        const currentUserProfile = user || (await fetchCurrentUserProfile());
        setSelectedUser(currentUserProfile);

        const [userCompanies, userProjects, userAccounts, allKPIs] =
          await Promise.all([
            fetchUserCompanies(currentUserProfile.id),
            fetchUserProjects(currentUserProfile.id),
            fetchUserAccounts(currentUserProfile.id),
            fetchAllKPIs(),
          ]);

        setCompanies(userCompanies);
        setProjects(userProjects);
        setAccounts(userAccounts);
        setKpis(allKPIs);

        const defaultKPIs = DEFAULT_CUSTOMIZATION_OPTIONS.kpiSlugs;
        setCustomizationOptions(prevOptions => ({
          ...prevOptions,
          kpiSlugs: defaultKPIs,
        }));

        const defaultAccount =
          userAccounts.find(
            acc => acc.name === `${currentUserProfile.username} Account`,
          ) || userAccounts[0];
        setSelectedAccount(defaultAccount || '');
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setLoading(false);
      }
    },
    [isAdmin, adminModeActive],
  );

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const handleUserChange = useCallback(
    async user => {
      setSelectedUser(user);
      setSelectedCompanyIds([]);
      setReportData(null);
      await fetchInitialData(user);
    },
    [fetchInitialData],
  );

  const handleCompanyFilterChange = companyId => {
    setSelectedCompanyIds(prevSelected => {
      if (prevSelected.includes(companyId)) {
        return prevSelected.filter(id => id !== companyId);
      } else {
        return [...prevSelected, companyId];
      }
    });
  };

  const handleCustomizationChange = newOptions => {
    setCustomizationOptions(prevOptions => {
      const updatedOptions = {
        ...prevOptions,
        ...newOptions,
        companyIds: selectedCompanyIds,
      };
      return updatedOptions;
    });
  };

  useEffect(() => {
    const fetchKPIs = async () => {
      try {
        const allKPIs = await fetchAllKPIs();
        setKpis(allKPIs);

        // Set default KPIs
        setCustomizationOptions(prevOptions => ({
          ...prevOptions,
          kpiSlugs: [
            KPI_SLUGS.PR_EQTCNT,
            KPI_SLUGS.PR_EQTDST,
            KPI_SLUGS.PR_PFTDST,
          ],
        }));
      } catch (error) {
        console.error('Error fetching KPIs:', error);
      }
    };

    fetchKPIs();
  }, []);

  return (
    <DashboardLayout
      title="Report Builder"
      onUserChange={handleUserChange}
      selectedUser={selectedUser}
      selectedAccount={selectedAccount}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          sx={{
            height: 'calc(100vh - 128px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle>
              Select companies and project metrics to generate a report.
            </AlertTitle>
          </Alert>

          {/* Main Content Layout */}
          <Box
            display="flex"
            flexDirection="row"
            gap="16px"
            sx={{
              flexGrow: 1,
              overflow: 'hidden',
              height: 'calc(100% - 48px)',
            }}
          >
            {/* Left Panel: Report Customization */}
            <Box
              sx={{
                width: '25%',
                padding: '16px',
                backgroundColor:
                  theme === 'dark' ? 'var(--background)' : '#fff',
                borderRadius: '8px',
                boxShadow: 'var(--shadow)',
                overflowY: 'auto',
              }}
            >
              <Box display="flex" flexDirection="column" gap="16px">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <CompaniesFilterDropdown
                      companies={companies}
                      selectedCompanies={selectedCompanyIds}
                      onFilterChange={handleCompanyFilterChange}
                    />
                    <KPIFilterDropdown
                      kpis={kpis}
                      selectedKPIs={customizationOptions.kpiSlugs}
                      onFilterChange={selectedKPISlugs =>
                        handleCustomizationChange({
                          kpiSlugs: selectedKPISlugs,
                        })
                      }
                    />
                    <DatePicker
                      label="Start Year"
                      views={['year']}
                      value={customizationOptions.startDate}
                      onChange={date => {
                        if (date) {
                          const startOfYear = new Date(
                            date.getFullYear(),
                            0,
                            1,
                          );
                          handleCustomizationChange({
                            ...customizationOptions,
                            startDate: startOfYear,
                          });
                        } else {
                          handleCustomizationChange({
                            ...customizationOptions,
                            startDate: null,
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} size="small" />
                      )}
                    />
                    <DatePicker
                      label="End Year"
                      views={['year']}
                      value={customizationOptions.endDate}
                      onChange={date => {
                        if (date) {
                          const endOfYear = new Date(
                            date.getFullYear(),
                            11,
                            31,
                          );
                          handleCustomizationChange({
                            ...customizationOptions,
                            endDate: endOfYear,
                          });
                        } else {
                          handleCustomizationChange({
                            ...customizationOptions,
                            endDate: null,
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} size="small" />
                      )}
                    />
                    <GeneratePDFButton
                      userId={selectedUser?.id}
                      options={{
                        ...customizationOptions,
                        companyIds: selectedCompanyIds,
                      }}
                      reportData={reportData}
                      disabled={
                        !reportData || Object.keys(reportData).length === 0
                      }
                    />
                  </>
                )}
              </Box>
            </Box>

            {/* Right Panel: Report Preview */}
            <Box
              sx={{
                width: '75%',
                backgroundColor:
                  theme === 'dark' ? 'var(--background)' : '#fff',
                borderRadius: '8px',
                boxShadow: 'var(--shadow)',
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Report Preview
              </Typography>
              <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    sx={{ opacity: 0.5 }}
                    zIndex={1000}
                  >
                    <CircularProgress />
                  </Box>
                ) : reportData ? (
                  <ReportPreview
                    data={reportData}
                    selectedCompanies={selectedCompanyIds}
                    selectedKPIs={customizationOptions.kpiSlugs}
                    isLoading={isLoading}
                  />
                ) : (
                  <Typography>
                    {selectedCompanyIds.length > 0 &&
                    customizationOptions.kpiSlugs.length > 0
                      ? 'Loading report data...'
                      : 'No report data available. Please select companies and KPIs.'}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </LocalizationProvider>
    </DashboardLayout>
  );
};

export default ReportBuilder;
