import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
} from '@mui/material';
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { fetchUserFinancialModelData } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';
import dataGridStyles from '../../styles/dataGridStyles';
import DataGridQuickFilterButtonGroup from '../buttons/DataGridQuickFilterButtonGroup';

const formatCurrency = value => {
  if (value == null || isNaN(value)) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const formatDataForGrid = (data, selectedKpis) => {
  const formattedData = [];
  const uniqueYears = new Set();
  const uniqueKpis = new Set();

  data.forEach(company => {
    company.projects.forEach(project => {
      project.kpis.forEach(kpi => {
        uniqueKpis.add(kpi.name);

        if (selectedKpis.length === 0 || selectedKpis.includes(kpi.name)) {
          const row = {
            id: `row-${company.id}-${project.id}-${kpi.id}`,
            company: company.name,
            companyId: company.id,
            project: project.name,
            projectId: project.id,
            kpiName: kpi.name,
          };

          Object.entries(kpi.values).forEach(([year, value]) => {
            const yearInt = parseInt(year, 10);
            if (!isNaN(yearInt)) {
              row[yearInt] = value;
              uniqueYears.add(yearInt);
            }
          });

          formattedData.push(row);
        }
      });
    });
  });

  return {
    formattedData,
    uniqueYears: Array.from(uniqueYears).sort((a, b) => a - b),
    uniqueKpis: Array.from(uniqueKpis),
  };
};

const FinancialModelMultiKpi = ({ userId }) => {
  const [data, setData] = useState(null);
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [activeFilter, setActiveFilter] = useState('equity');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [kpis, setKpis] = useState([]);
  const [selectedKpis, setSelectedKpis] = useState(['Equity Contribution']);
  const [yearRange, setYearRange] = useState([2020, 2030]);

  const { theme } = useTheme();
  const apiRef = useGridApiRef();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedData = await fetchUserFinancialModelData(userId);
        setData(fetchedData);

        const uniqueKpisSet = new Set();
        fetchedData.forEach(company => {
          company.projects.forEach(project => {
            project.kpis.forEach(kpi => {
              uniqueKpisSet.add(kpi.name);
            });
          });
        });
        const uniqueKpisArray = Array.from(uniqueKpisSet);
        setKpis(uniqueKpisArray);
        if (selectedKpis.length === 0) {
          setSelectedKpis(uniqueKpisArray.slice(0, 1));
        }

        setCompanies(
          fetchedData.map(company => ({ id: company.id, name: company.name })),
        );
      } catch (error) {
        console.error('Error fetching financial model data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  useEffect(() => {
    if (!data) {
      setFinancialData([]);
      return;
    }

    const { formattedData, uniqueYears } = formatDataForGrid(
      data,
      selectedKpis,
    );
    setFinancialData(formattedData);

    if (uniqueYears.length > 0) {
      setYears(uniqueYears);
      setYearRange([Math.min(...uniqueYears), Math.max(...uniqueYears)]);
    } else {
      setYears([]);
      setYearRange([2020, 2030]);
    }
  }, [data, selectedKpis]);

  const handleKpiChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedKpis(typeof value === 'string' ? value.split(',') : value);
  };

  const handleYearRangeChange = (event, newValue) => {
    setYearRange(newValue);
  };

  const handleQuickFilter = useCallback(filter => {
    setActiveFilter(filter);
    if (filter === 'equity') {
      setSelectedKpis(['Equity Contribution']);
    } else if (filter === 'capitalReturns') {
      setSelectedKpis([
        'Equity Distribution',
        'Profit Distribution',
        'Interest Distribution',
      ]);
    } else if (filter === 'projectData') {
      setSelectedKpis(['Project Revenue', 'Project Costs', 'Project Profit']);
    } else {
      setSelectedKpis([]);
    }
  }, []);

  const handleCompanyFilterChange = useCallback(selectedCompanies => {
    setSelectedCompanies(selectedCompanies);
  }, []);

  const minYear = years.length > 0 ? Math.min(...years) : 2020;
  const maxYear = years.length > 0 ? Math.max(...years) : 2030;

  const columns = useMemo(() => {
    const yearColumns = years
      .filter(year => year >= yearRange[0] && year <= yearRange[1])
      .map(year => ({
        field: year.toString(),
        headerName: year.toString(),
        width: 150,
        renderCell: params => formatCurrency(params.value),
        cellClassName: 'data-cell',
      }));

    return [
      {
        field: 'company',
        headerName: 'Company',
        width: 200,
        hide: true,
      },
      {
        field: 'project',
        headerName: 'Project',
        width: 200,
        hide: true,
      },
      {
        field: 'kpiName',
        headerName: 'KPI',
        width: 200,
      },
      ...yearColumns,
    ];
  }, [years, yearRange]);

  const filteredRows = useMemo(() => {
    return financialData.filter(row => {
      if (selectedCompanies.length === 0) return true;
      return selectedCompanies.includes(row.companyId);
    });
  }, [financialData, selectedCompanies]);

  const getTreeDataPath = useCallback(row => {
    return [row.company, row.project, row.kpiName];
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <DataGridQuickFilterButtonGroup
          activeFilter={activeFilter}
          handleQuickFilter={handleQuickFilter}
          companies={companies}
          selectedCompanies={selectedCompanies}
          onCompanyFilterChange={handleCompanyFilterChange}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="kpi-select-label">Select KPIs</InputLabel>
          <Select
            labelId="kpi-select-label"
            id="kpi-select"
            multiple
            value={selectedKpis}
            label="Select KPIs"
            onChange={handleKpiChange}
            renderValue={selected => selected.join(', ')}
            sx={{ height: 30 }}
          >
            {kpis.map(kpi => (
              <MenuItem key={kpi} value={kpi}>
                {kpi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {years.length > 0 && (
          <Box sx={{ width: 300, mx: 2 }}>
            <InputLabel id="year-range-slider">Select Year Range</InputLabel>
            <Slider
              value={yearRange}
              onChange={handleYearRangeChange}
              valueLabelDisplay="auto"
              min={minYear}
              max={maxYear}
              marks
            />
          </Box>
        )}
      </Box>
      <Box sx={{ height: 600, width: '100%', ...dataGridStyles }}>
        <DataGridPremium
          rows={filteredRows}
          columns={columns}
          loading={loading}
          apiRef={apiRef}
          rowHeight={35}
          defaultGroupingExpansionDepth={-1}
          getTreeDataPath={getTreeDataPath}
          treeData
          groupingColDef={{
            headerName: 'Company / Project / KPI',
            width: 400,
          }}
          initialState={{
            pinnedColumns: { left: ['__tree_data_group__'] },
            columns: {
              columnVisibilityModel: {
                company: false,
                project: false,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          sx={{
            '& .data-cell': {
              backgroundColor: '#f0f0f0',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default FinancialModelMultiKpi;
