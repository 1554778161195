import { Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts/LineChart';
import React, { useEffect, useMemo, useState } from 'react';

import { fetchKPINames } from '../../api/fetchers';
import useKPIData from '../../hooks/useKPIData';
import { project_types } from '../../shared/constants';

const ProjectCashFlowsChart = ({
  projectId,
  aggregation,
  selectedKpis,
  projectType,
}) => {
  const theme = useTheme();
  const [kpiNames, setKpiNames] = useState({});

  const isAssetManagement = projectType === project_types[1]; // 'Income Producing'

  // Fetch KPI names
  useEffect(() => {
    const getKPINames = async () => {
      const names = await fetchKPINames(selectedKpis);
      setKpiNames(names);
    };
    getKPINames();
  }, [selectedKpis]);

  // Fetch data for each KPI
  const kpiData1 = useKPIData(projectId, selectedKpis[0], aggregation);
  const kpiData2 = useKPIData(projectId, selectedKpis[1], aggregation);
  const kpiData3 = useKPIData(projectId, selectedKpis[2], aggregation);

  const kpiDataArray = useMemo(
    () => [kpiData1, kpiData2, kpiData3].filter(Boolean),
    [kpiData1, kpiData2, kpiData3],
  );

  const { chartData, xLabels } = useMemo(() => {
    const allData = kpiDataArray
      .map(({ data }) => data)
      .filter(data => data && data.length > 0);

    if (allData.length === 0) return { chartData: [], xLabels: [] };

    // Create a set of all unique dates across all series
    const allDates = new Set(
      allData.flatMap(data => data.map(item => item.date)),
    );
    const sortedDates = Array.from(allDates).sort(
      (a, b) => new Date(a) - new Date(b),
    );

    // Create a map for each KPI with date as key and amount as value
    const kpiMaps = allData.map(data =>
      Object.fromEntries(data.map(item => [item.date, item.amount_value])),
    );

    // Create series data with 0 for missing dates
    const series = kpiMaps.map((kpiMap, index) => ({
      data: sortedDates.map(date => kpiMap[date] || 0),
      label: kpiNames[selectedKpis[index]] || selectedKpis[index],
    }));

    const xLabels = sortedDates.map(date =>
      new Date(date).toLocaleDateString(),
    );

    return { chartData: series, xLabels };
  }, [kpiDataArray, selectedKpis, kpiNames]);

  const isLoading = kpiDataArray.some(({ loading }) => loading);
  const hasData = chartData.length > 0 && xLabels.length > 0;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!hasData) {
    return <Typography>No cash flow data available</Typography>;
  }

  const colors = [
    '#a8e6cf', // revenue (mint green)
    '#ffaaa5', // costs (soft coral)
    '#4d4d4d', // profits (dark grey)
  ];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {isAssetManagement
          ? 'Income Producing Project Metrics'
          : 'Development Project Metrics'}
      </Typography>
      <Box height={300}>
        <LineChart
          series={chartData.map((series, index) => ({
            ...series,
            color: colors[index % colors.length],
            curve: 'linear',
          }))}
          xAxis={[{ data: xLabels, scaleType: 'band' }]}
          yAxis={[
            {
              tickLabelStyle: {
                fontSize: 12,
              },
            },
          ]}
          minwidth={800}
          height={300}
          margin={{ left: 100, right: 10, bottom: 30 }}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              padding: 0,
            },
          }}
          sx={{
            width: '100%',
            height: '100%',
            '& .MuiChartsLegend-root': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ProjectCashFlowsChart;
