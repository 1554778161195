import InfoIcon from '@mui/icons-material/Info';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { fetchUserCompanyStakes } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const UserCompaniesBadge = ({ userId, projectId }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const [companyStakes, setCompanyStakes] = useState([]);

  useEffect(() => {
    const fetchCompanyStakes = async () => {
      if (!userId || !projectId) return;
      try {
        const response = await fetchUserCompanyStakes(userId);
        setCompanyStakes(
          response.filter(stake => stake.project_id === projectId),
        );
      } catch (error) {
        console.error('Error fetching company stakes:', error);
      }
    };
    fetchCompanyStakes();
  }, [userId, projectId]);

  const formatPercentage = basisPoints => {
    return (basisPoints / 100).toFixed(2) + '%';
  };

  return (
    <Card
      sx={{
        minWidth: 300,
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        backgroundColor: 'var(--card)',
        color: 'var(--foreground)',
        marginTop: '0rem',
      }}
    >
      <CardContent>
        <Typography variant="h6" fontWeight="bold" component="div">
          Project Ownership Stakes by Company
        </Typography>
        {companyStakes.length === 0 ? (
          <Typography
            variant="body2"
            component="div"
            gutterBottom
            style={{ color: 'var(--foreground)' }}
          >
            No stakes found for the selected project.
          </Typography>
        ) : (
          companyStakes.map(stake => (
            <Box
              key={stake.investment_id}
              display="flex"
              flexDirection="column"
              mt={2}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  style={{ color: 'var(--foreground)' }}
                >
                  {stake.company_name}
                </Typography>
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  style={{ color: 'var(--accent-color)' }}
                >
                  {stake.type}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
              >
                <Typography
                  variant="body2"
                  style={{ color: 'var(--foreground)' }}
                >
                  Nominal Ownership:
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: 'var(--foreground)' }}
                >
                  {formatPercentage(stake.nominal_ownership_pct)}
                </Typography>
              </Box>
              {stake.type === 'GP' && (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={0.5}
                  >
                    <Typography
                      variant="caption"
                      style={{ color: 'var(--foreground)' }}
                    >
                      Equity Distribution:
                      <Tooltip title="Actual equity distribution percentage">
                        <InfoIcon
                          fontSize="small"
                          style={{ marginLeft: '2px', verticalAlign: 'middle' }}
                        />
                      </Tooltip>
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{ color: 'var(--muted-text-light)' }}
                    >
                      {formatPercentage(stake.actual_equity_dist_pct)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={0.5}
                  >
                    <Typography
                      variant="caption"
                      style={{ color: 'var(--foreground)' }}
                    >
                      Interest Distribution:
                      <Tooltip title="Actual interest distribution percentage">
                        <InfoIcon
                          fontSize="small"
                          style={{ marginLeft: '2px', verticalAlign: 'top' }}
                        />
                      </Tooltip>
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{ color: 'var(--muted-text-lighter)' }}
                    >
                      {formatPercentage(stake.actual_interest_dist_pct)}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          ))
        )}
      </CardContent>
    </Card>
  );
};

export default UserCompaniesBadge;
