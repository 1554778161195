import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import React from 'react';

import {
  ASSET_MANAGEMENT_KPI_SLUGS,
  PROJECT_KPI_SLUGS,
} from '../../shared/constants';

const columns = [
  {
    field: 'kpi_slug',
    headerName: 'kpi_slug',
    width: 150,
    cellClassName: 'bg-gray-100',
  },
  { field: 'kpi_name', headerName: 'kpi_name', width: 200 },
  { field: 'project_id', headerName: 'project_id', width: 120, type: 'number' },
  { field: 'date1', headerName: '2022/01/01', width: 150, type: 'number' },
  { field: 'date2', headerName: '2023/01/01', width: 150, type: 'number' },
  { field: 'date3', headerName: '2024/01/01', width: 150, type: 'number' },
  { field: 'date4', headerName: '2025/01/01', width: 150, type: 'number' },
];

const rows = [
  {
    id: 1,
    kpi_slug: 'PR_EQTCNT',
    kpi_name: 'Equity Contribution',
    project_id: 3,
    date1: 377466,
    date2: 393653,
    date3: '',
    date4: 0,
  },
  {
    id: 2,
    kpi_slug: 'PR_EQTDST',
    kpi_name: 'Equity Distribution',
    project_id: 3,
    date1: '',
    date2: 304726,
    date3: 481852,
    date4: 714905,
  },
];

const UploadFormatGuideDialog = ({ open, onClose }) => {
  const apiRef = useGridApiRef();

  const handleExport = () => {
    apiRef.current.exportDataAsCsv({
      fileName: 'investor_platform_upload_template',
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Project Data Upload Format Guide
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Ensure your CSV includes the following columns:
        </Typography>
        <Box sx={{ height: 'auto', width: '100%', mb: 2 }}>
          <DataGridPremium
            rows={rows}
            columns={columns}
            hideFooter
            disableSelectionOnClick
            autoHeight
            apiRef={apiRef}
          />
        </Box>
        <Typography variant="body2" color="textSecondary" align="center" mb={2}>
          Note: Date columns continue to the right for as many dates as you
          include. Blank and zero cells are acceptable.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleExport}
          >
            Download Template CSV
          </Button>
        </Box>
        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper
              elevation={3}
              sx={{ p: 2, bgcolor: '#f3f4f6', height: '100%' }}
            >
              <Typography variant="h6" gutterBottom>
                KPI Slug/Name Reference List
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">
                    Development Project KPIs
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <ul>
                    {PROJECT_KPI_SLUGS.map(slug => (
                      <li key={slug}>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ fontStyle: 'italic' }}
                        >
                          {slug}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">
                    Asset Mgmt Project KPIs
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <ul>
                    {ASSET_MANAGEMENT_KPI_SLUGS.map(slug => (
                      <li key={slug}>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ fontStyle: 'italic' }}
                        >
                          {slug}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Upload issues? Common problems:
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <ul>
                <li>
                  <b>Dates should be in recognized date format.</b>
                </li>
                <li>• eg mm/dd/yyyy , yyyy/mm/dd , dd/mm/yyyy</li>
                <li>
                  <i>
                    • note: csv defaults to regional format mm/dd/yyyy on save
                  </i>
                </li>
                <li>
                  <b>Verify column header spelling.</b>
                </li>
                <li>• Include kpi_slug, kpi_name, project_id columns</li>
                <li>
                  <b>Ensure cells containing numbers are not saved as text.</b>
                </li>
                <li>• Select data cells and format type as number</li>
              </ul>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UploadFormatGuideDialog;
