import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, ListItemText, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import '../../styles/index.css';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  fontSize: '0.75rem',
  padding: theme.spacing(0.5, 1),
  minWidth: '32px',
  height: '36px',
}));

const CompaniesFilterDropdown = ({
  companies,
  selectedCompanies,
  onFilterChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (event, companyId) => {
    event.preventDefault();
    event.stopPropagation();
    onFilterChange(companyId);
  };

  return (
    <>
      <StyledButton
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        Company Filters ({selectedCompanies.length})
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'var(--bg-secondary)',
            color: 'var(--foreground)',
          },
        }}
      >
        {companies.map(company => (
          <MenuItem
            key={company.id}
            onClick={event => handleToggle(event, company.id)}
            dense
          >
            <Checkbox
              checked={selectedCompanies.includes(company.id)}
              onChange={event => handleToggle(event, company.id)}
              onClick={event => event.stopPropagation()}
              sx={{
                color: 'var(--foreground)',
                '&.Mui-checked': {
                  color: 'var(--accent-color)',
                },
              }}
            />
            <ListItemText
              primary={company.name}
              sx={{ color: 'var(--foreground)' }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CompaniesFilterDropdown;
