const calculateSummary = parsedResults => {
  let totalEntries = 0;
  let numberOfFiles = parsedResults.length;
  let fileNames = parsedResults.map(result => result.fileName);
  let totalSum = 0;

  parsedResults.forEach(result => {
    result.data.forEach(row => {
      for (let [key, value] of Object.entries(row)) {
        if (key !== 'KPI_Slug' && key !== 'KPI_Name' && key !== 'Project_ID') {
          if (value && !isNaN(value)) {
            totalSum += value;
          }
        }
      }
      totalEntries++;
    });
  });

  return {
    totalEntries,
    numberOfFiles,
    fileNames,
    totalSum,
  };
};

export default calculateSummary;
