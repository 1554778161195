import { axisClasses } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';
import React, { useEffect, useRef, useState } from 'react';

const ProjectTimeline = ({ projects }) => {
  const [chartDimensions, setChartDimensions] = useState({
    width: 800,
    height: 400,
  });
  const containerRef = useRef(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        setChartDimensions({
          width: containerWidth - 40, // Subtract padding
          height: Math.max(400, (projects?.length || 0) * 40), // Adjust height based on number of projects
        });
      }
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions(); // Call once to set initial dimensions

    return () => window.removeEventListener('resize', updateDimensions);
  }, [projects]);

  if (!projects || projects.length === 0) {
    return (
      <div
        ref={containerRef}
        className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow"
      >
        <h2 className="text-lg font-semibold mb-4">
          Portfolio Projects Timeline
        </h2>
        <p>No project data available.</p>
      </div>
    );
  }

  const earliestStart = new Date(
    Math.min(...projects.map(p => new Date(p.start_date))),
  );
  const latestEnd = new Date(
    Math.max(...projects.map(p => new Date(p.end_date))),
  );

  // padding at start
  // earliestStart.setFullYear(earliestStart.getFullYear(), 0, 1);
  // latestEnd.setFullYear(latestEnd.getFullYear(), 11, 31);

  const totalRange = latestEnd.getTime() - earliestStart.getTime();
  const yearRange = latestEnd.getFullYear() - earliestStart.getFullYear() + 1;

  const maxTicks = Math.max(
    3,
    Math.min(15, Math.floor(chartDimensions.width / 80)),
  );

  const chartData = projects.map(project => {
    const start = new Date(project.start_date);
    const end = new Date(project.end_date);

    const startPosition =
      (start.getTime() - earliestStart.getTime()) / totalRange;
    const duration = (end.getTime() - start.getTime()) / totalRange;

    const durationInYears =
      (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24 * 365.25);

    return {
      name: project.name,
      start: startPosition,
      duration: duration,
      durationInYears: durationInYears,
      startDate: start,
      endDate: end,
    };
  });

  // Sort projects by start date
  chartData.sort((a, b) => a.start - b.start);

  return (
    <div
      ref={containerRef}
      className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow w-full"
    >
      <h2 className="text-lg font-semibold mb-4">
        Project Timeline ({earliestStart.getFullYear()} -{' '}
        {latestEnd.getFullYear()})
      </h2>
      <BarChart
        dataset={chartData}
        xAxis={[
          {
            id: 'years',
            scaleType: 'linear',
            min: 0,
            max: 1,
            tickMinStep: 0.1,
            valueFormatter: value => {
              const date = new Date(
                earliestStart.getTime() + value * totalRange,
              );
              return date.getFullYear().toString();
            },
            tickLabelStyle: {
              angle: 45,
              textAnchor: 'start',
              fontSize: 12,
            },
            labelStyle: {
              fontSize: 14,
            },
          },
        ]}
        yAxis={[
          {
            scaleType: 'band',
            dataKey: 'name',
          },
        ]}
        series={[
          {
            dataKey: 'start',
            stack: 'total',
            color: 'transparent',
            barThickness: 0,
            barGap: 0,
            highlightScope: { highlighted: 'none', faded: 'none' },
            valueFormatter: (value, context) => {
              if (context.dataIndex === undefined) return '';
              const item = chartData[context.dataIndex];
              return `${item.durationInYears.toFixed(1)} years`;
            },
          },
          {
            dataKey: 'duration',
            stack: 'total',
            color: '#14b8a6',
            valueFormatter: (value, context) => {
              if (context.dataIndex === undefined) return '';
              const item = chartData[context.dataIndex];
              const startDate = new Date(
                earliestStart.getTime() + item.start * totalRange,
              );
              const endDate = new Date(
                earliestStart.getTime() +
                  (item.start + item.duration) * totalRange,
              );
              return `${startDate.getFullYear()} - ${endDate.getFullYear()}`;
            },
            highlightScope: { highlighted: 'item', faded: 'global' },
          },
        ]}
        layout="horizontal"
        width={chartDimensions.width}
        height={chartDimensions.height}
        margin={{ left: 200, right: 20, top: 20, bottom: 40 }}
        slotProps={{
          bar: {
            onClick: (event, itemData, barData) => {
              console.log('Bar clicked:', itemData, barData);
            },
          },
        }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-10px, 0)',
          },
          '& .MuiBarElement-root': {
            transition: 'all 0.3s ease-in-out',
          },
        }}
      />
    </div>
  );
};

export default ProjectTimeline;
