import React from 'react';
import { Link } from 'react-router-dom';

export const FooterWithLinks = () => (
  <footer className="relative z-10 bg-transparent text-white py-4 px-8 text-sm">
    <div className="max-w-7xl mx-auto ml-16">
      <div className="flex flex-col space-y-2">
        <div className="flex space-x-4">
          <Link
            to="/privacy-policy"
            className="hover:text-gray-300 transition-colors"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-of-service"
            className="hover:text-gray-300 transition-colors"
          >
            Terms of Service
          </Link>
        </div>
        <div>
          © {new Date().getFullYear()} Real Assets Portal. All rights reserved.
        </div>
      </div>
    </div>
  </footer>
);

export const FooterWithoutLinks = () => (
  <footer className="relative z-10 bg-transparent text-white py-4 px-8 text-sm">
    <div className="flex justify-center">
      <div>
        © {new Date().getFullYear()} Real Assets Portal. All rights reserved.
      </div>
    </div>
  </footer>
);
