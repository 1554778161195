import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import OrgAdminPanel from './components/admin/org_admin/OrgAdminPanel';
import Loading from './components/ui-modals/Loading';
import { AdminModeProvider } from './contexts/AdminModeContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { ThemeProvider } from './contexts/ThemeContext';
import AccountBilling from './pages/AccountBilling';
import AccountSettings from './pages/AccountSettings';
import ForgotPassword from './pages/ForgotPassword';
import HomePage from './pages/HomePage';
import LandingPage from './pages/LandingPage';
import Login from './pages/Login';
import PortfolioDashboard from './pages/PortfolioDashboard';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProjectDashboard from './pages/ProjectDashboard';
import Projects from './pages/Projects';
import ReportBuilder from './pages/ReportBuilder';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings';
import Support from './pages/Support';
import TermsOfService from './pages/TermsOfService';
import Upload from './pages/Upload';
import ProtectedRoute from './routes/ProtectedRoute';
import './shared/mui-license';
import './styles/App.css';

export const app_paths = {
  HOME_PATH: '/home',
  PORTFOLIO_DASHBOARD_PATH: '/portfolio',
  PROJECTS_DASHBOARD_PATH: '/dashboard',
  PROJECTS_PATH: '/projects',
  UPLOAD_PATH: '/upload',
  REPORTS_PATH: '/reports',
  SETTINGS_PATH: '/settings',
  ACCOUNT_PATH: '/account',
  LOGIN_PATH: '/login',
  SUPPORT_PATH: '/support',
  ACCOUNT_BILLING_PATH: '/account-billing',
  ACCOUNT_SETTINGS_PATH: '/account-settings',
  PRIVACY_POLICY_PATH: '/privacy-policy',
  TERMS_OF_SERVICE_PATH: '/terms-of-service',
  ORG_ADMIN_PATH: '/org-admin',
  SETUP_PASSWORD_PATH: '/setup-password',
  FORGOT_PASSWORD_PATH: '/forgot-password',
  RESET_PASSWORD_PATH: '/reset-password',
};

function App() {
  const [loading, setLoading] = useState(true);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
      serverZone: 'EU',
      defaultTracking: true,
      autocapture: {
        elementInteractions: true,
      },
    });
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <ThemeProvider>
      <AdminModeProvider>
        <SidebarProvider>
          {loading && <Loading message="Loading Account" />}
          <Routes>
            <Route
              path="/"
              element={
                <LandingPage onSignUpClick={() => setIsSignUpModalOpen(true)} />
              }
            />
            <Route path={app_paths.LOGIN_PATH} element={<Login />} />
            <Route
              path={app_paths.PRIVACY_POLICY_PATH}
              element={<PrivacyPolicy />}
            />
            <Route
              path={app_paths.TERMS_OF_SERVICE_PATH}
              element={<TermsOfService />}
            />
            <Route
              path={app_paths.HOME_PATH}
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.PORTFOLIO_DASHBOARD_PATH}
              element={
                <ProtectedRoute>
                  <PortfolioDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.PROJECTS_DASHBOARD_PATH}
              element={
                <ProtectedRoute>
                  <ProjectDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.REPORTS_PATH}
              element={
                <ProtectedRoute>
                  <ReportBuilder />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.PROJECTS_PATH}
              element={
                <ProtectedRoute>
                  <Projects />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.UPLOAD_PATH}
              element={
                <ProtectedRoute>
                  <Upload />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.SETTINGS_PATH}
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.ACCOUNT_SETTINGS_PATH}
              element={
                <ProtectedRoute>
                  <AccountSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.ORG_ADMIN_PATH}
              element={
                <ProtectedRoute>
                  <OrgAdminPanel />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.ACCOUNT_BILLING_PATH}
              element={
                <ProtectedRoute>
                  <AccountBilling />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.SUPPORT_PATH}
              element={
                <ProtectedRoute>
                  <Support />
                </ProtectedRoute>
              }
            />
            <Route
              path={app_paths.FORGOT_PASSWORD_PATH}
              element={<ForgotPassword />}
            />
            <Route
              path={app_paths.RESET_PASSWORD_PATH}
              element={<ResetPassword />}
            />
            <Route
              path={app_paths.SETUP_PASSWORD_PATH}
              element={<ResetPassword />}
            />
          </Routes>
        </SidebarProvider>
      </AdminModeProvider>
    </ThemeProvider>
  );
}

export default App;
