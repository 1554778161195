import { Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import React, { useEffect, useMemo, useState } from 'react';

import { fetchKPINames } from '../../api/fetchers';
import useKPIData from '../../hooks/useKPIData';

const ProjectPerformanceChart = ({ projectId, selectedKpis, aggregation }) => {
  const theme = useTheme();
  const [kpiNames, setKpiNames] = useState({});

  // Fetch KPI names
  useEffect(() => {
    const getKPINames = async () => {
      const names = await fetchKPINames(selectedKpis);
      setKpiNames(names);
    };
    getKPINames();
  }, [selectedKpis]);

  // Call useKPIData for each KPI individually
  const kpiData1 = useKPIData(projectId, selectedKpis[0], aggregation);
  const kpiData2 = useKPIData(projectId, selectedKpis[1], aggregation);
  const kpiData3 = useKPIData(projectId, selectedKpis[2], aggregation);
  const kpiData4 = useKPIData(projectId, selectedKpis[3], aggregation);

  const kpiDataArray = useMemo(() => {
    return [
      { kpi: selectedKpis[0], ...kpiData1 },
      { kpi: selectedKpis[1], ...kpiData2 },
      { kpi: selectedKpis[2], ...kpiData3 },
      { kpi: selectedKpis[3], ...kpiData4 },
    ].filter(({ kpi, data }) => kpi && data && data.length > 0);
  }, [selectedKpis, kpiData1, kpiData2, kpiData3, kpiData4]);

  const { chartData, xLabels, allValues } = useMemo(() => {
    if (kpiDataArray.length === 0)
      return { chartData: [], xLabels: [], allValues: [] };

    // Get all unique dates from all KPIs
    const allDates = [
      ...new Set(
        kpiDataArray.flatMap(({ data }) => data.map(item => item.date)),
      ),
    ].sort();

    // Create a map of dates to index
    const dateIndexMap = new Map(allDates.map((date, index) => [date, index]));

    const xLabels = allDates.map(date => new Date(date).toLocaleDateString());

    const series = kpiDataArray.map(({ kpi, data }) => {
      const filledData = new Array(allDates.length).fill(null);
      data.forEach(item => {
        const index = dateIndexMap.get(item.date);
        if (index !== undefined) {
          filledData[index] = item.amount_value;
        }
      });

      return {
        data: filledData,
        label: kpiNames[kpi] || kpi,
      };
    });

    const allValues = series.flatMap(s => s.data.filter(v => v !== null));

    return { chartData: series, xLabels, allValues };
  }, [kpiDataArray, kpiNames]);

  const isLoading = kpiDataArray.some(({ loading }) => loading);
  const hasData = chartData.length > 0 && xLabels.length > 0;

  if (!selectedKpis || selectedKpis.length === 0) {
    return <Typography>No KPIs selected</Typography>;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!hasData) {
    return <Typography>No data available</Typography>;
  }

  const colors = [
    '#f1948a', // outflow (red)
    '#82E0AA', // inflow 1 (light green)
    '#3498DB', // inflow 2 (light blue)
    '#9B59B6', // inflow 3 (purple)
  ];

  return (
    <Box sx={{ width: '100%', height: 300 }}>
      <BarChart
        series={chartData.map((series, index) => ({
          ...series,
          color: colors[index % colors.length],
          animated: false,
        }))}
        xAxis={[{ data: xLabels, scaleType: 'band' }]}
        yAxis={[
          {
            min: Math.min(0, ...allValues),
            max: Math.max(...allValues),
            tickLabelStyle: {
              fontSize: 12,
            },
          },
        ]}
        height={300}
        margin={{ left: 100, right: 10, bottom: 30 }}
        skipAnimation={true}
        slotProps={{
          legend: {
            itemTextStyle: { fontSize: '1px' },
          },
        }}
        sx={{
          width: '100%',
          height: '100%',
          '& .MuiChartsLegend-root': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          },
        }}
      />
    </Box>
  );
};

export default ProjectPerformanceChart;
