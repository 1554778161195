export const legalLabels = {
  lastUpdated: 'Sept 30, 2024',

  termsOfService: {
    title: 'Terms of Service',
    sections: [
      'Acceptance of Terms',
      'Description of Service',
      'Eligibility',
      'User Accounts',
      'Use of the Platform',
      'Intellectual Property',
      'International Data Transfers',
      'Termination',
      'Disclaimers and Limitation of Liability',
      'Governing Law',
      'Changes to Terms',
      'Contact Us',
    ],
    content: {
      'Acceptance of Terms':
        'By accessing or using the Skala Investor Platform ("the Platform"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you may not access or use the Platform.',
      'Description of Service':
        'The Platform provides a reporting system for investment sponsors of projects ("General Partners" or "GPs") to report specific project investment performance. Authorized users ("Limited Partners" or "LPs") can view, download reports, or export data for analysis.',
      Eligibility:
        'You must be at least 18 years old and be authorized by a General Partner to access and use the Platform.',
      'User Accounts': {
        Registration:
          'You agree to provide accurate and complete information during registration.',
        'Account Security':
          'You are responsible for maintaining the confidentiality of your login credentials.',
      },
      'Use of the Platform': {
        Compliance:
          'You agree to use the Platform in compliance with all applicable laws and regulations.',
        'Prohibited Activities':
          'You shall not misuse the Platform for any unlawful or unauthorized purposes.',
      },
      'Intellectual Property':
        'All content on the Platform, including text, graphics, logos, and software, is the property of Skala/Tandem Group and is protected by intellectual property laws.',
      'International Data Transfers':
        'Your information may be transferred to and maintained on servers located outside of your province, country, or other governmental jurisdiction where the data protection laws may differ. By using the Platform, you consent to such transfers in compliance with applicable Canadian regulations.',
      Termination:
        'We reserve the right to suspend or terminate your access to the Platform at our sole discretion, without notice, for conduct that violates these Terms.',
      'Disclaimers and Limitation of Liability': {
        'No Investment Advice':
          'The Platform does not provide investment advice.',
        'As-Is Basis':
          'The Platform is provided on an "as-is" basis without warranties of any kind.',
        Limitation:
          'Skala Group shall not be liable for any indirect, incidental, or consequential damages arising out of your use of the Platform.',
      },
      'Governing Law':
        'These Terms are governed by and construed in accordance with the laws of Canada, without regard to its conflict of law principles.',
      'Changes to Terms':
        'We may modify these Terms at any time. Changes will be posted on this page with an updated effective date.',
      'Contact Us':
        'If you have any questions about these Terms, please contact us at legal@skalagroup.ca.',
    },
  },

  privacyPolicy: {
    title: 'Privacy Policy',
    sections: [
      'Introduction',
      'Information We Collect',
      'How We Use Your Information',
      'Cookies and Tracking Technologies',
      'Sharing Your Information',
      'Data Security',
      'Your Rights',
      'Age Restrictions',
      'International Data Transfers',
      'Changes to Privacy Policy',
      'Contact Us',
    ],
    content: {
      Introduction:
        'At Skala Investor Platform ("we," "us," "our"), we are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, and safeguard your information.',
      'Information We Collect': {
        'Personal Information':
          'Name, email address, contact details, and investment preferences.',
        'Usage Data': 'Login times, pages viewed, and other interaction data.',
      },
      'How We Use Your Information': [
        'To Provide Services: Facilitate account creation and manage user accounts.',
        'Communication: Send you notifications about project updates and important information.',
        'Improvement: Analyze usage to improve our Platform.',
      ],
      'Cookies and Tracking Technologies':
        'We use cookies to enhance your experience. You can control cookie settings through your browser.',
      'Sharing Your Information': {
        'Service Providers':
          'Third-party vendors who assist in operating the Platform.',
        'Legal Obligations': 'When required by law or to protect our rights.',
      },
      'Data Security':
        'We implement security measures to protect your information. However, no method of transmission over the internet is 100% secure.',
      'Your Rights': {
        'Access and Correction':
          'You may access and update your personal information through your account settings.',
        'Opt-Out': 'You may opt out of receiving non-essential communications.',
      },
      'Age Restrictions':
        'Our Platform is not intended for individuals under 18 years of age.',
      'International Data Transfers':
        'Your information may be transferred to and maintained on servers located outside of your province, country, or other governmental jurisdiction where the data protection laws may differ. By using the Platform, you consent to such transfers in compliance with applicable Canadian regulations.',
      'Changes to Privacy Policy':
        'We may update our Privacy Policy from time to time. Changes will be posted on this page with an updated effective date.',
      'Contact Us':
        'If you have any questions about this Privacy Policy, the ways in which we collect, store, and use your information, please contact us at legal@skalagroup.ca.',
    },
  },
};
