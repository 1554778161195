import { Tooltip } from '@mui/material';
import React from 'react';

import UploadPreviewDataTable from '../../components/tables/UploadPreviewDataTable';
import PanelCollapsible from '../../components/ui-elements/PanelCollapsible';

const UploadDataPreview = ({
  isReviewing,
  parsedData,
  summary,
  upload_labels,
  handleCommit,
  handleClearUploads,
}) => {
  if (!isReviewing || parsedData.length === 0) {
    return (
      <div className="mt-1 bg-white dark:bg-gray-800 rounded-lg ">
        <h1 className="text-2xl font-bold mb-4">
          {upload_labels.DATA_PREVIEW}
        </h1>
        <p className="text-gray-600 dark:text-gray-400">
          Upload data to preview CSV files before saving to database.
        </p>
      </div>
    );
  }
  return (
    <div className="mt-1">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">{upload_labels.DATA_PREVIEW}</h1>
        <div className="flex items-center">
          <div className="mr-4 text-sm">
            <span className="font-semibold">
              {upload_labels.TOTAL_ENTRIES}:
            </span>{' '}
            {summary.totalEntries} |{' '}
            <span className="font-semibold">
              {upload_labels.NUMBER_OF_FILES}:
            </span>{' '}
            {summary.numberOfFiles}
          </div>
          <Tooltip
            title="Clears existing project data and upload to database"
            placement="top"
            arrow
          >
            <button
              onClick={handleCommit}
              className="bg-green-200 hover:bg-green-300 text-green-800 font-semibold py-2 px-4 rounded-lg mr-2"
            >
              {upload_labels.COMMIT_TO_DATABASE}
            </button>
          </Tooltip>
          <Tooltip title="Remove all staged upload files" arrow placement="top">
            <button
              onClick={handleClearUploads}
              className="bg-red-200 hover:bg-red-300 text-red-800 font-semibold py-2 px-4 rounded-lg"
            >
              {upload_labels.CLEAR_UPLOADS}
            </button>
          </Tooltip>
        </div>
      </div>
      {parsedData.map((fileData, index) => (
        <PanelCollapsible key={index} title={fileData.fileName}>
          <div className="overflow-x-auto">
            <UploadPreviewDataTable
              data={fileData.data.slice(0, 12)}
              fileName={fileData.fileName}
            />
          </div>
        </PanelCollapsible>
      ))}
    </div>
  );
};

export default UploadDataPreview;
