import { Box, Paper, Typography } from '@mui/material';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import React, { useEffect, useMemo, useState } from 'react';

import { fetchUserPortfolioKPIs } from '../../api/fetchers';
import dataGridStyles from '../../styles/dataGridStyles';

const formatCurrency = value => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const PortfolioSummaryDataGrid = ({ userId, selectedCompanyIds }) => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (selectedCompanyIds.length === 0) {
          setPortfolioData([]);
        } else {
          const portfolioKPIs = await fetchUserPortfolioKPIs(
            userId,
            selectedCompanyIds,
          );
          const formattedData = formatDataForSummary(portfolioKPIs);
          setPortfolioData(formattedData);
        }
      } catch (error) {
        console.error('Error fetching portfolio KPIs:', error);
        setPortfolioData([]);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId, selectedCompanyIds]);

  const formatDataForSummary = data => {
    return data.map(company => {
      const yearlyData = {};
      let totalEquityContributed = 0;
      let totalCapitalReturns = 0;

      company.kpis.forEach(kpi => {
        const year = new Date(kpi.date).getFullYear();
        if (!yearlyData[year]) {
          yearlyData[year] = {
            equityContributed: 0,
            capitalReturns: 0,
            revenue: 0,
            profit: 0,
          };
        }

        switch (kpi.slug) {
          case 'PR_EQTCNT':
            yearlyData[year].equityContributed += Number(kpi.value);
            totalEquityContributed += Number(kpi.value);
            break;
          case 'PR_EQTDST':
          case 'PR_PFTDST':
          case 'PR_INTDST':
            yearlyData[year].capitalReturns += Number(kpi.value);
            totalCapitalReturns += Number(kpi.value);
            break;
          case 'PR_TOTREV':
            yearlyData[year].revenue += Number(kpi.value);
            break;
          case 'PR_TOTPRF':
            yearlyData[year].profit += Number(kpi.value);
            break;
        }
      });

      const sortedYears = Object.keys(yearlyData).sort();

      return {
        id: company.company_id,
        companyName: company.company_name,
        totalEquityContributed,
        totalCapitalReturns,
        yearlyEquityContributed: sortedYears.map(
          year => yearlyData[year].equityContributed,
        ),
        yearlyCapitalReturns: sortedYears.map(
          year => yearlyData[year].capitalReturns,
        ),
        yearlyRevenue: sortedYears.map(year => yearlyData[year].revenue),
        yearlyProfit: sortedYears.map(year => yearlyData[year].profit),
      };
    });
  };
  const columns = useMemo(
    () => [
      { field: 'companyName', headerName: 'Company', width: 200 },
      {
        field: 'totalEquityContributed',
        headerName: 'Total Equity Contributed',
        width: 120,
        renderCell: params => formatCurrency(params.value),
      },
      {
        field: 'totalCapitalReturns',
        headerName: 'Total Projected Capital Returns',
        width: 120,
        renderCell: params => formatCurrency(params.value),
      },
      {
        field: 'yearlyEquityContributed',
        headerName: 'Equity Contribution Trend',
        width: 150,
        renderCell: params => (
          <SparkLineChart
            data={params.value}
            height={40}
            width={100}
            plotType="bar"
          />
        ),
      },
      {
        field: 'yearlyCapitalReturns',
        headerName: 'Capital Returns Trend',
        width: 150,
        renderCell: params => (
          <SparkLineChart
            data={params.value}
            height={40}
            width={100}
            plotType="bar"
          />
        ),
      },
      {
        field: 'yearlyRevenue',
        headerName: 'Revenue Trend',
        width: 150,
        renderCell: params => (
          <SparkLineChart
            data={params.value}
            height={40}
            width={100}
            plotType="line"
          />
        ),
      },
      {
        field: 'yearlyProfit',
        headerName: 'Profit Trend',
        width: 150,
        renderCell: params => (
          <SparkLineChart
            data={params.value}
            height={40}
            width={100}
            plotType="line"
          />
        ),
      },
    ],
    [],
  );

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ height: 600, width: '100%', ...dataGridStyles }}>
      <Paper elevation={3} sx={{ p: 1, mb: 1 }}>
        <Typography variant="h6" gutterBottom>
          Portfolio Company Summary
        </Typography>
      </Paper>
      <DataGridPremium
        rows={portfolioData}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
        density="compact"
        components={{ Toolbar: GridToolbar }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'totalEquityContributed', sort: 'desc' }],
          },
        }}
      />
    </Box>
  );
};

export default PortfolioSummaryDataGrid;
