import { ExitToApp as LogoutIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { app_paths } from '../../App';
import { logout } from '../../api/fetchers';
import { useAdminMode } from '../../contexts/AdminModeContext';
import '../../styles/index.css';

const LogoutButton = ({ label, collapsed = false }) => {
  const navigate = useNavigate();
  const { clearAdminMode } = useAdminMode();

  const handleButtonClick = async () => {
    await logout();
    clearAdminMode();
    sessionStorage.clear();
    localStorage.clear();
    navigate(app_paths.LOGIN_PATH);
  };

  return (
    <Tooltip
      title="Exit"
      placement="right"
      arrow
      disableHoverListener={!collapsed}
    >
      <button
        onClick={handleButtonClick}
        className={`flex items-center space-x-2 p-1.5 rounded w-full hover:bg-gray-200 dark:hover:bg-gray-700 ${
          collapsed ? 'justify-center' : 'justify-start'
        }`}
      >
        <LogoutIcon className="w-5 h-5" />
        {!collapsed && <span className="text-xs">{label}</span>}
      </button>
    </Tooltip>
  );
};

export default LogoutButton;
