import styled from 'styled-components';

// export const ChartContainer = styled.div`
//   width: 100%;
//   height: 80vh;
//   overflow: hidden;
//   border: 2px solid red; // Add this line
//   border-radius: 4px;
// `;

export const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  color: #666;
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  color: #d32f2f;
  text-align: center;
  padding: 0 20px;
`;

export const ChartTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 70vh;
  overflow: hidden;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    flex: 1;
    min-height: 0;
  }

  .org-node {
    background-color: #f0f0f0;
    border: 1px solid #999;
    border-radius: 5px;
    padding: 10px;
    width: 250px;
    height: 120px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
  }

  .org-node.external {
    background-color: #e6f3ff;
  }

  .org-node.has-children {
    cursor: pointer;
  }

  .org-node.has-children:hover,
  .org-node.hover {
    background-color: #d4e6f6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .node-name {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .node-type,
  .node-ownership {
    font-size: 0.9em;
    color: #666;
  }

  .children-indicator {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    background-color: #2caae5;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
  }

  .link {
    fill: none;
    stroke: #999;
    stroke-width: 1px;
    transition:
      stroke 0.3s ease,
      stroke-width 0.3s ease;
  }

  .link.highlight {
    stroke: #2196f3;
    stroke-width: 4px;
  }

  .org-node.highlight,
  .org-node.highlight.hover {
    background-color: #ffeb3b;
    box-shadow: 0 0 10px rgba(255, 235, 59, 0.5);
  }
`;
