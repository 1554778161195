import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import '../../styles/index.css';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(1),
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    border: 0,
    '&:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
    },
    '&:first-of-type': {
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    '&:last-of-type': {
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  padding: theme.spacing(0.5, 1),
  fontSize: '0.75rem',
  fontWeight: 'bold',
  minWidth: '32px',
  height: '24px',
}));

const DataGridQuickFilterButtonGroup = ({
  activeFilter,
  handleQuickFilter,
  companies,
  selectedCompanies,
  onCompanyFilterChange,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 1,
        mb: 2,
      }}
    >
      <StyledToggleButtonGroup
        size="small"
        value={activeFilter}
        exclusive
        onChange={(event, newFilter) => handleQuickFilter(newFilter)}
        aria-label="quick filter"
      >
        <StyledToggleButton value="equity" aria-label="equity">
          Equity Contributions
        </StyledToggleButton>
        <StyledToggleButton value="capitalReturns" aria-label="returns">
          Capital Returns
        </StyledToggleButton>
        <StyledToggleButton value="projectData" aria-label="project data">
          Project Data
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      {/* <CompaniesFilterDropdown
        companies={companies}
        selectedCompanies={selectedCompanies}
        onFilterChange={onCompanyFilterChange}
      /> */}
    </Box>
  );
};

export default DataGridQuickFilterButtonGroup;
