import { Box } from '@mui/material';
import React, { useEffect } from 'react';

import { useAdminMode } from '../../contexts/AdminModeContext';
import { useSidebar } from '../../contexts/SidebarContext';
import Sidebar from '../ui-elements/Sidebar';
import TopNavBar from '../ui-elements/TopNavBar';

const DashboardLayout = ({
  children,
  title,
  onUserChange,
  selectedUser,
  selectedAccount,
}) => {
  const { isOpen, toggleSidebar } = useSidebar();
  const { isAdmin, adminModeActive, toggleAdminMode } = useAdminMode();

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Sidebar
        open={isOpen}
        onToggle={toggleSidebar}
        selectedUser={selectedUser}
        selectedAccount={selectedAccount}
        onUserChange={onUserChange}
        isAdmin={isAdmin}
        adminModeActive={adminModeActive}
        toggleAdminMode={() => toggleAdminMode(selectedUser)}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          transition: 'margin-left 0.3s, width 0.3s',
          marginLeft: isOpen ? '16rem' : '4rem',
          width: isOpen ? 'calc(100% - 16rem)' : 'calc(100% - 4rem)',
        }}
      >
        <TopNavBar
          title={title}
          onUserChange={onUserChange}
          selectedUser={selectedUser}
          isAdmin={isAdmin}
          adminModeActive={adminModeActive}
          toggleAdminMode={() => toggleAdminMode(selectedUser)}
        />
        <Box sx={{ flexGrow: 1, p: 3.5 }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
