import { useEffect, useState } from 'react';

import {
  fetchOrganizationSubtree,
  fetchOrganizationTree,
  fetchProjectOrganizationTree,
} from '../../api/organization';

export const useOrgChartData = (projectId, organizationId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let orgTree;
        if (projectId) {
          console.log('Fetching data for projectId:', projectId);
          orgTree = await fetchProjectOrganizationTree(projectId);
        } else if (organizationId) {
          orgTree = await fetchOrganizationSubtree(organizationId);
        } else {
          orgTree = await fetchOrganizationTree(true);
        }
        console.log('API response:', orgTree);

        // Ensure we always have an array of valid objects
        const processedData = Array.isArray(orgTree)
          ? orgTree.filter(node => node && typeof node === 'object')
          : orgTree && typeof orgTree === 'object'
            ? [orgTree]
            : [];

        console.log('Processed data:', processedData);
        setData(processedData);
      } catch (err) {
        console.error('Error fetching org data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (projectId || organizationId) {
      fetchData();
    }
  }, [projectId, organizationId]);

  return { data, loading, error };
};

const transformToHierarchy = flatData => {
  console.log('Transforming data:', flatData);
  if (!Array.isArray(flatData) || flatData.length === 0) {
    console.log('Flat data is empty or not an array');
    return [];
  }

  const idMapping = flatData.reduce((acc, el, i) => {
    acc[el.id] = i;
    return acc;
  }, {});

  let root;
  flatData.forEach(el => {
    // Handle the root element
    if (el.parentId === null) {
      root = el;
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = flatData[idMapping[el.parentId]];
    // Add our current el to its parent's `children` array
    if (parentEl) {
      parentEl.children = [...(parentEl.children || []), el];
    } else {
      console.log('Parent not found for element:', el);
    }
  });

  console.log('Transformed data:', root ? [root] : []);
  return root ? [root] : [];
};
