import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';

export const UploadStatusAlert = ({ uploadStatus, onClose }) =>
  uploadStatus && (
    <div className="mt-6">
      <Alert
        severity={uploadStatus.success ? 'success' : 'error'}
        onClose={onClose}
      >
        {uploadStatus.message}
      </Alert>
    </div>
  );

export const WarningDialog = ({ open, onClose, onProceed, upload_labels }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{upload_labels.WARNING_TITLE}</DialogTitle>
    <DialogContent>
      <Typography>{upload_labels.WARNING_MESSAGE}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onProceed} color="primary">
        {upload_labels.PROCEED}
      </Button>
      <Button onClick={onClose} color="secondary" autoFocus>
        {upload_labels.CANCEL}
      </Button>
    </DialogActions>
  </Dialog>
);
