import {
  CreditCard as BillingIcon,
  HelpCenterTwoTone as HelpIcon,
  ExitToApp as LogoutIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { Box, Divider, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { app_paths } from '../../App';

const UserMenu = ({ anchorEl, open, onClose, selectedUser, onLogout }) => {
  const iconColor = theme => theme.palette.text.secondary;

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          style: {
            minWidth: 200,
          },
        },
      }}
    >
      <MenuItem>
        <Box>
          <Typography variant="body1">
            {selectedUser?.username || 'User'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {selectedUser?.email || 'email@skalagroup.ca'}
          </Typography>
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem
        component={Link}
        to={app_paths.ACCOUNT_SETTINGS_PATH}
        onClick={onClose}
      >
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Account Settings
        </Typography>
        <SettingsIcon fontSize="small" sx={{ color: iconColor }} />
      </MenuItem>
      <MenuItem
        component={Link}
        to={app_paths.ACCOUNT_BILLING_PATH}
        onClick={onClose}
      >
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Billing
        </Typography>
        <BillingIcon fontSize="small" sx={{ color: iconColor }} />
      </MenuItem>
      <MenuItem component={Link} to="/support" onClick={onClose}>
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Help Center
        </Typography>
        <HelpIcon fontSize="small" sx={{ color: iconColor }} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={onLogout}>
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Logout
        </Typography>
        <LogoutIcon fontSize="small" sx={{ color: iconColor }} />
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
