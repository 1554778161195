import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

const ProjectMetricCards = ({ projectDetails }) => {
  if (!projectDetails) {
    return null; // or return a loading state or placeholder
  }

  const metrics = [
    { label: 'Total Acres', value: projectDetails.total_acres },
    { label: 'Developable Acres', value: projectDetails.developable_acres },
    { label: 'Number of Units', value: projectDetails.number_of_units },
    { label: 'Average Unit Size', value: projectDetails.average_unit_size },
  ];

  return (
    <Grid container spacing={2}>
      {metrics.map((metric, index) => (
        <Grid item xs={3} key={index}>
          <Paper elevation={2} sx={{ p: 1, height: '100%' }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              height="100%"
            >
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ mr: 1 }}
              >
                {metric.label}:
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {metric.value !== undefined ? metric.value : 'N/A'}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectMetricCards;
