import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

import { CustomOrgChartLink } from './OrgChartLink';
import { OrgChartNode } from './OrgChartNode';
import { ChartContainer, ErrorMessage, LoadingSpinner } from './OrgChartStyles';
import { useOrgChartData } from './useOrgChartData';

const OrgChartComponent = ({ projectId }) => {
  const { data, loading, error } = useOrgChartData(projectId);
  const d3Container = useRef(null);
  const svgRef = useRef(null);
  const gRef = useRef(null);
  const zoomRef = useRef(null);
  const tooltipRef = useRef(null);
  const updateRef = useRef(null); // Create updateRef here
  const [root, setRoot] = useState(null);

  const centerOnInitialView = () => {
    if (!root || !svgRef.current || !zoomRef.current) return;

    const nodes = root.descendants();
    const bounds = nodes.reduce(
      (acc, node) => ({
        left: Math.min(acc.left, node.x),
        right: Math.max(acc.right, node.x),
        top: Math.min(acc.top, node.y),
        bottom: Math.max(acc.bottom, node.y),
      }),
      { left: Infinity, right: -Infinity, top: Infinity, bottom: -Infinity },
    );

    const containerWidth = d3Container.current.offsetWidth;
    const containerHeight = d3Container.current.offsetHeight;
    const dx = bounds.right - bounds.left + 300; // Add padding
    const dy = bounds.bottom - bounds.top + 300; // Add padding
    const x = (bounds.left + bounds.right) / 2;
    const y = (bounds.top + bounds.bottom) / 2;

    const scale = 0.9 / Math.max(dx / containerWidth, dy / containerHeight);
    const translate = [
      containerWidth / 2 - scale * x,
      containerHeight / 2 - scale * y,
    ];

    svgRef.current
      .transition()
      .duration(750)
      .call(
        zoomRef.current.transform,
        d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale),
      );
  };

  useEffect(() => {
    if (data && data.length > 0 && !loading && d3Container.current) {
      const containerWidth = d3Container.current.offsetWidth;
      const containerHeight = d3Container.current.offsetHeight;

      // Clear previous chart
      d3.select(d3Container.current).selectAll('*').remove();

      const svg = d3
        .select(d3Container.current)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 0 ${containerWidth} ${containerHeight}`)
        .attr('preserveAspectRatio', 'xMidYMid meet');
      svgRef.current = svg;

      const g = svg.append('g');
      gRef.current = g;

      // Initialize zoom behavior
      const zoom = d3
        .zoom()
        .scaleExtent([0.1, 3])
        .on('zoom', event => {
          gRef.current.attr('transform', event.transform);
        });
      zoomRef.current = zoom;

      svg.call(zoom);

      const hierarchy = d3.hierarchy(data[0]);
      let i = 0;

      hierarchy.each(d => {
        d.id = i++;
        d._children = d.children;
        if (d.depth >= 2) {
          d.children = null;
        }
      });

      const root = hierarchy;
      root.x0 = containerWidth / 2;
      root.y0 = 0;
      setRoot(root); // Set the root state

      const treeLayout = d3
        .tree()
        .size([containerWidth, containerHeight])
        .separation((a, b) => {
          if (a.depth === 3 && b.depth === 3) {
            return 2;
          }
          return (a.parent === b.parent ? 1 : 1.25) / a.depth;
        });

      const showTooltip = (event, d) => {
        const tooltip = d3.select(tooltipRef.current);
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(OrgChartNode(d, { isTooltip: true }))
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY - 28}px`);
      };

      const hideTooltip = () => {
        const tooltip = d3.select(tooltipRef.current);
        tooltip.transition().duration(500).style('opacity', 0);
      };

      const highlightConnectedLinks = d => {
        g.selectAll('path.link')
          .filter(link => link.source === d || link.target === d)
          .style('stroke', '#007bff')
          .style('stroke-width', '3px');
      };

      const resetLinkHighlights = () => {
        g.selectAll('path.link')
          .style('stroke', '#555')
          .style('stroke-width', '1px');
      };

      const update = (source, callback) => {
        // Compute the new tree layout
        const treeData = treeLayout(root);

        // Get nodes and links
        const nodes = treeData.descendants();
        const links = treeData.links();

        // Adjust positions
        nodes.forEach(d => {
          d.y = d.depth * 180; // Vertical spacing

          if (d.depth === 1) {
            d.x = d.x * 1.5; // Increase spacing for first level
          } else if (d.depth === 2) {
            // Keep the x position assigned by the tree layout
            d.y = d.parent.y + 180; // Adjust vertical spacing as needed
          } else if (d.depth === 3) {
            const columnWidth = 250; // Adjust based on your node width
            const i = d.parent.children.indexOf(d);
            d.x =
              d.parent.x + (i % 2 === 0 ? -columnWidth / 2 : columnWidth / 2);
            d.y = d.parent.y + 180 + Math.floor(i / 2) * 120; // Vertical layout
          }
        });

        // Update the nodes...
        const node = g.selectAll('g.node').data(nodes, d => d.id);

        const nodeEnter = node
          .enter()
          .append('g')
          .attr(
            'class',
            d => `node ${d.children || d._children ? 'expandable' : ''}`,
          )
          .attr('transform', d => `translate(${source.x0},${source.y0})`)
          .on('click', (event, d) => {
            if (d.children) {
              d._children = d.children;
              d.children = null;
            } else if (d._children) {
              d.children = d._children;
              d._children = null;
            }
            update(d, () => {
              centerOnNodeAndChildren(d);
            });
          })
          .on('mouseover', function (event, d) {
            showTooltip(event, d);
            highlightConnectedLinks(d);
            this.parentNode.appendChild(this);
          })
          .on('mouseout', function () {
            hideTooltip();
            resetLinkHighlights();
          });

        nodeEnter
          .append('circle')
          .attr('r', 1e-6)
          .style('fill', d =>
            d._children ? 'lightsteelblue' : d.children ? '#fff' : '#999',
          );

        nodeEnter
          .append('foreignObject')
          .attr('width', 250)
          .attr('height', 120)
          .attr('x', -125)
          .attr('y', -60)
          .html(d => OrgChartNode(d, {}));

        const nodeUpdate = nodeEnter.merge(node);

        nodeUpdate
          .transition()
          .duration(750)
          .attr('transform', d => `translate(${d.x},${d.y})`);

        nodeUpdate
          .select('circle')
          .attr('r', 4.5)
          .style('fill', d =>
            d._children ? 'lightsteelblue' : d.children ? '#fff' : '#999',
          );

        const nodeExit = node
          .exit()
          .transition()
          .duration(750)
          .attr('transform', d => `translate(${source.x},${source.y})`)
          .remove();

        nodeExit.select('circle').attr('r', 1e-6);

        // Update the links...
        const link = g.selectAll('path.link').data(links, d => d.target.id);

        const linkEnter = link
          .enter()
          .insert('path', 'g')
          .attr('class', 'link')
          .attr('d', d => {
            const o = { x: source.x0, y: source.y0 };
            return CustomOrgChartLink({ source: o, target: o });
          });

        const linkUpdate = linkEnter.merge(link);

        linkUpdate.transition().duration(750).attr('d', CustomOrgChartLink);

        link
          .exit()
          .transition()
          .duration(750)
          .attr('d', d => {
            const o = { x: source.x, y: source.y };
            return CustomOrgChartLink({ source: o, target: o });
          })
          .remove();

        // Store the old positions for transition
        nodes.forEach(d => {
          d.x0 = d.x;
          d.y0 = d.y;
        });

        // Call the callback after transitions complete
        d3.timeout(() => {
          if (callback) callback();
        }, 750);
      };

      // Update the updateRef
      updateRef.current = update;

      const centerOnVisibleNodes = () => {
        const nodes = root.descendants().filter(d => !d._children);
        if (nodes.length === 0) return;

        const bounds = nodes.reduce(
          (acc, node) => {
            return {
              left: Math.min(acc.left, node.x),
              right: Math.max(acc.right, node.x),
              top: Math.min(acc.top, node.y),
              bottom: Math.max(acc.bottom, node.y),
            };
          },
          {
            left: Infinity,
            right: -Infinity,
            top: Infinity,
            bottom: -Infinity,
          },
        );

        const dx = bounds.right - bounds.left;
        const dy = bounds.bottom - bounds.top;
        const x = (bounds.left + bounds.right) / 2;
        const y = (bounds.top + bounds.bottom) / 2;

        const scale = 0.9 / Math.max(dx / containerWidth, dy / containerHeight);
        const translate = [
          containerWidth / 2 - scale * x,
          containerHeight / 2 - scale * y,
        ];

        svg
          .transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale),
          );
      };

      const centerOnNodeAndChildren = source => {
        let nodes;
        if (source.children) {
          // If expanded, include the node and its children
          nodes = [source, ...(source.children || [])];
        } else {
          // If collapsed, include the node, its parent, and its siblings
          nodes = source.parent
            ? [source.parent, ...source.parent.children]
            : [source];
        }

        const bounds = nodes.reduce(
          (acc, node) => ({
            left: Math.min(acc.left, node.x),
            right: Math.max(acc.right, node.x),
            top: Math.min(acc.top, node.y),
            bottom: Math.max(acc.bottom, node.y),
          }),
          {
            left: Infinity,
            right: -Infinity,
            top: Infinity,
            bottom: -Infinity,
          },
        );

        const dx = bounds.right - bounds.left + 300; // Add padding
        const dy = bounds.bottom - bounds.top + 300; // Add padding
        const x = (bounds.left + bounds.right) / 2;
        const y = (bounds.top + bounds.bottom) / 2;

        const scale = 0.9 / Math.max(dx / containerWidth, dy / containerHeight);
        const translate = [
          containerWidth / 2 - scale * x,
          containerHeight / 2 - scale * y,
        ];

        svg
          .transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale),
          );
      };

      // Initialize the chart
      update(root, () => {
        centerOnInitialView();
      });
    }
  }, [data, loading]);

  const handleZoomExtents = () => {
    if (!root || !svgRef.current || !zoomRef.current || !d3Container.current)
      return;

    const nodes = root.descendants();
    const bounds = nodes.reduce(
      (acc, node) => ({
        left: Math.min(acc.left, node.x),
        right: Math.max(acc.right, node.x),
        top: Math.min(acc.top, node.y),
        bottom: Math.max(acc.bottom, node.y),
      }),
      { left: Infinity, right: -Infinity, top: Infinity, bottom: -Infinity },
    );

    const containerWidth = d3Container.current.offsetWidth;
    const containerHeight = d3Container.current.offsetHeight;
    const dx = bounds.right - bounds.left + 100; // Add padding
    const dy = bounds.bottom - bounds.top + 100; // Add padding
    const x = (bounds.left + bounds.right) / 2;
    const y = (bounds.top + bounds.bottom) / 2;

    const scale = 0.9 / Math.max(dx / containerWidth, dy / containerHeight);
    const translate = [
      containerWidth / 2 - scale * x,
      containerHeight / 2 - scale * y,
    ];

    svgRef.current
      .transition()
      .duration(750)
      .call(
        zoomRef.current.transform,
        d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale),
      );
  };

  const handleCollapseAll = () => {
    if (root && updateRef.current) {
      root.each(d => {
        if (d.children) {
          d._children = d.children;
          d.children = null;
        }
      });
      updateRef.current(root);
      centerOnInitialView();
    }
  };

  const handleExpandAll = () => {
    if (root && updateRef.current) {
      root.each(d => {
        if (d._children) {
          d.children = d._children;
          d._children = null;
        }
      });
      updateRef.current(root);
      centerOnInitialView();
    }
  };

  if (loading) return <LoadingSpinner>Loading...</LoadingSpinner>;
  if (error) return <ErrorMessage>Error: {error.message}</ErrorMessage>;
  if (!data || data.length === 0) {
    return (
      <ErrorMessage>
        No organization data available for this project.
      </ErrorMessage>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <button onClick={handleZoomExtents} style={buttonStyle}>
          Zoom Extents
        </button>
        <button onClick={handleCollapseAll} style={buttonStyle}>
          Collapse All
        </button>
        <button onClick={handleExpandAll} style={buttonStyle}>
          Expand All
        </button>
      </div>
      <ChartContainer ref={d3Container} />
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          display: 'none',
          pointerEvents: 'none',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 0 5px rgba(0,0,0,0.3)',
          fontSize: '12px',
          zIndex: 10,
        }}
      ></div>
    </>
  );
};

const buttonStyle = {
  margin: '0 5px',
  padding: '5px 10px',
  backgroundColor: '#f0f0f0',
  border: '1px solid #ccc',
  borderRadius: '4px',
  cursor: 'pointer',
};

export default OrgChartComponent;
