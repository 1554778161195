import {
  Divider,
  Menu,
  MenuItem,
  Pagination,
  Switch,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { fetchUsers } from '../../api/fetchers';
import { useAdminMode } from '../../contexts/AdminModeContext';

function UserMenuAdmin({
  anchorEl,
  open,
  onClose,
  onUserChange,
  isAdmin,
  currentUser,
}) {
  const { adminModeActive, toggleAdminMode, originalUser } = useAdminMode();
  const [allUsers, setAllUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const usersPerPage = 10;

  useEffect(() => {
    async function fetchAllUsers() {
      try {
        const users = await fetchUsers();
        setAllUsers(users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    if (isAdmin) {
      fetchAllUsers();
    }
  }, [isAdmin]);

  const paginatedUsers = useMemo(() => {
    const startIndex = (page - 1) * usersPerPage;
    return allUsers.slice(startIndex, startIndex + usersPerPage);
  }, [allUsers, page]);

  const totalPages = Math.ceil(allUsers.length / usersPerPage);

  const handleUserChange = user => {
    setSelectedUser(user);
    onUserChange(user);
    onClose();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleAdminModeToggle = () => {
    toggleAdminMode(currentUser);
    if (adminModeActive && originalUser) {
      onUserChange(originalUser);
    }
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          style: {
            minWidth: 200,
            maxHeight: '80vh',
          },
        },
      }}
    >
      <div>
        {' '}
        {adminModeActive && (
          <>
            <Typography variant="h6" sx={{ mx: 2, my: 1 }}>
              Users
            </Typography>
            <Divider />
            {paginatedUsers.map(user => (
              <MenuItem key={user.id} onClick={() => handleUserChange(user)}>
                <Typography variant="body2">{user.username}</Typography>
              </MenuItem>
            ))}
            <Divider />
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="secondary"
              size="small"
              sx={{ display: 'flex', justifyContent: 'center', my: 1 }}
            />
            <Divider />
          </>
        )}
        <MenuItem onClick={handleAdminModeToggle}>
          <Switch
            checked={adminModeActive}
            onChange={e => e.stopPropagation()}
            color="secondary"
            size="small"
          />
          <Typography variant="body2" sx={{ ml: 1 }}>
            Admin Mode
          </Typography>
        </MenuItem>
      </div>
    </Menu>
  );
}

export default UserMenuAdmin;
