import { Edit as EditIcon } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { fetchProjects } from '../../api/fetchers';

const ActivityUploadTracker = ({ refresh }) => {
  const [activities, setActivities] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchActivities = async () => {
      setLoading(true);
      try {
        const projects = await fetchProjects(0, 25);
        if (Array.isArray(projects) && projects.length > 0) {
          const groupedActivities = projects
            .sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated))
            .reduce((acc, project) => {
              const date = project.last_updated.split('T')[0]; // Assuming ISO date format
              if (!acc[date]) {
                acc[date] = [];
              }
              acc[date].push({
                type: 'edit',
                description: `Project "${project.name}" was updated`,
                user: 'System',
                timestamp: project.last_updated,
              });
              return acc;
            }, {});

          setActivities(groupedActivities);
        } else {
          console.warn('No projects found or empty array returned');
          setActivities({});
        }
      } catch (error) {
        console.error('Error fetching activities:', error);
        setActivities({});
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [refresh]);

  const formatDate = dateString => {
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '400px',
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="h6"
        sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}
      >
        Recent Activity
      </Typography>
      <List
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          maxHeight: 'calc(100% - 48px)',
          py: 0,
        }}
      >
        {Object.entries(activities).length > 0 ? (
          Object.entries(activities).map(([date, dateActivities]) => (
            <React.Fragment key={date}>
              <ListItem sx={{ py: 0.5, bgcolor: 'action.hover' }}>
                <Typography variant="subtitle2">{formatDate(date)}</Typography>
              </ListItem>
              {dateActivities.map((activity, index) => (
                <ListItem key={index} alignItems="flex-start" sx={{ py: 0.5 }}>
                  <EditIcon
                    color="primary"
                    sx={{ mr: 1, mt: 0.5, fontSize: '0.8rem' }}
                  />
                  <ListItemText
                    primary={activity.description}
                    primaryTypographyProps={{ variant: 'body2' }}
                    secondary={activity.user}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                </ListItem>
              ))}
              <Divider component="li" />
            </React.Fragment>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No recent activities" />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default ActivityUploadTracker;
