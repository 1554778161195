import { Box, InputLabel, Slider } from '@mui/material';
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { fetchUserFinancialModelData } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';
import dataGridStyles from '../../styles/dataGridStyles';
import DataGridQuickFilterButtonGroupDynamic from '../buttons/DataGridQuickFilterButtonGroupDynamic';

const formatCurrency = value => {
  if (value == null || isNaN(value) || value === 0) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const formatDataForGrid = (data, selectedKpi) => {
  const formattedData = [];
  const uniqueYears = new Set();
  const uniqueKpis = new Set();

  data.forEach(company => {
    const companyRow = {
      id: `company-${company.id}`,
      company: company.name,
      companyId: company.id,
      isCompany: true,
    };

    company.projects.forEach(project => {
      const projectRow = {
        id: `project-${company.id}-${project.id}`,
        company: company.name,
        companyId: company.id,
        project: project.name,
        projectId: project.id,
        isProject: true,
      };

      project.kpis.forEach(kpi => {
        uniqueKpis.add(kpi.name);

        if (kpi.name === selectedKpi || selectedKpi === '') {
          Object.entries(kpi.values).forEach(([year, value]) => {
            const yearInt = parseInt(year, 10);
            if (!isNaN(yearInt)) {
              projectRow[yearInt] = (projectRow[yearInt] || 0) + value;
              companyRow[yearInt] = (companyRow[yearInt] || 0) + value;
              uniqueYears.add(yearInt);
            }
          });
        }
      });

      formattedData.push(projectRow);
    });

    formattedData.push(companyRow);
  });

  return {
    formattedData,
    uniqueYears: Array.from(uniqueYears).sort((a, b) => a - b),
    uniqueKpis: Array.from(uniqueKpis),
  };
};

const FinancialModelSingleKpi = ({ userId }) => {
  const [data, setData] = useState(null); // Raw data from API
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Project Revenue');
  const [selectedKpi, setSelectedKpi] = useState('Project Revenue');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [kpis, setKpis] = useState([]);
  const [yearRange, setYearRange] = useState([2020, 2030]);

  const { theme } = useTheme();
  const apiRef = useGridApiRef();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedData = await fetchUserFinancialModelData(userId);
      setData(fetchedData);

      const uniqueKpisSet = new Set();
      fetchedData.forEach(company => {
        company.projects.forEach(project => {
          project.kpis.forEach(kpi => {
            uniqueKpisSet.add(kpi.name);
          });
        });
      });
      const uniqueKpisArray = Array.from(uniqueKpisSet);
      setKpis(uniqueKpisArray);
      if (!selectedKpi) {
        setSelectedKpi(uniqueKpisArray[0]);
      }

      setCompanies(
        fetchedData.map(company => ({ id: company.id, name: company.name })),
      );
    } catch (error) {
      console.error('Error fetching financial model data:', error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const { formattedData, uniqueYears } = useMemo(() => {
    if (!data) return { formattedData: [], uniqueYears: [] };
    return formatDataForGrid(data, selectedKpi);
  }, [data, selectedKpi]);

  useEffect(() => {
    setFinancialData(formattedData);

    if (uniqueYears.length > 0) {
      setYears(uniqueYears);
      setYearRange([Math.min(...uniqueYears), Math.max(...uniqueYears)]);
    } else {
      setYears([]);
      setYearRange([2020, 2030]);
    }
  }, [formattedData, uniqueYears]);

  const handleQuickFilter = useCallback(newFilter => {
    setActiveFilter(newFilter);
    setSelectedKpi(newFilter);
  }, []);

  const handleYearRangeChange = useCallback((event, newValue) => {
    setYearRange(newValue);
  }, []);

  const columns = useMemo(() => {
    const yearColumns = years
      .filter(year => year >= yearRange[0] && year <= yearRange[1])
      .map(year => ({
        field: year.toString(),
        headerName: year.toString(),
        width: 150,
        renderCell: params => formatCurrency(params.value),
        cellClassName: 'data-cell',
      }));

    return [
      {
        field: 'company',
        headerName: 'Company',
        width: 200,
        renderCell: params => (params.row.isCompany ? params.value : ''),
        hide: true,
      },
      {
        field: 'project',
        headerName: 'Project',
        width: 200,
        renderCell: params => (params.row.isProject ? params.value : ''),
        hide: true,
      },
      ...yearColumns,
    ];
  }, [years, yearRange]);

  const filteredRows = useMemo(() => {
    return financialData.filter(row => {
      if (selectedCompanies.length === 0) return true;
      return selectedCompanies.includes(row.companyId);
    });
  }, [financialData, selectedCompanies]);

  const getTreeDataPath = useCallback(row => {
    return row.isCompany ? [row.company] : [row.company, row.project];
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
          <DataGridQuickFilterButtonGroupDynamic
            activeFilter={activeFilter}
            handleQuickFilter={handleQuickFilter}
            kpis={kpis}
          />
        </Box>
        {years.length > 0 && (
          <Box sx={{ width: 300, mx: 2 }}>
            <InputLabel id="year-range-slider">Select Year Range</InputLabel>
            <Slider
              value={yearRange}
              onChange={handleYearRangeChange}
              valueLabelDisplay="auto"
              min={Math.min(...years)}
              max={Math.max(...years)}
              marks
            />
          </Box>
        )}
      </Box>
      <Box sx={{ height: 600, width: '100%', ...dataGridStyles }}>
        <DataGridPremium
          rows={filteredRows}
          columns={columns}
          loading={loading}
          apiRef={apiRef}
          rowHeight={35}
          defaultGroupingExpansionDepth={-1}
          getTreeDataPath={getTreeDataPath}
          treeData
          groupingColDef={{
            headerName: 'Company / Project',
            width: 400,
          }}
          initialState={{
            pinnedColumns: { left: ['__tree_data_group__'] },
            columns: {
              columnVisibilityModel: {
                company: false,
                project: false,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          sx={{
            '& .data-cell': {
              backgroundColor: '#f0f0f0',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default FinancialModelSingleKpi;
