import DashboardIcon from '@mui/icons-material/Dashboard';
import { Alert, AlertTitle, Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { fetchCurrentUserProfile, fetchUserAccounts } from '../api/fetchers';
import DashboardLayout from '../components/layouts/DashboardLayout';
import useTheme from '../hooks/useTheme';

// Import necessary fetchers

const Support = ({ userId }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState('');
  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const fetchCurrentUserAndAccount = useCallback(async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const userAccounts =
        (await fetchUserAccounts(currentUserProfile.id)) || [];
      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUserAndAccount();
  }, [fetchCurrentUserAndAccount]);

  const handleUserChange = useCallback(async user => {
    setSelectedUser(user);
    try {
      const userAccounts = (await fetchUserAccounts(user.id)) || [];
      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount ? defaultAccount : '');
    } catch (error) {
      console.error('Error fetching user accounts:', error);
    }
  }, []);

  return (
    <DashboardLayout
      title="Feedback"
      icon={<DashboardIcon />}
      onUserChange={handleUserChange}
      selectedUser={selectedUser}
      selectedAccount={selectedAccount}
    >
      {/* <Box className="card bg-white dark:bg-card p-4 rounded shadow mb-4">
        
      </Box> */}

      <Alert severity="info">
        <AlertTitle> Support </AlertTitle>
        Need help? Please use the form below to submit feedback.
      </Alert>

      <Box className="bg-white dark:bg-card p-4 rounded shadow mb-3 flex-grow flex flex-col">
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/appapvetJQfx9ytOE/pagmZNdZGjh7wER9R/form"
          style={{
            background: 'transparent',
            border: `0px solid ${currentTheme === 'dark' ? '#444' : '#ccc'}`,
            borderRadius: '4px',
            flexGrow: 1,
            height: '100%',
            minHeight: '1200px',
            width: '100%',
          }}
          title="Support Form"
        />
      </Box>
    </DashboardLayout>
  );
};

export default Support;
