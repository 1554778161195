import { Button, Grid, MenuItem, Paper, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  fetchCurrentUserProfile,
  fetchUserAccounts,
  fetchUserProjects,
} from '../../../api/fetchers';
import {
  createOrganization,
  deleteOrganization,
  fetchOrganizations,
  updateOrganization,
} from '../../../api/organization';
import { useAdminMode } from '../../../contexts/AdminModeContext';
import DashboardLayout from '../../layouts/DashboardLayout';
import ProjectSelector from '../../ui-elements/ProjectSelector';
import ConfirmationDialog from '../../ui-modals/ConfirmationDialog';
import Toast from '../../ui-modals/ToastMessage';
import OrgFormInput from './OrgFormInput';

const MemoizedDataGrid = React.memo(
  ({ rows, columns, loading, onSelectionModelChange }) => (
    <DataGridPremium
      rows={rows}
      columns={columns}
      loading={loading}
      autoHeight
      checkboxSelection
      disableSelectionOnClick
      onSelectionModelChange={onSelectionModelChange}
    />
  ),
);

MemoizedDataGrid.displayName = 'MemoizedDataGrid';

const OrgAdminPanel = () => {
  const { isAdmin } = useAdminMode();
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editingOrgId, setEditingOrgId] = useState(null);
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    content: '',
    orgId: null,
  });

  const memoizedSelectedUser = useMemo(() => selectedUser, [selectedUser?.id]);

  // Replace useForm with local state
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    ownership_percentage: '',
    parent_id: '',
    notes: '',
  });

  useEffect(() => {
    const fetchCurrentUserAndData = async () => {
      try {
        const currentUserProfile = await fetchCurrentUserProfile();
        if (!selectedUser || currentUserProfile.id !== selectedUser.id) {
          setSelectedUser(currentUserProfile);
          // Fetch projects and accounts only if user changes
          const userProjects = await fetchUserProjects(currentUserProfile.id);
          setProjects(userProjects);
          const userAccounts = await fetchUserAccounts(currentUserProfile.id);
          const defaultAccount =
            userAccounts.find(
              acc => acc.name === `${currentUserProfile.username} Account`,
            ) || userAccounts[0];
          setSelectedAccount(defaultAccount ? defaultAccount : '');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchCurrentUserAndData();
  }, [selectedUser]);

  useEffect(() => {
    if (selectedProjectId) {
      fetchOrganizationsData();
    }
  }, [selectedProjectId]);

  const fetchOrganizationsData = useCallback(async () => {
    if (selectedProjectId) {
      setLoading(true);
      try {
        const data = await fetchOrganizations(selectedProjectId);
        setOrganizations(data);
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
      setLoading(false);
    }
  }, [selectedProjectId]);

  useEffect(() => {
    fetchOrganizationsData();
  }, [fetchOrganizationsData]);

  const handleProjectSelect = useCallback(
    projectId => {
      if (projectId !== selectedProjectId) {
        setSelectedProjectId(projectId);
      }
    },
    [selectedProjectId],
  );

  const handleEditOrg = useCallback(org => {
    setEditingOrgId(org.id);
    setFormData(org);
  }, []);

  const handleDeleteClick = useCallback(
    orgId => {
      const orgToDelete = organizations.find(org => org.id === orgId);
      setConfirmDialog({
        open: true,
        title: 'Confirm Organization Deletion',
        content: `Are you sure you want to delete the organization "${orgToDelete.name}"? This action cannot be undone.`,
        orgId: orgId,
      });
    },
    [organizations],
  );

  const handleAddOrg = useCallback(
    async data => {
      try {
        const createdOrg = await createOrganization({
          ...data,
          project_id: selectedProjectId,
        });
        setOrganizations(prev => [...prev, createdOrg]);
        setToast({
          open: true,
          message: 'Organization added successfully!',
          severity: 'success',
        });
      } catch (error) {
        console.error('Error creating organization:', error);
        setToast({
          open: true,
          message: 'Error adding organization. Please try again.',
          severity: 'error',
        });
      }
    },
    [selectedProjectId],
  );

  const handleUpdateOrg = useCallback(
    async data => {
      try {
        const updatedOrg = await updateOrganization(editingOrgId, data);
        setOrganizations(prev =>
          prev.map(org => (org.id === editingOrgId ? updatedOrg : org)),
        );
        setToast({
          open: true,
          message: 'Organization updated successfully!',
          severity: 'success',
        });
      } catch (error) {
        console.error('Error updating organization:', error);
        setToast({
          open: true,
          message: 'Error updating organization. Please try again.',
          severity: 'error',
        });
      }
    },
    [editingOrgId],
  );

  const handleInputChange = useCallback(e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (editingOrgId) {
        await handleUpdateOrg(formData);
      } else {
        await handleAddOrg(formData);
      }
      setFormData({
        name: '',
        type: '',
        ownership_percentage: '',
        parent_id: '',
        notes: '',
      });
      setEditingOrgId(null);
    },
    [editingOrgId, handleUpdateOrg, handleAddOrg, formData],
  );

  const handleConfirmDelete = async () => {
    try {
      await deleteOrganization(confirmDialog.orgId);
      setOrganizations(orgs =>
        orgs.filter(org => org.id !== confirmDialog.orgId),
      );
      setToast({
        open: true,
        message: 'Organization deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting organization:', error);
      setToast({
        open: true,
        message: 'Error deleting organization. Please try again.',
        severity: 'error',
      });
    } finally {
      setConfirmDialog({ ...confirmDialog, open: false });
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const handleUserChange = useCallback(
    async user => {
      if (!selectedUser || user.id !== selectedUser.id) {
        setSelectedUser(user);
      }
      try {
        const userAccounts = await fetchUserAccounts(user.id);
        const defaultAccount =
          userAccounts.find(acc => acc.name === `${user.username} Account`) ||
          userAccounts[0];
        setSelectedAccount(defaultAccount || '');
      } catch (error) {
        console.error('Error fetching user accounts:', error);
      }
    },
    [selectedUser],
  );

  const memoizedOrganizations = useMemo(() => organizations, [organizations]);

  const handleSelectionModelChange = useCallback(newSelectionModel => {
    setSelectedRows(newSelectionModel);
  }, []);

  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'name', headerName: 'Name', width: 200, editable: true },
      { field: 'type', headerName: 'Type', width: 150, editable: true },
      {
        field: 'ownership_percentage',
        headerName: 'Ownership %',
        type: 'number',
        width: 150,
        editable: true,
      },
      {
        field: 'parent_id',
        headerName: 'Parent ID',
        width: 120,
        editable: true,
      },
      {
        field: 'project_id',
        headerName: 'Project ID',
        width: 120,
        editable: false,
      },
      {
        field: 'notes',
        headerName: 'Notes',
        width: 200,
        editable: true,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        renderCell: params => (
          <>
            <Button onClick={() => handleEditOrg(params.row)}>Edit</Button>
            <Button
              onClick={() => handleDeleteClick(params.row.id)}
              color="secondary"
            >
              Delete
            </Button>
          </>
        ),
      },
    ],
    [handleEditOrg, handleDeleteClick],
  );

  return (
    <DashboardLayout
      title="Organization Admin Panel"
      onUserChange={handleUserChange}
      selectedUser={memoizedSelectedUser}
      selectedAccount={selectedAccount}
    >
      <ProjectSelector
        userId={selectedUser?.id}
        onProjectSelect={handleProjectSelect}
        selectedProjectId={selectedProjectId}
        projects={projects}
      />
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <form onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <OrgFormInput
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      fullWidth
                      label="Name"
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <OrgFormInput
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                      fullWidth
                      label="Type"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <OrgFormInput
                      name="ownership_percentage"
                      value={formData.ownership_percentage}
                      onChange={handleInputChange}
                      fullWidth
                      label="Ownership %"
                      type="number"
                      inputProps={{ min: 0, max: 100, step: 0.01 }}
                      required
                      isPercentage
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <OrgFormInput
                      name="parent_id"
                      value={formData.parent_id}
                      onChange={handleInputChange}
                      fullWidth
                      label="Parent Organization"
                      select
                    >
                      <MenuItem value="">None</MenuItem>
                      {organizations.map(org => (
                        <MenuItem key={org.id} value={org.id}>
                          {org.name}
                        </MenuItem>
                      ))}
                    </OrgFormInput>
                  </Grid>
                  <Grid item xs={3}>
                    <OrgFormInput
                      name="notes"
                      value={formData.notes}
                      onChange={handleInputChange}
                      fullWidth
                      label="Notes"
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button type="submit" variant="contained" color="primary">
                      {editingOrgId ? 'Update' : 'Add'} Organization
                    </Button>
                    {editingOrgId && (
                      <Button
                        onClick={() => {
                          setEditingOrgId(null);
                          setFormData({
                            name: '',
                            type: '',
                            ownership_percentage: '',
                            parent_id: '',
                            notes: '',
                          });
                        }}
                        variant="outlined"
                        sx={{ ml: 1 }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <MemoizedDataGrid
                rows={memoizedOrganizations}
                columns={columns}
                loading={loading}
                onSelectionModelChange={handleSelectionModelChange}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={() => setToast({ ...toast, open: false })}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        content={confirmDialog.content}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </DashboardLayout>
  );
};

export default OrgAdminPanel;
