import { Home } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { app_paths } from '../App';
import {
  changePassword,
  fetchCurrentUserProfile,
  updateCurrentUserProfile,
} from '../api/fetchers';
import DashboardLayout from '../components/layouts/DashboardLayout';

const AccountSettings = () => {
  const [selectedSection, setSelectedSection] = useState('Profile');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userData = await fetchCurrentUserProfile();
        setName(userData.username || '');
        setEmail(userData.email || '');
      } catch (error) {
        console.error('Error fetching user data:', error);
        setSnackbar({
          open: true,
          message: 'Error loading user data',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, []);

  const handleUpdateProfile = async () => {
    try {
      await updateCurrentUserProfile({ username: name });
      setSnackbar({
        open: true,
        message: 'Profile updated successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      setSnackbar({
        open: true,
        message: 'Error updating profile',
        severity: 'error',
      });
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setSnackbar({
        open: true,
        message: 'New passwords do not match',
        severity: 'error',
      });
      return;
    }
    try {
      await changePassword({
        old_password: oldPassword,
        new_password: newPassword,
      });
      setSnackbar({
        open: true,
        message: 'Password changed successfully',
        severity: 'success',
      });
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      setSnackbar({
        open: true,
        message: 'Error changing password',
        severity: 'error',
      });
    }
  };

  const renderContent = () => {
    if (loading) {
      return <Typography>Loading user data...</Typography>;
    }

    switch (selectedSection) {
      case 'Profile':
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Personal information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  helperText="Change your display name. This will be displayed in reports and within the portal."
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  value={email}
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  helperText="Contact support to change email."
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateProfile}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        );
      case 'Password':
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  label="Old Password"
                  value={oldPassword}
                  onChange={e => setOldPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  label="Confirm New Password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleChangePassword}
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </Box>
        );
      case 'API keys':
        return <Typography>API keys management goes here</Typography>;
      case 'Integrations':
        return <Typography>Integrations settings go here</Typography>;
      case 'Early access':
        return <Typography>Early access settings go here</Typography>;
      case 'Delete account':
        return <Typography>Account deletion options go here</Typography>;
      default:
        return null;
    }
  };

  return (
    <DashboardLayout title="Account Settings">
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '24px' }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={9}>
            <Typography variant="h4" gutterBottom>
              Account settings
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                component={Link}
                to={app_paths.HOME_PATH}
                startIcon={<Home />}
                color="inherit"
                size="small"
              >
                Back to Home
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <List component="nav" sx={{ borderRight: '1px solid #e0e0e0' }}>
              {[
                'Profile',
                'Password',
                'API keys',
                'Integrations',
                'Early access',
                'Delete account',
              ].map(text => (
                <ListItem
                  button
                  key={text}
                  selected={selectedSection === text}
                  onClick={() => setSelectedSection(text)}
                  disabled={[
                    'API keys',
                    'Integrations',
                    'Early access',
                    'Delete account',
                  ].includes(text)}
                  sx={{
                    borderLeft:
                      selectedSection === text ? '2px solid #1976d2' : 'none',
                    backgroundColor:
                      selectedSection === text
                        ? 'rgba(25, 118, 210, 0.08)'
                        : 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(25, 118, 210, 0.04)',
                    },
                  }}
                >
                  <ListItemText
                    primary={text}
                    primaryTypographyProps={{
                      color:
                        selectedSection === text ? 'primary' : 'textPrimary',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <Paper elevation={3} sx={{ padding: '24px' }}>
              {renderContent()}
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
};

export default AccountSettings;
