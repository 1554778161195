import { useCallback, useEffect, useState } from 'react';

import { fetchKPIData } from '../api/fetchers';

const useKPIData = (projectId, kpiSlug, aggregation) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    if (!projectId || !kpiSlug) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetchKPIData(projectId, kpiSlug, aggregation);
      setData(response);
    } catch (err) {
      setError(err);
      // console.error('Error fetching KPI data:', err);
    } finally {
      setLoading(false);
    }
  }, [projectId, kpiSlug, aggregation]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error };
};

export default useKPIData;
