import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';

import {
  createUserAccountRelation,
  deleteUserAccountRelation,
  fetchAccounts,
  fetchAllUserAccounts,
  fetchUsers,
} from '../../api/fetchers';
import ConfirmationDialog from '../ui-modals/ConfirmationDialog';
import Toast from '../ui-modals/ToastMessage';

const AdminUserAccountPanel = () => {
  const [users, setUsers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [userAccounts, setUserAccounts] = useState([]);
  const [newUserAccount, setNewUserAccount] = useState({
    user_id: '',
    account_id: '',
  });
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    content: '',
    userAccountId: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [userAccountsData, accountsData, usersData] = await Promise.all([
          fetchAllUserAccounts(),
          fetchAccounts(),
          fetchUsers(),
        ]);
        setUserAccounts(userAccountsData);
        setAccounts(accountsData);
        setUsers(usersData);
      } catch (error) {
        console.error('Error loading data:', error);
        setToast({
          open: true,
          message: 'Error loading data. Please try again.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };
    loadInitialData();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewUserAccount(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setNewUserAccount({
      user_id: '',
      account_id: '',
    });
  };

  const handleAddUserAccount = async () => {
    try {
      const createdUserAccount =
        await createUserAccountRelation(newUserAccount);
      setUserAccounts([...userAccounts, createdUserAccount]);
      resetForm();
      setToast({
        open: true,
        message: 'User-Account relation added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding user-account relation:', error);
      setToast({
        open: true,
        message: 'Error adding user-account relation. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleDeleteClick = (userId, accountId) => {
    const userAccountToDelete = userAccounts.find(
      ua => ua.user_id === userId && ua.account_id === accountId,
    );
    setConfirmDialog({
      open: true,
      title: 'Confirm Deletion',
      content: `Are you sure you want to delete the relation between "${userAccountToDelete.username}" and "${userAccountToDelete.account_name}"? This action cannot be undone.`,
      userId,
      accountId,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteUserAccountRelation(
        confirmDialog.userId,
        confirmDialog.accountId,
      );
      setUserAccounts(
        userAccounts.filter(
          ua =>
            ua.user_id !== confirmDialog.userId ||
            ua.account_id !== confirmDialog.accountId,
        ),
      );
      setToast({
        open: true,
        message: 'User-Account relation deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting user-account relation:', error);
      setToast({
        open: true,
        message: 'Error deleting user-account relation. Please try again.',
        severity: 'error',
      });
    } finally {
      setConfirmDialog({ ...confirmDialog, open: false });
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const columns = [
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'account_name', headerName: 'Account Name', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: params => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            handleDeleteClick(params.row.user_id, params.row.account_id)
          }
        >
          Delete
        </Button>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Manage User-Account Relations
      </Typography>
      <Box className="h-[50vh] w-full mt-2" sx={{ flexGrow: 1, mb: 2 }}>
        <DataGridPremium
          rows={userAccounts}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          disableSelectionOnClick
          getRowId={row => `${row.user_id}-${row.account_id}`}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>User</InputLabel>
              <Select
                name="user_id"
                value={newUserAccount.user_id}
                onChange={handleInputChange}
                required
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                }}
              >
                {users.map(user => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Account</InputLabel>
              <Select
                name="account_id"
                value={newUserAccount.account_id}
                onChange={handleInputChange}
                required
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                }}
              >
                {accounts.map(account => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddUserAccount}
              fullWidth
            >
              Add User-Account Relationship
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={() => setToast({ ...toast, open: false })}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        content={confirmDialog.content}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Box>
  );
};

export default AdminUserAccountPanel;
