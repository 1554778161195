import {
  HelpCenterTwoTone as HelpIcon,
  AccountBoxTwoTone as UserIcon,
} from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { app_paths } from '../../App';
import { logout } from '../../api/fetchers';
import { useAdminMode } from '../../contexts/AdminModeContext';
import ThemeContext from '../../contexts/ThemeContext';
import '../../styles/index.css';
import AuthStatus from '../auth/AuthStatus';
import UserMenu from '../ui-modals/UserMenu';
import UserSupportMenu from '../ui-modals/UserSupportMenu';

const TopNavBar = ({ title, onUserChange, selectedUser }) => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';
  const [anchorEl, setAnchorEl] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const { adminModeActive, clearAdminMode } = useAdminMode();
  const navigate = useNavigate();

  const handleUserMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpClick = event => {
    setHelpAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setHelpAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    clearAdminMode();
    sessionStorage.clear();
    localStorage.clear();
    navigate(app_paths.LOGIN_PATH);
    handleMenuClose();
  };

  const iconColor = isDarkMode ? '#e0e0e0' : '#b0b0b0';

  return (
    <div
      className="sticky top-0 bg-indigo-100 dark:bg-gray-800 text-black dark:text-white flex justify-between items-center px-4 py-2 shadow z-25"
      style={{ zIndex: 100 }}
    >
      <Box ml={2} className="flex items-end">
        <Typography variant="h6" className="font-bold mr-2">
          {title}
        </Typography>
      </Box>
      <Box ml="auto" className="flex items-end mr-0">
        <Typography
          variant="caption"
          className="text-gray-500 dark:text-gray-400 mb-2 mr-12"
        >
          {selectedUser ? selectedUser.username : '-'}
        </Typography>
      </Box>
      <Box mr={1}>
        <AuthStatus />
      </Box>
      <div className="flex items-center space-x-2">
        <IconButton
          onClick={handleHelpClick}
          sx={{
            color: iconColor,
            '&:hover': {
              backgroundColor: 'transparent',
              '& .MuiSvgIcon-root': {
                color: 'var(--bg-button-default)',
              },
            },
          }}
        >
          <HelpIcon />
        </IconButton>
        <UserSupportMenu
          anchorEl={helpAnchorEl}
          open={Boolean(helpAnchorEl)}
          onClose={handleMenuClose}
          isDarkMode={isDarkMode}
          toggleTheme={toggleTheme}
          iconColor={iconColor}
        />
        <IconButton
          onClick={handleUserMenuClick}
          size="small"
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <UserIcon sx={{ color: '#4CAF50' }} />
        </IconButton>
        <UserMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onUserChange={onUserChange}
          selectedUser={selectedUser}
          menuWidth={200}
          onLogout={handleLogout}
        />
        {adminModeActive && (
          <div className="bg-red-500 text-white px-2 py-1 rounded text-xs ml-4">
            Admin Mode Active
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavBar;
