import { Button, CircularProgress, Tooltip } from '@mui/material';
import React, { useState } from 'react';

import { fetchReportPDF, fetchUserNames } from '../../api/fetchers';

const GeneratePDFButton = ({ userId, options, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleGeneratePDF = async () => {
    setIsLoading(true);
    try {
      const [response, userInfo] = await Promise.all([
        fetchReportPDF(userId, options),
        fetchUserNames(userId),
      ]);

      const username = userInfo || `user${userId}`;

      const today = new Date().toISOString().split('T')[0].replace(/-/g, '');
      const filename = `${username}_${today}_investmentreport.pdf`;

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('An error occurred while generating the PDF. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Tooltip
      title={
        disabled
          ? 'Please select companies and KPIs to generate the report.'
          : ''
      }
      arrow
    >
      <span>
        <Button
          variant="contained"
          onClick={handleGeneratePDF}
          disabled={disabled || isLoading}
          startIcon={
            isLoading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          {isLoading ? 'Generating...' : 'Generate PDF'}
        </Button>
      </span>
    </Tooltip>
  );
};

export default GeneratePDFButton;
