import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';

import FinancialModelMultiKpi from '../tables/FinancialModelMultiKpi';
import FinancialModelSingleKpi from '../tables/FinancialModelSingleKpi';

const FinancialModelDataGrid = ({ userId }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Single-KPI Aggregated" />
        <Tab label="Multi-KPI Detailed" />
      </Tabs>
      {tabValue === 0 && (
        <Box sx={{ p: 2 }}>
          <FinancialModelSingleKpi userId={userId} />
        </Box>
      )}
      {tabValue === 1 && (
        <Box sx={{ p: 2 }}>
          <FinancialModelMultiKpi userId={userId} />
        </Box>
      )}
    </Box>
  );
};

export default FinancialModelDataGrid;
