import { Box, CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import {
  fetchAggregatedKPIs,
  fetchCurrentUserProfile,
  fetchUserAccounts,
  fetchUserCompanies,
  fetchUserPortfolioHierarchy,
  fetchUserProjects,
} from '../api/fetchers';
import EquityDistributionPieChart from '../components/charts/EquityDistributionPieChart';
import InvestmentPerformanceChart from '../components/charts/InvestmentPerformanceChart';
import ProjectTimeline from '../components/charts/ProjectTimeline';
import DashboardLayout from '../components/layouts/DashboardLayout';
import FinancialModelDataGrid from '../components/tables/FinancialModelDataGrid';
import PortfolioHierarchyGrid from '../components/tables/PortfolioHierarchyDataGrid';
import PortfolioSummaryDataGrid from '../components/tables/PortfolioSummaryDataGrid';
import PortfolioUserCompaniesBadge from '../components/tables/PortfolioUserCompaniesBadge';
import ProjectSummaryTable from '../components/tables/ProjectSummaryTable';
import PortfolioEntitiesCard from '../components/ui-elements/PortfolioEntitiesCard';
import PortfolioMetricCard from '../components/ui-elements/PortfolioMetricCard';
import { useAdminMode } from '../contexts/AdminModeContext';
import useTheme from '../hooks/useTheme';
import { portfolioDashboardLabels } from '../labels/dashboard_labels';
import ErrorBoundary from '../utils/errorBoundary';

const formatCurrency = value => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const PortfolioDashboard = ({ userId }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [kpiData, setKpiData] = useState({
    equityContributed: { total: 0, yearly: [] },
    capitalReturns: { total: 0, yearly: [] },
  });
  const [activeTab, setActiveTab] = useState(0);
  const [portfolioHierarchyData, setPortfolioHierarchyData] = useState([]);
  const { isAdmin, adminModeActive } = useAdminMode();
  const { theme } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const fetchData = useCallback(async user => {
    if (!user || !user.id) {
      return;
    }

    try {
      const [
        userProjects,
        userCompanies,
        userAccounts,
        kpisData,
        hierarchyData,
      ] = await Promise.all([
        fetchUserProjects(user.id),
        fetchUserCompanies(user.id),
        fetchUserAccounts(user.id),
        fetchAggregatedKPIs(
          user.id,
          ['PR_EQTCNT', 'PR_EQTDST', 'PR_PFTDST', 'PR_INTDST'],
          'annual',
        ),
        fetchUserPortfolioHierarchy(user.id),
      ]);

      setProjects(userProjects);
      setCompanies(userCompanies);
      setAccounts(userAccounts);

      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount || null);

      setPortfolioHierarchyData(hierarchyData);

      // Process KPI data
      const equityContributed = kpisData.find(
        kpi => kpi.kpi_slug === 'PR_EQTCNT',
      );
      const capitalReturns = kpisData.filter(kpi =>
        ['PR_EQTDST', 'PR_PFTDST', 'PR_INTDST'].includes(kpi.kpi_slug),
      );

      const aggregateYearlyData = data => {
        const yearlyMap = new Map();
        data.forEach(item => {
          const year = new Date(item.date).getFullYear();
          const value = parseFloat(item.amount_value);
          yearlyMap.set(year, (yearlyMap.get(year) || 0) + value);
        });
        return Array.from(yearlyMap, ([year, value]) => ({ year, value })).sort(
          (a, b) => a.year - b.year,
        );
      };

      const processedKpiData = {
        equityContributed: {
          total: parseFloat(equityContributed.total_value),
          yearly: aggregateYearlyData(equityContributed.data),
        },
        capitalReturns: {
          total: capitalReturns.reduce(
            (sum, kpi) => sum + parseFloat(kpi.total_value),
            0,
          ),
          yearly: aggregateYearlyData(capitalReturns.flatMap(kpi => kpi.data)),
        },
      };

      setKpiData(processedKpiData);
    } catch (error) {
      console.error('Error fetching data:', error);
      console.error('Error details:', error.response?.data || error.message);
    }
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);
      await fetchData(currentUserProfile);
    };

    initializeData();
  }, [fetchData]);

  const handleUserChange = useCallback(
    async user => {
      setSelectedUser(user);
      await fetchData(user);
    },
    [fetchData],
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const calculateChange = yearly => {
    if (yearly.length < 2) return 0;
    const lastYear = yearly[yearly.length - 1].value;
    const secondLastYear = yearly[yearly.length - 2].value;
    const change = ((lastYear - secondLastYear) / secondLastYear) * 100;
    return change
  };

  return (
    <DashboardLayout
      title="Portfolio Dashboard"
      onUserChange={handleUserChange}
      selectedUser={selectedUser}
      selectedAccount={selectedAccount}
    >
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="portfolio dashboard tabs"
          >
            <Tab label="Overview" />
            <Tab label="Investments" />
            <Tab label="Financial Model" />
            <Tab label="Timeline" />
            <Tab label="Asset Summary" />
          </Tabs>
        </Box>

        <Box sx={{ mt: 4 }}>
          {activeTab === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <PortfolioMetricCard
                  title={portfolioDashboardLabels.TOTAL_EQUITY_CONTRIBUTION}
                  value={formatCurrency(kpiData.equityContributed.total)}
                  change={calculateChange(kpiData.equityContributed.yearly)}
                  breakdown={kpiData.equityContributed.yearly.map(item => ({
                    label: item.year,
                    value: formatCurrency(item.value),
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <PortfolioMetricCard
                  title={portfolioDashboardLabels.TOTAL_CAPITAL_RETURNS}
                  value={formatCurrency(kpiData.capitalReturns.total)}
                  change={calculateChange(kpiData.capitalReturns.yearly)}
                  breakdown={kpiData.capitalReturns.yearly.map(item => ({
                    label: item.year,
                    value: formatCurrency(item.value),
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <PortfolioEntitiesCard
                  title="Total Projects"
                  entities={projects.map(project => ({
                    id: project.id,
                    name: project.name,
                    type: 'project',
                    value: project.value,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <PortfolioEntitiesCard
                  title="Active Companies"
                  entities={companies.map(company => ({
                    id: company.id,
                    name: company.name,
                    type: 'company',
                    value: company.value,
                  }))}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <ErrorBoundary>
                  <PortfolioSummaryDataGrid
                    userId={selectedUser?.id}
                    selectedCompanyIds={companies.map(company => company.id)}
                  />
                </ErrorBoundary>
              </Grid>
              <Grid item xs={12} md={4}>
                <PortfolioUserCompaniesBadge userId={selectedUser?.id} />
              </Grid>
            </Grid>
          )}
          {activeTab === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <ErrorBoundary>
                  {selectedUser ? (
                    <PortfolioHierarchyGrid userId={selectedUser.id} />
                  ) : (
                    <CircularProgress />
                  )}
                </ErrorBoundary>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <InvestmentPerformanceChart data={kpiData} />
                  </Grid>
                  <Grid item>
                    <EquityDistributionPieChart
                      portfolioData={portfolioHierarchyData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {activeTab === 2 && (
            <ErrorBoundary>
              <FinancialModelDataGrid userId={selectedUser?.id} />
            </ErrorBoundary>
          )}
          {activeTab === 3 && (
            <Grid container spacing={12}>
              <Grid item xs={12} md={12}>
                <ProjectTimeline projects={projects} />
              </Grid>
            </Grid>
          )}
          {activeTab === 4 && (
            <ProjectSummaryTable projects={projects} title="Assets" />
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default PortfolioDashboard;
