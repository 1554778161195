import React from 'react';
import { Navigate } from 'react-router-dom';

import { ACCESS_TOKEN } from '../api/login';

const ProtectedRoute = ({ children }) => {
  const access_token = sessionStorage.getItem(ACCESS_TOKEN);

  if (!access_token) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
