import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '../api/fetchers';
import LandingPageToast from '../components/ui-modals/LandingPageToast';
import { FooterWithLinks } from '../labels/footers';
import { MainHeader } from '../labels/headers';
import { landingLabels } from '../labels/landing_labels';

const LandingPage = () => {
  const navigate = useNavigate();
  const emailInputRef = useRef(null);
  const [consent, setConsent] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    const handleKeyPress = event => {
      if (document.activeElement !== emailInputRef.current) {
        if (event.key === 'l' || event.key === 'L') {
          navigate('/login');
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [navigate]);

  const handleSubscribe = async () => {
    const email = emailInputRef.current.value;

    if (!consent) {
      setToast({
        open: true,
        message: landingLabels.toastMessages.consentRequired,
        severity: 'error',
      });
      return;
    }

    try {
      await api.subscribeEmail(email);
      setToast({
        open: true,
        message: landingLabels.toastMessages.subscribeSuccess,
        severity: 'success',
      });
      emailInputRef.current.value = '';
      setConsent(false);
    } catch (error) {
      setToast({
        open: true,
        message: `${landingLabels.toastMessages.subscribeError}${error.message}`,
        severity: 'error',
      });
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubscribe();
    }
  };

  const handleInputFocus = () => {
    setShowConsent(true);
  };

  const handleCloseToast = () => {
    setToast({ ...toast, open: false });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white relative overflow-hidden flex flex-col">
      {/* Background gradient */}
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-indigo-500 via-indigo-500 to-teal-300 z-0"></div>
      </div>

      <MainHeader tenantIcon="/tandem-icon.png" />

      {/* Main Content */}
      <main className="relative z-10 flex flex-col items-start justify-center flex-grow text-left px-4 ml-16">
        {/* Large semi-transparent logo */}
        <div className="absolute bottom-[10px] right-[100px] z-0">
          <img
            src="/tandem-icon.png"
            alt="Large Tandem Logo"
            className="w-72 h-72 opacity-10"
          />
        </div>

        {/* Title and content */}
        <div className="relative z-10">
          <h1 className="text-5xl font-extrabold mb-4 text-gray-100">
            <span className="text-yellow-400">
              {landingLabels.mainHeading.part1}
            </span>
          </h1>
          <h1 className="text-4xl font-extrabold mb-4 text-gray-100">
            {landingLabels.mainHeading.part2}
          </h1>
          <p className="text-xl mb-8 max-w-2xl">{landingLabels.subHeading}</p>

          {/* Email Signup Section */}
          <div className="w-full max-w-md bg-gradient-to-r from-indigo-500 to-teal-500 p-1 rounded-lg shadow-lg">
            <div className="flex flex-col bg-gray-900 rounded-md p-2">
              <div className="flex items-center">
                <input
                  ref={emailInputRef}
                  type="email"
                  placeholder={landingLabels.emailPlaceholder}
                  className="flex-grow px-4 py-3 text-gray-900 bg-gray-100 rounded-l-md outline-none"
                  onKeyPress={handleKeyPress}
                  onFocus={handleInputFocus}
                />
                <button
                  className="bg-gradient-to-r from-indigo-500 to-teal-500 text-white px-6 py-3 rounded-r-md hover:from-indigo-600 hover:to-teal-600 transition-all duration-300"
                  onClick={handleSubscribe}
                  disabled={
                    !emailInputRef.current?.value || (showConsent && !consent)
                  }
                >
                  {landingLabels.notifyButton}
                </button>
              </div>
              {showConsent && (
                <div className="flex items-start mt-4">
                  <input
                    type="checkbox"
                    id="consentCheckbox"
                    className="mr-2 mt-1"
                    checked={consent}
                    onChange={e => setConsent(e.target.checked)}
                  />
                  <label
                    htmlFor="consentCheckbox"
                    className="text-sm text-gray-400"
                  >
                    {landingLabels.consentMessage}
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      {/* Bottom-right Image */}
      <img
        src="/landing-dash-img.png"
        alt={landingLabels.imageAlt}
        className="absolute top-1/2 right-[-30%] w-[120%] h-auto opacity-90 z-0 drop-shadow-lg transform -translate-y-1/2"
      />

      <FooterWithLinks />

      <LandingPageToast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={handleCloseToast}
      />
    </div>
  );
};

export default LandingPage;
