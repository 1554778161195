import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  fetchAllKPIs,
  fetchCurrentUserProfile,
  fetchProjectCompanyStakeholderData,
  fetchProjectDetails,
  fetchUserAccounts,
  fetchUserProjects,
} from '../api/fetchers';
import KeyMetricsAggregateButtonGroup from '../components/buttons/KeyMetricsAggregateButtonGroup';
import InvestmentStakeholdersChart from '../components/charts/InvestmentStakeholdersChart';
import ProjectCashFlowsChart from '../components/charts/ProjectCashFlowsChart';
import ProjectPerformanceChart from '../components/charts/ProjectPerformanceChart';
import DashboardLayout from '../components/layouts/DashboardLayout';
import OrgChartComponent from '../components/orgchart/OrgChart';
import DataTablePage from '../components/tables/DataTablePage';
import KpiDropdownTiny from '../components/ui-elements/KpiDropdownTiny';
import ProjectInfoBadge from '../components/ui-elements/ProjectInfoBadge';
import ProjectMetricCards from '../components/ui-elements/ProjectMetricCards';
import ProjectSelector from '../components/ui-elements/ProjectSelector';
import UserCompaniesBadge from '../components/ui-elements/UserCompaniesBadge';
import { useAdminMode } from '../contexts/AdminModeContext';
import useTheme from '../hooks/useTheme';
import {
  KPI_SLUGS,
  defaultSelectedKPIs,
  project_types,
} from '../shared/constants';

const INCOME_PRD = project_types[1]; // 'Income Producing'
const CONSTRUCTION = project_types[0]; // 'Construction Land'

const DEFAULT_AGGREGATIONS = {
  [INCOME_PRD]: 'annual',
  [CONSTRUCTION]: 'annual',
};

const ProjectDashboard = ({ userId }) => {
  const [selectedUser, setSelectedUser] = useState({});
  const { isAdmin } = useAdminMode();
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [projects, setProjects] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [kpiDescriptors, setKpiDescriptors] = useState({
    [INCOME_PRD]: [],
    [CONSTRUCTION]: [],
  });
  const [selectedProjectDetails, setSelectedProjectDetails] = useState({
    project_type: INCOME_PRD,
  });

  const [projectId, setProjectId] = useState(null);
  const [selectedKpis, setSelectedKpiSlugs] = useState(['', '', '', '']);
  const [aggregate, setAggregate] = useState('annual');
  const [activeTab, setActiveTab] = useState(0);

  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const [stakeholderData, setStakeholderData] = useState([]);
  const [selectedCashFlowKPIs, setSelectedCashFlowKPIs] = useState([]);

  const loadKPIDescriptors = async () => {
    const allKPIs = await fetchAllKPIs();
    setKpiDescriptors({
      [INCOME_PRD]: kpisFilteredForIncomeProducing(allKPIs),
      [CONSTRUCTION]: kpisFilteredForConstruction(allKPIs),
    });
  };

  const kpisFilteredForIncomeProducing = allKPIs => {
    return allKPIs.filter(
      kpi => Object.values(KPI_SLUGS).indexOf(kpi.slug) >= 7,
    );
  };

  const kpisFilteredForConstruction = allKPIs => {
    return allKPIs.filter(
      kpi => Object.values(KPI_SLUGS).indexOf(kpi.slug) < 7,
    );
  };

  const setDefaultKpisAndAggregation = project => {
    const { project_type } = project;
    setSelectedKpiSlugs(defaultSelectedKPIs[project_type]);
    setAggregate(DEFAULT_AGGREGATIONS[project_type]);

    // Set default cash flow KPIs based on project type
    const defaultCashFlowKPIs = {
      [INCOME_PRD]: ['AM_NOI', 'AM_CPX', 'AM_FMV'],
      [CONSTRUCTION]: ['PR_TOTREV', 'PR_TOTCST', 'PR_TOTPRF'],
    };
    setSelectedCashFlowKPIs(defaultCashFlowKPIs[project_type] || []);
  };

  const initializeDefaults = projects => {
    if (projects.length > 0) {
      const firstProject = projects[0];
      setProjectId(firstProject.id);
      setSelectedProjectDetails(firstProject);
      setDefaultKpisAndAggregation(firstProject);
    }
  };

  const fetchCurrentUserAndData = async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);
      const userProjects = await fetchUserProjects(currentUserProfile.id);
      setProjects(userProjects);
      const userAccounts = await fetchUserAccounts(currentUserProfile.id);
      setAccounts(userAccounts);

      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount ? defaultAccount : '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleUserChange = async user => {
    setSelectedUser(user);
    try {
      const userProjects = await fetchUserProjects(user.id);
      setProjects(userProjects);
      initializeDefaults(userProjects);

      const userAccounts = (await fetchUserAccounts(user.id)) || [];
      setAccounts(userAccounts);

      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount ? defaultAccount : '');

      const kpiList = await fetchAllKPIs();
      setKpiDescriptors({
        [INCOME_PRD]: kpisFilteredForIncomeProducing(kpiList),
        [CONSTRUCTION]: kpisFilteredForConstruction(kpiList),
      });
    } catch (error) {
      console.error('Error fetching user projects:', error);
    }
  };

  const handleKpiChange = (index, value) => {
    const currentKpis = [...selectedKpis];
    currentKpis[index] = value;
    setSelectedKpiSlugs(currentKpis);
  };

  const handleProjectSelection = async projectId => {
    setProjectId(projectId);

    try {
      const projectData = await fetchProjectDetails(projectId);
      setProjectDetails(projectData);
      setSelectedProjectDetails(projectData);
      setDefaultKpisAndAggregation(projectData);
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  };

  const handleAccountSelection = async accountId => {
    const userProjects = await fetchUserProjects(selectedUser.id, accountId);
    setProjects(userProjects);
    initializeDefaults(userProjects);
  };

  useEffect(() => {
    loadKPIDescriptors();
    fetchCurrentUserAndData();
  }, []);

  useEffect(() => {
    if (projectId) {
      fetchProjectCompanyStakeholderData(projectId).then(data => {
        setStakeholderData(data);
      });
    }
  }, [projectId]);

  const kpiSelectors = () => {
    return (
      <Grid container spacing={2} padding={1}>
        {selectedKpis.map((selectedKpi, index) => (
          <Grid item xs={3} key={`kpi-selector-${index}`}>
            <KpiDropdownTiny
              selectorIndex={index}
              kpiNames={kpiDescriptors[selectedProjectDetails.project_type]}
              onKpiSelect={handleKpiChange}
              selectedKpiSlug={selectedKpi}
              label="Select Key Metrics"
              backgroundColor="rgba(0, 250, 0, 0.04)"
              theme={currentTheme}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <DashboardLayout
      title="Project Dashboard"
      onUserChange={handleUserChange}
      selectedUser={selectedUser}
      selectedAccount={selectedAccount}
    >
      <Box mb={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="project dashboard tabs"
              >
                <Tab label="Overview" />
                <Tab label="Project Data" />
                <Tab label="Stakeholders" />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-start"
              mt={-1}
            >
              <ProjectSelector
                userId={selectedUser?.id}
                onProjectSelect={handleProjectSelection}
                selectedProjectId={projectId}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {activeTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <ProjectMetricCards projectDetails={projectDetails} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper elevation={3} className="p-4">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h5">Project Performance</Typography>
                <KeyMetricsAggregateButtonGroup
                  aggregate={aggregate}
                  setAggregate={setAggregate}
                />
              </Box>
              {isAdmin && kpiSelectors()}
              {projectId && selectedKpis.every(kpi => kpi) && (
                <ProjectPerformanceChart
                  key={projectId}
                  projectId={projectId}
                  selectedKpis={selectedKpis}
                  aggregation={aggregate}
                />
              )}
            </Paper>
            <Paper elevation={3} className="p-4" sx={{ marginTop: '1rem' }}>
              <ProjectCashFlowsChart
                projectId={projectId}
                aggregation={aggregate}
                selectedKpis={selectedCashFlowKPIs}
                projectType={selectedProjectDetails.project_type}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper elevation={5} className="p-4">
              <Box mb={2}>
                <ProjectInfoBadge projectDetails={projectDetails} />
              </Box>
              <Box mb={0.5}>
                <UserCompaniesBadge
                  userId={selectedUser?.id}
                  projectId={projectId}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
      {activeTab === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Project Data
            </Typography>
            <DataTablePage
              userId={selectedUser?.id}
              projectId={projectId}
              kpiSlug={selectedKpis[0]}
              aggregation={aggregate}
              title=""
            />
          </Grid>
        </Grid>
      )}
      {activeTab === 2 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InvestmentStakeholdersChart data={stakeholderData} />
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} className="p-4">
              <Typography variant="h6" gutterBottom>
                Project Organization Chart
              </Typography>
              <OrgChartComponent projectId={projectId} />
            </Paper>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default ProjectDashboard;
