import { Box, Tooltip } from '@mui/material';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import React, { useEffect, useMemo, useState } from 'react';

import { fetchUserPortfolioHierarchy } from '../../api/fetchers';
import dataGridStyles from '../../styles/dataGridStyles';

const formatCurrency = value => {
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const formatPercentage = value => {
  return new Intl.NumberFormat('en-CA', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const PortfolioHierarchyGrid = ({ userId }) => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserPortfolioHierarchy(userId);
        setPortfolioData(data);
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const processedRows = useMemo(() => {
    return portfolioData.flatMap(company =>
      company.projects.map((project, projectIndex) => {
        const equityContribution =
          project.kpis.find(kpi => kpi.slug === 'PR_EQTCNT')?.value || 0;
        const capitalReturns =
          (project.kpis.find(kpi => kpi.slug === 'PR_EQTDST')?.value || 0) +
          (project.kpis.find(kpi => kpi.slug === 'PR_PFTDST')?.value || 0) +
          (project.kpis.find(kpi => kpi.slug === 'PR_INTDST')?.value || 0);

        return {
          id: `${company.id}-${projectIndex}`,
          companyName: company.name,
          projectName: project.name,
          stakePercentage: project.stake_percentage,
          equityContribution: equityContribution,
          capitalReturns: capitalReturns,
        };
      }),
    );
  }, [portfolioData]);

  const columns = [
    {
      field: 'stakePercentage',
      headerName: 'Stake %',
      width: 100,
      renderCell: params =>
        params.row.projectName ? (
          <span>{formatPercentage(params.value)}</span>
        ) : (
          ''
        ),
    },
    {
      field: 'equityContribution',
      headerName: 'Equity Contribution',
      width: 150,
      renderCell: params =>
        params.row.projectName ? (
          <Tooltip title="Cumulative Equity Contribution">
            <span>{formatCurrency(params.value)}</span>
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      field: 'capitalReturns',
      headerName: 'Projected Returns',
      width: 150,
      renderCell: params =>
        params.row.projectName ? (
          <Tooltip title="Cumulative Projected Capital Returns">
            <span>{formatCurrency(params.value)}</span>
          </Tooltip>
        ) : (
          ''
        ),
    },
  ];

  return (
    <Box
      sx={{
        height: 600,
        width: '100%',
        '& .MuiDataGrid-root': {
          border: 'none',
        },
        ...dataGridStyles,
      }}
    >
      <DataGridPremium
        rows={processedRows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        treeData
        getTreeDataPath={row => [row.companyName, row.projectName]}
        groupingColDef={{
          headerName: 'Portfolio Companies',
          width: 400,
          pinned: 'left',
        }}
        defaultGroupingExpansionDepth={-1}
      />
    </Box>
  );
};

export default PortfolioHierarchyGrid;
