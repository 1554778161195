import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { fetchUserCompanyStakes } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const PortfolioUserCompaniesBadge = ({ userId, title }) => {
  const [companyStakes, setCompanyStakes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserCompanyStakes(userId);
        const transformedData = transformData(data);
        setCompanyStakes(transformedData);
      } catch (error) {
        console.error('Error fetching company stakes across Portfolio:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const transformData = data => {
    const companyMap = new Map();

    data.forEach(item => {
      if (!companyMap.has(item.company_id)) {
        companyMap.set(item.company_id, {
          id: item.company_id,
          company_name: item.company_name,
          projects: [],
        });
      }
      const company = companyMap.get(item.company_id);
      company.projects.push({
        id: item.project_id,
        project_name: item.project_name,
        type: item.type,
        nominal_ownership_pct: item.nominal_ownership_pct,
        actual_equity_dist_pct: item.actual_equity_dist_pct,
        actual_interest_dist_pct: item.actual_interest_dist_pct,
      });
    });

    return Array.from(companyMap.values()).sort(
      (a, b) => b.projects.length - a.projects.length,
    ); // Sort by number of projects descending
  };

  const formatPercentage = value => {
    return value ? `${(value * 100).toFixed(2)}%` : 'N/A';
  };

  return (
    <Card
      sx={{
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        backgroundColor: 'var(--card)',
        color: 'var(--foreground)',
        marginTop: '1rem',
      }}
    >
      <CardContent>
        <Typography variant="h6" fontWeight="bold" component="div" gutterBottom>
          {title || 'Portfolio Company Stakes'}
        </Typography>
        {loading ? (
          <Typography variant="body2">Loading...</Typography>
        ) : companyStakes.length === 0 ? (
          <Typography variant="body2">No company stakes found.</Typography>
        ) : (
          companyStakes.map(company => (
            <Accordion
              key={company.id}
              sx={{
                backgroundColor: 'var(--card)',
                color: 'var(--foreground)',
                boxShadow: 'none',
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${company.id}-content`}
                id={`panel${company.id}-header`}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {company.company_name}
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{ marginLeft: 1, color: 'text.secondary' }}
                  >
                    ({company.projects.length})
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {company.projects.map(project => (
                  <Box
                    key={project.id}
                    sx={{
                      marginBottom: 2,
                      padding: 1,
                      borderRadius: '4px',
                      backgroundColor: 'var(--background)',
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      {project.project_name}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body2">Investment Type:</Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'var(--accent-color)' }}
                      >
                        {project.type}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body2">
                        Nominal Ownership:
                      </Typography>
                      <Typography variant="body2">
                        {formatPercentage(
                          project.nominal_ownership_pct / 10000,
                        )}
                      </Typography>
                    </Box>
                    {project.type === 'GP' && (
                      <>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="body2">
                            Equity Distribution:
                          </Typography>
                          <Typography variant="body2">
                            {formatPercentage(
                              project.actual_equity_dist_pct / 10000,
                            )}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="body2">
                            Interest Distribution:
                          </Typography>
                          <Typography variant="body2">
                            {formatPercentage(
                              project.actual_interest_dist_pct / 10000,
                            )}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </CardContent>
    </Card>
  );
};

export default PortfolioUserCompaniesBadge;
