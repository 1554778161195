import { Home } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { app_paths } from '../App';
import DashboardLayout from '../components/layouts/DashboardLayout';

const AccountBilling = () => {
  const [selectedSection, setSelectedSection] = useState('Overview');

  const renderContent = () => {
    switch (selectedSection) {
      case 'Overview':
        return <Typography>Billing overview</Typography>;
      case 'Payment methods':
        return <Typography>Payment methods management</Typography>;
      case 'Billing history':
        return <Typography>Billing history table</Typography>;
      case 'Plans':
        return (
          <Typography>Available plans and current plan details</Typography>
        );
      default:
        return null;
    }
  };

  return (
    <DashboardLayout title="Billing Settings">
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '24px' }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={9}>
            <Typography variant="h4" gutterBottom>
              Billing settings
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                component={Link}
                to={app_paths.HOME_PATH}
                startIcon={<Home />}
                color="inherit"
                size="small"
              >
                Back to Home
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <List component="nav" sx={{ borderRight: '1px solid #e0e0e0' }}>
              {['Overview', 'Payment methods', 'Billing history', 'Plans'].map(
                text => (
                  <ListItem
                    button
                    key={text}
                    selected={selectedSection === text}
                    onClick={() =>
                      text === 'Overview' && setSelectedSection(text)
                    }
                    disabled={text !== 'Overview'}
                    sx={{
                      borderLeft:
                        selectedSection === text ? '2px solid #1976d2' : 'none',
                      backgroundColor:
                        selectedSection === text
                          ? 'rgba(25, 118, 210, 0.08)'
                          : 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.04)',
                      },
                    }}
                  >
                    <ListItemText
                      primary={text}
                      primaryTypographyProps={{
                        color:
                          selectedSection === text ? 'primary' : 'textPrimary',
                      }}
                    />
                  </ListItem>
                ),
              )}
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <Paper elevation={3} sx={{ padding: '24px' }}>
              {renderContent()}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default AccountBilling;
