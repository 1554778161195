import { BarChart } from '@mui/x-charts/BarChart';
import React from 'react';

const InvestmentPerformanceChart = ({ data }) => {
  const chartData = data.equityContributed.yearly.map((item, index) => ({
    year: item.year,
    equityContributed: Number(item.value),
    capitalReturns: Number(data.capitalReturns.yearly[index]?.value || 0),
  }));

  return (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
      <h2 className="text-lg font-semibold mb-4">Investment Performance</h2>
      <div className="ml-2">
        <BarChart
          xAxis={[
            { data: chartData.map(item => item.year), scaleType: 'band' },
          ]}
          series={[
            {
              data: chartData.map(item => item.equityContributed),
              label: 'Equity Contributed',
              valueFormatter: value => Math.round(value).toLocaleString(),
            },
            {
              data: chartData.map(item => item.capitalReturns),
              label: 'Capital Returns',
              valueFormatter: value => Math.round(value).toLocaleString(),
            },
          ]}
          width={500}
          height={300}
          sx={{ padding: '10px' }}
        />
      </div>
    </div>
  );
};

export default InvestmentPerformanceChart;
