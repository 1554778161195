import {
  Brightness4,
  Brightness7,
  Send as FeedbackIcon,
  Launch as LinkIcon,
} from '@mui/icons-material';
import { Box, Divider, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import AboutMenuItem from './AboutModal';

const UserSupportMenu = ({
  anchorEl,
  open,
  onClose,
  isDarkMode,
  toggleTheme,
  iconColor,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: 200,
        },
      }}
    >
      <MenuItem disabled>
        <Box>
          <Typography variant="body1">Support</Typography>
        </Box>
      </MenuItem>
      <Divider sx={{ my: 1 }} />
      <MenuItem component={Link} to="/support" onClick={onClose}>
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Feedback
        </Typography>
        <FeedbackIcon fontSize="small" sx={{ color: iconColor }} />
      </MenuItem>
      <AboutMenuItem />
      <MenuItem
        component="a"
        href="http://www.tandemgroup.com/investorarea/index.php?page=login"
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClose}
      >
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Investor Portal
        </Typography>
        <LinkIcon fontSize="small" sx={{ color: iconColor }} />
      </MenuItem>
      <Divider sx={{ my: 1 }} />
      <MenuItem
        onClick={() => {
          toggleTheme();
          onClose();
        }}
      >
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </Typography>
        {isDarkMode ? (
          <Brightness7 fontSize="small" sx={{ color: iconColor }} />
        ) : (
          <Brightness4 fontSize="small" sx={{ color: iconColor }} />
        )}
      </MenuItem>
    </Menu>
  );
};

export default UserSupportMenu;
