import { TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';

const OrgFormInput = React.memo(
  ({ name, value, onChange, error, helperText, isPercentage, ...props }) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    const debouncedOnChange = useCallback(
      debounce(newValue => {
        onChange({ target: { name, value: newValue } });
      }, 50),
      [onChange, name],
    );

    const handleChange = e => {
      let newValue = e.target.value;

      if (isPercentage) {
        // Allow up to 5 decimal places for percentage inputs
        newValue = newValue.replace(/[^0-9.]/g, '');
        const parts = newValue.split('.');
        if (parts[1] && parts[1].length > 5) {
          parts[1] = parts[1].slice(0, 5);
          newValue = parts.join('.');
        }
      }

      setLocalValue(newValue);
      debouncedOnChange(newValue);
    };

    return (
      <TextField
        {...props}
        name={name}
        value={localValue}
        onChange={handleChange}
        error={!!error}
        helperText={helperText}
        inputProps={{
          ...props.inputProps,
          step: isPercentage ? '0.00001' : undefined,
        }}
      />
    );
  },
);

OrgFormInput.displayName = 'OrgFormInput';

export default OrgFormInput;
