import {
  AccountBalanceWalletTwoTone,
  BarChart,
  Business,
  Description as FileText,
  FoundationTwoTone,
  PieChart,
  Receipt,
  SavingsTwoTone,
  Settings,
  SsidChartOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { app_paths } from '../App';
import {
  fetchAggregatedKPIs,
  fetchCurrentUserProfile,
  fetchUserAccounts,
  fetchUserCompanies,
  fetchUserProjects,
} from '../api/fetchers';
import AuthStatus from '../components/auth/AuthStatus';
import DashboardLayout from '../components/layouts/DashboardLayout';
import { useAdminMode } from '../contexts/AdminModeContext';

const FeatureCard = ({ title, description, icon: Icon, linkTo }) => (
  <Paper
    elevation={3}
    className="p-6 flex flex-col items-center text-center h-full"
  >
    <Icon className="text-blue-500 mb-4" style={{ fontSize: 48 }} />
    <Typography variant="h6" className="mb-2">
      {title}
    </Typography>
    <Typography variant="body2" color="textSecondary" className="mb-4">
      {description}
    </Typography>
    <Link
      to={linkTo}
      className="text-blue-500 font-medium hover:underline mt-auto"
    >
      Explore {title}
    </Link>
  </Paper>
);

const StatCard = ({ title, value, icon: Icon, bgColor }) => (
  <Paper elevation={3} className={`p-4 ${bgColor}`}>
    <Box display="flex" alignItems="center">
      <Icon className="text-blue-500 mr-3" style={{ fontSize: 36 }} />
      <Box>
        <Typography variant="subtitle2" className="font-medium">
          {title}
        </Typography>
        <Typography variant="h5" className="font-bold">
          {typeof value === 'number' &&
          title !== 'Companies' &&
          title !== 'Projects'
            ? `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`
            : value}
        </Typography>
      </Box>
    </Box>
  </Paper>
);

const HomePage = () => {
  const { adminModeActive, checkAdminStatus } = useAdminMode();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [totalEquityContributions, setTotalEquityContributions] = useState(0);
  const [capitalReturnsToDate, setCapitalReturnsToDate] = useState(0);
  const [projectedReturns, setProjectedReturns] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchUserData = useCallback(async userId => {
    try {
      const [userAccounts, userCompanies, userProjects, aggregatedKPIs] =
        await Promise.all([
          fetchUserAccounts(userId),
          fetchUserCompanies(userId),
          fetchUserProjects(userId),
          fetchAggregatedKPIs(
            userId,
            ['PR_EQTCNT', 'PR_EQTDST', 'PR_PFTDST', 'PR_INTDST'],
            'annual',
          ),
        ]);

      setAccounts(userAccounts);
      setCompanies(userCompanies);
      setProjects(userProjects);

      // Calculate total equity contributions
      const totalEquityContributed =
        Number(
          aggregatedKPIs.find(kpi => kpi.kpi_slug === 'PR_EQTCNT')?.total_value,
        ) || 0;
      setTotalEquityContributions(totalEquityContributed);

      // Aggregate capital returns
      const equityDistributions =
        aggregatedKPIs.find(kpi => kpi.kpi_slug === 'PR_EQTDST')?.data || [];
      const profitDistributions =
        aggregatedKPIs.find(kpi => kpi.kpi_slug === 'PR_PFTDST')?.data || [];
      const interestDistributions =
        aggregatedKPIs.find(kpi => kpi.kpi_slug === 'PR_INTDST')?.data || [];

      const allDistributions = [
        ...equityDistributions,
        ...profitDistributions,
        ...interestDistributions,
      ];

      // Get current date
      const currentDate = new Date();

      // Split data into returns to date and projected returns
      let returnsToDate = 0;
      let projectedRet = 0;

      allDistributions.forEach(distribution => {
        const distributionDate = new Date(distribution.date);
        const amountValue = parseFloat(distribution.amount_value) || 0;

        if (distributionDate <= currentDate) {
          returnsToDate += amountValue;
        } else {
          projectedRet += amountValue;
        }
      });

      setCapitalReturnsToDate(returnsToDate);
      setProjectedReturns(projectedRet);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const currentUserProfile = await fetchCurrentUserProfile();
        setSelectedUser(currentUserProfile);
        await fetchUserData(currentUserProfile.id);
        await checkAdminStatus();

        const userAccounts = await fetchUserAccounts(currentUserProfile.id);
        setAccounts(userAccounts);

        const defaultAccount =
          userAccounts.find(
            acc => acc.name === `${currentUserProfile.username} Account`,
          ) || userAccounts[0];
        setSelectedAccount(defaultAccount || null);
      } catch (error) {
        console.error('Error initializing data:', error);
        setLoading(false);
      }
    };

    initializeData();
  }, [fetchUserData, checkAdminStatus]);

  const handleUserChange = useCallback(
    async user => {
      setSelectedUser(user);
      setLoading(true);
      await fetchUserData(user.id);

      try {
        const userAccounts = await fetchUserAccounts(user.id);
        setAccounts(userAccounts);

        const defaultAccount =
          userAccounts.find(acc => acc.name === `${user.username} Account`) ||
          userAccounts[0];
        setSelectedAccount(defaultAccount || null);
      } catch (error) {
        console.error('Error fetching user accounts:', error);
      } finally {
        setLoading(false);
      }
    },
    [fetchUserData],
  );

  if (loading) {
    return (
      <DashboardLayout title="Home">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      title="Home"
      selectedUser={selectedUser}
      selectedAccount={selectedAccount}
      onUserChange={handleUserChange}
    >
      <Box className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={7}>
            <Typography variant="h4" className="mb-6">
              Welcome, {selectedUser?.username}
            </Typography>
            <Typography variant="h6" style={{ color: 'grey', textIndent: 10 }}>
              {selectedAccount?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                component={Link}
                to={app_paths.ACCOUNT_SETTINGS_PATH}
                startIcon={<Settings />}
                color="inherit"
                size="small"
                sx={{ mr: 2 }}
              >
                Account Settings
              </Button>
              <Button
                component={Link}
                to={app_paths.ACCOUNT_BILLING_PATH}
                startIcon={<Receipt />}
                color="inherit"
                size="small"
              >
                Billing Settings
              </Button>
              <Box ml={2}>
                <AuthStatus />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body1" className="mb-8" marginTop={2}>
          Manage your investments, track performance, and generate insightful
          reports. Choose a section below to get started.
        </Typography>
        <Grid container spacing={4} marginTop={2}>
          <Grid item xs={12} md={4}>
            <FeatureCard
              title="Portfolio Dashboard"
              description="Get a comprehensive overview of your investment portfolio. Track performance and export aggregated portfolio metrics for analysis."
              icon={PieChart}
              linkTo={app_paths.PORTFOLIO_DASHBOARD_PATH}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureCard
              title="Project Dashboard"
              description="Explore project details and investment performance metrics. Filter by project to get a detailed view of each investment."
              icon={BarChart}
              linkTo={app_paths.PROJECTS_DASHBOARD_PATH}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FeatureCard
              title="Reports"
              description="Generate custom reports for analysis and distribution. Filter metrics and companies to share insights with stakeholders."
              icon={FileText}
              linkTo={app_paths.REPORTS_PATH}
            />
          </Grid>
        </Grid>
        <Paper elevation={3} className="mt-12 p-6">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                title="Total Equity Contributions"
                value={totalEquityContributions}
                icon={SavingsTwoTone}
                bgColor="bg-blue-50"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <StatCard
                title="Returns to Date"
                value={capitalReturnsToDate}
                icon={AccountBalanceWalletTwoTone}
                bgColor="bg-green-50"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <StatCard
                title="Projected Returns"
                value={projectedReturns}
                icon={SsidChartOutlined}
                bgColor="bg-purple-50"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <StatCard
                title="Companies"
                value={companies.length}
                icon={Business}
                bgColor="bg-yellow-50"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <StatCard
                title="Projects"
                value={projects.length}
                icon={FoundationTwoTone}
                bgColor="bg-orange-50"
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </DashboardLayout>
  );
};

export default HomePage;
