import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

const PortfolioMetricCard = ({ title, value, change, breakdown }) => {
  const isPositiveChange = change >= 0;

  const renderBreakdownItem = (item, index) => (
    <Box key={index} display="flex" justifyContent="space-between" mt={1}>
      <Typography variant="body2" color="text.secondary">
        {item.label}
      </Typography>
      <Typography variant="body2" fontWeight="medium" sx={{ pr: 2 }}>
        {typeof item.value === 'number' ? Math.round(item.value) : item.value}
      </Typography>
    </Box>
  );

  return (
    <Card
      elevation={2}
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <CardContent sx={{ flexGrow: 1, p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>
        <Box display="flex" alignItems="baseline" mb={1}>
          <Typography variant="h4" component="div" fontWeight="bold">
            {typeof value === 'number' ? Math.round(value) : value}
          </Typography>
          {change !== undefined && (
            <Box display="flex" alignItems="center" ml={1}>
              {isPositiveChange ? (
                <TrendingUpIcon color="success" fontSize="small" />
              ) : (
                <TrendingDownIcon color="error" fontSize="small" />
              )}
              <Typography
                variant="body2"
                color={isPositiveChange ? 'success.main' : 'error.main'}
                ml={0.5}
              >
                {Math.round(Math.abs(change))}%
              </Typography>
            </Box>
          )}
        </Box>
        {breakdown && (
          <Box
            sx={{
              mt: 1,
              maxHeight: '100px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '2px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            {breakdown.map((item, index) => renderBreakdownItem(item, index))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default PortfolioMetricCard;
