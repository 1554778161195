export const login_labels = {
  LOGIN_TITLE: 'Real Assets Portal Login',
  EMAIL_LABEL: 'Email',
  EMAIL_PLACEHOLDER: 'email',
  PASSWORD_LABEL: 'Password',
  PASSWORD_PLACEHOLDER: 'password',
  LOGIN_BUTTON: 'Login',
  NEW_ACCOUNT_QUESTION: "Don't have an account?",
  SIGNUP_BUTTON: 'Sign up for early access',
  INVALID_CREDENTIALS_MSG: 'Invalid credentials',
  FORGOT_PASSWORD: 'Forgot Password?',
  RESET_PASSWORD: 'Reset Password',
  NEW_PASSWORD_LABEL: 'New Password',
  CONFIRM_PASSWORD_LABEL: 'Confirm Password',
  RESET_PASSWORD_BUTTON: 'Reset Password',
  PASSWORD_RESET_EMAIL_SENT:
    'A password reset link will be sent if the account exists.',
  PASSWORD_RESET_ERROR: 'An error occurred. Please try again.',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match.',
  PASSWORD_RESET_SUCCESS:
    'Your password has been reset successfully. Please log in with your new password.',

  // First-time user password setup
  FIRST_TIME_USER_TITLE: 'Welcome to the Real Assets Portal!',
  FIRST_TIME_USER_MESSAGE:
    'An email has been sent to you with instructions to set up your password. Please check your inbox and click the link to complete your account setup.',
  FIRST_TIME_USER_CLOSE_BUTTON: 'Close',
  SETUP_PASSWORD: 'Set Up Your Password',
  SETUP_PASSWORD_BUTTON: 'Set Password',
  PASSWORD_SETUP_SUCCESS:
    'Your password has been set successfully. Please log in with your new password.',
  PASSWORD_SETUP_ERROR:
    'An error occurred while setting up your password. Please try again.',
};
