export const upload_labels = {
  UPLOAD_CSV_FILES: 'Upload CSV Files',
  DRAG_DROP_FILES: 'Drag & drop files here, or click to select files',
  SELECTED_FILES: 'Staged Files',
  PARSE_FILES: 'Parse Files',
  DATA_PREVIEW: 'Data Preview',
  SUMMARY: 'Summary',
  TOTAL_ENTRIES: 'Staged KPIs',
  NUMBER_OF_FILES: 'Staged Files',
  TOTAL_SUM: 'Total Sum',
  FILE_NAMES: 'File Names',
  KPI_SUMMARIES: 'KPI Summaries',
  KPI_SLUG: 'KPI Slug',
  KPI_NAME: 'KPI Name',
  PROJECT_ID: 'Project ID',
  SUM: 'Sum',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  COMMIT_TO_DATABASE: 'Commit to Database',
  CLEAR_UPLOADS: 'Clear Uploads',
  UPLOAD_SUCCESSFUL: 'Upload Successful',
  UPLOAD_FAILED: 'Upload Failed',
  WARNING_MESSAGE:
    'You already have parsed files. Uploading new files will clear the previous data. Do you want to proceed?',
  PROCEED: 'Proceed',
  CANCEL: 'Cancel',
};
