import React from 'react';
import { Link } from 'react-router-dom';

import { landingLabels } from './landing_labels';

export const MainHeader = ({ tenantIcon }) => {
  return (
    <header className="relative z-10 bg-transparent text-white py-4 px-8 flex justify-between items-center">
      <div className="flex items-center space-x-6 ml-16">
        {tenantIcon && (
          <img src={tenantIcon} alt="Header Icon" className="h-8 w-auto" />
        )}
        <Link
          to="/"
          className="text-xl font-semibold hover:text-gray-300 transition-colors"
        >
          {landingLabels.title}
        </Link>
        <Link
          to="/login"
          className="text-white px-4 py-2 rounded-md bg-gradient-to-r from-gray-800 to-gray-600 hover:from-teal-400 hover:to-teal-500 shadow-lg transition-all duration-300 flex items-center"
        >
          {landingLabels.betaLogin}
          <span className="ml-2 text-xs bg-gray-700 px-1 rounded border border-yellow-400 ">
            {landingLabels.betaLoginShortcut}
          </span>
        </Link>
      </div>
      <div className="flex space-x-4 mr-16"></div>
    </header>
  );
};
