export const OrgChartNode = (node, state) => {
  const { name, type, ownership_percentage, is_external } = node.data;
  const hasChildren = node._children || node.children;

  const backgroundColor = hasChildren ? '#e0e0e0' : '#f5f5f5'; // Darker grey for nodes with children

  return `
    <div class="org-node ${is_external ? 'external' : 'internal'} ${hasChildren ? 'has-children' : ''}" 
         style="background-color: ${backgroundColor};">
      <div class="node-name" style="height: 3rem; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">${name}</div>
      <div class="node-type">${type}</div>
      ${ownership_percentage !== null ? `<div class="node-ownership"><strong style="font-size: 24px;">${ownership_percentage}%</strong></div>` : ''}
      ${hasChildren ? `<div class="children-indicator">${node.children ? '-' : '+'}</div>` : ''}
    </div>
  `;
};
