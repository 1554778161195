export const OrgChartLink = d => {
  return `M${d.source.x},${d.source.y}
          L${d.source.x},${(d.source.y + d.target.y) / 2}
          L${d.target.x},${(d.source.y + d.target.y) / 2}
          L${d.target.x},${d.target.y}`;
};

export const CustomOrgChartLink = ({ source, target }) => {
  if (target.depth >= 2) {
    const midY = source.y + (target.y - source.y) / 2;
    return `
      M${source.x},${source.y}
      V${midY}
      H${target.x}
      V${target.y}
    `;
  }
  return `M${source.x},${source.y}
          V${(source.y + target.y) / 2}
          H${target.x}
          V${target.y}`;
};
