import { Alert, Fade } from '@mui/material';
import React, { useEffect } from 'react';

const LandingPageToast = ({ open, message, severity, onClose }) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [open, onClose]);

  return (
    <Fade in={open}>
      <div
        style={{
          position: 'absolute',
          left: '105px',
          top: 'calc(50% + 250px)',
          transform: 'translateY(-50%)',
          zIndex: 9999,
          width: '100%',
          maxWidth: '400px',
        }}
      >
        <Alert
          onClose={onClose}
          severity={severity}
          variant="filled"
          sx={{
            width: '100%',
            fontSize: '1rem',
            padding: '12px 20px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
          }}
        >
          {message}
        </Alert>
      </div>
    </Fade>
  );
};

export default LandingPageToast;
