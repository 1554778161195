import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { checkIsAdmin } from '../api/fetchers';

const AdminModeContext = createContext();

export const AdminModeProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminModeActive, setAdminModeActive] = useState(() => {
    return JSON.parse(localStorage.getItem('adminModeActive')) || false;
  });
  const [originalUser, setOriginalUser] = useState(() => {
    return JSON.parse(localStorage.getItem('originalUser')) || null;
  });

  const clearAdminMode = useCallback(() => {
    setIsAdmin(false);
    setAdminModeActive(false);
    setOriginalUser(null);
    localStorage.removeItem('adminModeActive');
    localStorage.removeItem('originalUser');
  }, []);

  useEffect(() => {
    const fetchAdminStatus = async () => {
      try {
        const adminStatus = await checkIsAdmin();
        setIsAdmin(adminStatus);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
        setAdminModeActive(false);
        localStorage.removeItem('adminModeActive');
        localStorage.removeItem('originalUser');
      }
    };

    fetchAdminStatus();
  }, []);

  const checkAdminStatus = useCallback(async () => {
    try {
      const adminStatus = await checkIsAdmin();
      setIsAdmin(adminStatus);
    } catch (error) {
      console.error('Error checking admin status:', error);
      setIsAdmin(false);
      setAdminModeActive(false);
      localStorage.removeItem('adminModeActive');
      localStorage.removeItem('originalUser');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('adminModeActive', JSON.stringify(adminModeActive));
    localStorage.setItem('originalUser', JSON.stringify(originalUser));
  }, [adminModeActive, originalUser]);

  const toggleAdminMode = currentUser => {
    if (isAdmin) {
      setAdminModeActive(prevState => {
        if (!prevState) {
          setOriginalUser(currentUser);
          return true;
        } else {
          setOriginalUser(null);
          return false;
        }
      });
    }
  };

  return (
    <AdminModeContext.Provider
      value={{
        isAdmin,
        adminModeActive,
        toggleAdminMode,
        originalUser,
        checkAdminStatus,
        clearAdminMode,
      }}
    >
      {children}
    </AdminModeContext.Provider>
  );
};

export const useAdminMode = () => useContext(AdminModeContext);
