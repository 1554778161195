import { DocumentPlusIcon } from '@heroicons/react/24/solid';
import React from 'react';

const UploadFileDropZone = ({
  handleDrop,
  handleDragOver,
  handleFileChange,
  upload_labels,
}) => (
  <div
    className="border-2 border-dashed border-gray-300 dark:border-gray-700 p-6 rounded-lg mb-6 text-center cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
    onDrop={handleDrop}
    onDragOver={handleDragOver}
  >
    <input
      type="file"
      accept=".csv"
      multiple
      onChange={handleFileChange}
      className="hidden"
      id="fileUpload"
    />
    <label htmlFor="fileUpload" className="cursor-pointer">
      <DocumentPlusIcon className="h-12 w-12 mx-auto text-gray-500 dark:text-gray-400 mb-2" />
      <p className="text-gray-500 dark:text-gray-400">
        {upload_labels.DRAG_DROP_FILES}
      </p>
    </label>
  </div>
);

export default UploadFileDropZone;
