import { Box, Card, CardContent, Typography } from '@mui/material';
import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

const formatCurrency = value => {
  if (value == null || isNaN(value) || value === 0) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const ReportPreview = ({ data, selectedCompanies, selectedKPIs }) => {
  const { portfolioRows, portfolioColumns, projectRows, projectColumns } =
    useMemo(() => {
      if (!data || !data.selected_kpis)
        return {
          portfolioRows: [],
          portfolioColumns: [],
          projectRows: [],
          projectColumns: [],
        };

      const years = [
        ...new Set(
          data.selected_kpis.flatMap(kpi =>
            kpi.data.map(item => new Date(item.date).getFullYear()),
          ),
        ),
      ].sort();

      const kpiColumn = {
        field: 'kpi',
        headerName: 'KPI',
        width: 400,
        flex: 1,
      };

      const yearColumns = years.map(year => ({
        field: year.toString(),
        headerName: year.toString(),
        width: 120,
        flex: 1,
        renderCell: params => formatCurrency(params.value),
      }));

      const totalColumn = {
        field: 'total',
        headerName: 'Total',
        width: 150,
        flex: 0,
        renderCell: params => formatCurrency(params.value),
      };

      const portfolioColumns = [kpiColumn, ...yearColumns, totalColumn];

      // Portfolio-level aggregated metrics
      const portfolioRows = selectedKPIs.map((kpiSlug, index) => {
        const kpiInfo = data.selected_kpis.find(
          kpi => kpi.kpi_slug === kpiSlug,
        );
        if (!kpiInfo) {
          return {
            id: `portfolio-${index}`,
            kpi: kpiSlug,
            ...Object.fromEntries(years.map(year => [year.toString(), 0])),
            total: 0,
          };
        }

        const row = {
          id: `portfolio-${index}`,
          kpi: kpiInfo.kpi_name,
        };

        let total = 0;
        years.forEach(year => {
          const yearValue = kpiInfo.data.reduce((sum, item) => {
            if (new Date(item.date).getFullYear() === year) {
              return sum + parseFloat(item.amount_value);
            }
            return sum;
          }, 0);
          row[year.toString()] = yearValue;
          total += yearValue;
        });
        row.total = total;

        return row;
      });

      // Project-specific metrics with grouping
      const projectRows = [];
      let id = 0;

      data.companies.forEach(company => {
        if (selectedCompanies.includes(company.id)) {
          company.projects.forEach(project => {
            selectedKPIs.forEach(kpiSlug => {
              const kpiInfo = data.selected_kpis.find(
                kpi => kpi.kpi_slug === kpiSlug,
              );
              const row = {
                id: id++,
                company: company.name,
                project: project.name,
                kpi: kpiInfo ? kpiInfo.kpi_name : kpiSlug,
              };

              let total = 0;
              years.forEach(year => {
                const yearValue = project.kpis
                  .filter(
                    kpi =>
                      kpi.slug === kpiSlug &&
                      new Date(kpi.date).getFullYear() === year,
                  )
                  .reduce((sum, kpi) => sum + parseFloat(kpi.value), 0);
                row[year.toString()] = yearValue;
                total += yearValue;
              });
              row.total = total;

              projectRows.push(row);
            });
          });
        }
      });

      const projectColumns = [...yearColumns, totalColumn];

      return {
        portfolioRows,
        portfolioColumns,
        projectRows,
        projectColumns,
      };
    }, [data, selectedCompanies, selectedKPIs]);

  if (!data || !data.selected_kpis) {
    return <Typography>No data available for preview</Typography>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Card sx={{ flex: '0 0 auto' }}>
        <CardContent sx={{ '&:last-child': { pb: 1 } }}>
          <Typography variant="h6" gutterBottom>
            Portfolio-Level Metrics
          </Typography>
          <Box sx={{ width: '100%' }}>
            <DataGridPremium
              rows={portfolioRows}
              columns={portfolioColumns}
              disableSelectionOnClick
              autoHeight
              hideFooter
              density="compact"
              initialState={{
                pinnedColumns: {
                  left: ['kpi'],
                  right: ['total'],
                },
              }}
            />
          </Box>
        </CardContent>
      </Card>

      <Card
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
          overflow: 'hidden',
        }}
      >
        <CardContent
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
            p: 0,
            overflow: 'hidden',
          }}
        >
          <Typography variant="h6" sx={{ p: 2, pb: 1 }}>
            Project-Specific Metrics
          </Typography>
          <Box sx={{ flex: 1, width: '100%', overflow: 'auto' }}>
            <DataGridPremium
              rows={projectRows}
              columns={projectColumns}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
              initialState={{
                pinnedColumns: {
                  left: ['company', 'project', 'kpi'],
                  right: ['total'],
                },
                sorting: {
                  sortModel: [{ field: 'company', sort: 'asc' }],
                },
              }}
              getTreeDataPath={row => [row.company, row.project, row.kpi]}
              groupingColDef={{
                headerName: 'Company / Project / KPI',
                width: 400,
              }}
              treeData
              density="compact"
              defaultGroupingExpansionDepth={-1}
              rowHeight={36}
              sx={{
                '& .MuiDataGrid-root': {
                  border: 'none',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'background.paper',
                  borderBottom: 1,
                  borderColor: 'divider',
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: 'none',
                },
                '& .MuiDataGrid-row': {
                  '&:nth-of-type(2n)': {
                    backgroundColor: 'action.hover',
                  },
                },
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReportPreview;
