import { Box } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React from 'react';

const UploadPreviewDataTable = ({ data }) => {
  const columns = Object.keys(data[0] || {}).map((key, index) => {
    const baseColumn = {
      field: key,
      headerName: key,
      flex: 0,
      width: index < 2 ? 150 : 100,
      sortable: false,
    };

    if (index >= 3) {
      baseColumn.renderCell = params => {
        if (typeof params.value === 'number') {
          return params.value.toLocaleString();
        }
        return params.value;
      };
    }

    return baseColumn;
  });

  return (
    <Box sx={{ height: 'auto', width: '100%' }}>
      <DataGridPremium
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        getRowId={row => row.id || Math.random().toString(36).substr(2, 9)}
        disableColumnMenu
        disableSelectionOnClick
        density="compact"
        initialState={{
          pinnedColumns: { left: columns.slice(0, 3).map(col => col.field) },
        }}
      />
    </Box>
  );
};

export default UploadPreviewDataTable;
