import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';

const PanelCollapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div className="mb-4 border rounded-lg overflow-hidden">
      <div
        onClick={togglePanel}
        className="flex justify-between items-center p-4 cursor-pointer bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600"
      >
        <h3 className="font-semibold">{title}</h3>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 text-gray-600 dark:text-gray-300" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-gray-600 dark:text-gray-300" />
        )}
      </div>
      {isOpen && (
        <div className="p-4 bg-white dark:bg-gray-800">{children}</div>
      )}
    </div>
  );
};

export default PanelCollapsible;
