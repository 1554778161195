import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const ProjectSummaryTable = ({ projects, title }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  return (
    <div className="mb-6">
      <Typography
        variant="h6"
        align="left"
        style={{
          color: isDarkMode ? 'var(--foreground)' : 'var(--foreground)',
        }}
      >
        {title}
      </Typography>
      <TableContainer
        component={Paper}
        style={{
          backgroundColor: isDarkMode ? 'var(--card)' : 'var(--card)',
          color: 'var(--foreground)',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          border: `1px solid ${isDarkMode ? 'var(--border-dark)' : 'var(--border-light)'}`,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: 'var(--foreground)' }}>
                Project
              </TableCell>
              <TableCell style={{ color: 'var(--foreground)' }}>
                Location
              </TableCell>
              <TableCell style={{ color: 'var(--foreground)' }}>Type</TableCell>
              <TableCell style={{ color: 'var(--foreground)' }}>
                Disclaimer
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map(project => (
              <TableRow key={project.id}>
                <TableCell style={{ color: 'var(--foreground)' }}>
                  {project.name}
                </TableCell>
                <TableCell style={{ color: 'var(--foreground)' }}>
                  {project.location}
                </TableCell>
                <TableCell style={{ color: 'var(--foreground)' }}>
                  {project.project_sub_type}
                </TableCell>
                <TableCell style={{ color: 'var(--foreground)' }}>
                  {project.disclaimer}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProjectSummaryTable;
