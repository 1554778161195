export const projectDashboardLabels = {
  TITLE: 'Project Dashboard',
  WELCOME_MESSAGE: 'Welcome, ',
  PROJECT_PERFORMANCE: 'Project Performance',
  PROJECT_DATA_TABLE: 'Project Data - Table',
  RESET_BUTTON_LABEL: 'Reset',
  DOWNLOAD_BUTTON_LABEL: 'Download',
  SELECT_COMPANIES_LABEL: 'Select Companies',
  SELECT_KEY_METRICS_LABEL: 'Select Key Metrics',
  ACCOUNT_DROPDOWN_LABEL: 'Account',
  PROJECT_DROPDOWN_LABEL: 'Project',
};

export const portfolioDashboardLabels = {
  TITLE: 'Portfolio Dashboard',
  WELCOME_MESSAGE: 'Welcome, ',
  TOTAL_EQUITY_CONTRIBUTION: 'Total Equity Contribution',
  TOTAL_CAPITAL_RETURNS: 'Total Capital Returns',
  COMPANIES_PROJECTS_SUMMARY: 'Companies and Projects Summary',
  PROJECT_STAKES: 'Project Stakes',
  PORTFOLIO_PROJECTS_SUMMARY: 'Portfolio Projects Summary',
  DISTRIBUTIONS: 'Distributions',
  UNIT_CLOSINGS_PROTOTYPE_CHART: 'Unit Closings Prototype Chart',
  QUARTERLY_DISTRIBUTIONS_AREA_STEP: 'Quarterly Distributions Area Step',
  SELECT_COMPANIES_LABEL: 'Select Companies',
};

export const projectsDataTableLabels = {
  VIEW_COMPANY_STAKE: 'View Company Stake ($)',
  TABLE_HEADER_DATE: 'Date',
  TABLE_HEADER_PROJECT_ID: 'Project ID',
  TABLE_HEADER_KPI_NAME: 'KPI Name',
  TABLE_HEADER_VALUE: 'Value',
};
