import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';

import { login_labels } from '../../labels/login_labels';

const FirstTimeUserModal = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{login_labels.FIRST_TIME_USER_TITLE}</DialogTitle>
      <DialogContent>
        <Typography>{login_labels.FIRST_TIME_USER_MESSAGE}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary" fullWidth>
          {login_labels.FIRST_TIME_USER_CLOSE_BUTTON}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FirstTimeUserModal;
