import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useState } from 'react';

import {
  createProject,
  deleteProject,
  fetchProjects,
  updateProject,
} from '../../api/fetchers';
import {
  construction_statuses,
  data_sources,
  project_sub_types,
  project_types,
} from '../../shared/constants';
import ConfirmationDialog from '../ui-modals/ConfirmationDialog';
import Toast from '../ui-modals/ToastMessage';

const DEFAULT_PROJECT_STATE = {
  name: '',
  description: '',
  location: '',
  start_date: '',
  end_date: '',
  construction_status: '',
  total_acres: '',
  number_of_units: '',
  project_type: '',
  project_sub_type: '',
  disclaimer: '',
  last_updated: '',
  data_source: '',
};

const AdminProjectPanel = ({ userId }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [newProject, setNewProject] = useState(DEFAULT_PROJECT_STATE);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    content: '',
    projectId: null,
  });

  const fetchProjectsData = useCallback(async () => {
    setLoading(true);
    try {
      const projectsData = await fetchProjects();
      setProjects(projectsData);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setToast({
        open: true,
        message: 'Error loading projects. Please try again.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchProjectsData();
  }, [fetchProjectsData]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewProject(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddProject = async () => {
    try {
      const createdProject = await createProject(userId, newProject);
      setProjects([...projects, createdProject]);
      resetForm();
      setToast({
        open: true,
        message: 'Project added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding project:', error);
      setToast({
        open: true,
        message: 'Error adding project. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleUpdateProject = async () => {
    try {
      const updatedProject = await updateProject(
        selectedProject.id,
        newProject,
      );
      setProjects(
        projects.map(project =>
          project.id === selectedProject.id ? updatedProject : project,
        ),
      );
      resetForm();
      setToast({
        open: true,
        message: 'Project updated successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error updating project:', error);
      setToast({
        open: true,
        message: 'Error updating project. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleDeleteClick = projectId => {
    const projectToDelete = projects.find(project => project.id === projectId);
    setConfirmDialog({
      open: true,
      title: 'Confirm Project Deletion',
      content: `Are you sure you want to delete the project "${projectToDelete.name}"? This action cannot be undone.`,
      projectId: projectId,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteProject(confirmDialog.projectId);
      setProjects(
        projects.filter(project => project.id !== confirmDialog.projectId),
      );
      setToast({
        open: true,
        message: 'Project deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting project:', error);
      setToast({
        open: true,
        message: 'Error deleting project. Please try again.',
        severity: 'error',
      });
    } finally {
      setConfirmDialog({ ...confirmDialog, open: false });
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const handleEditProject = projectId => {
    const projectToEdit = projects.find(project => project.id === projectId);
    setSelectedProject(projectToEdit);
    setNewProject(projectToEdit);
  };

  const handleSelectChange = event => {
    const { name, value } = event.target;
    setNewProject(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setSelectedProject(null);
    setNewProject(DEFAULT_PROJECT_STATE);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast({ ...toast, open: false });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: params => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditProject(params.row.id)}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'location', headerName: 'Location', width: 150 },
    {
      field: 'construction_status',
      headerName: 'Construction Status',
      width: 180,
    },
    {
      field: 'total_acres',
      headerName: 'Total Acres',
      width: 120,
      type: 'number',
    },
    {
      field: 'number_of_units',
      headerName: 'Number of Units',
      width: 150,
      type: 'number',
    },
    { field: 'project_type', headerName: 'Project Type', width: 150 },
    { field: 'project_sub_type', headerName: 'Project Subtype', width: 150 },
    { field: 'disclaimer', headerName: 'Disclaimer', width: 150 },
    { field: 'start_date', headerName: 'Start Date', width: 120 },
    { field: 'end_date', headerName: 'End Date', width: 120 },
    { field: 'last_updated', headerName: 'Last Updated', width: 150 },
    { field: 'data_source', headerName: 'Data Source', width: 150 },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Manage Projects
      </Typography>
      <Box sx={{ flexGrow: 1, mb: 2, height: 400 }}>
        <DataGridPremium
          rows={projects}
          columns={columns}
          pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          checkboxSelection
          disableSelectionOnClick
          loading={loading}
          getRowId={row => row.name}
          initialState={{
            pinnedColumns: {
              left: [columns[0].field, columns[1].field, columns[2].field],
              right: [],
            },
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              label="Project Name"
              value={newProject.name}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="description"
              label="Description"
              value={newProject.description}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="start_date"
              label="Start Date"
              type="date"
              value={newProject.start_date}
              onChange={handleInputChange}
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="end_date"
              label="End Date"
              type="date"
              value={newProject.end_date}
              onChange={handleInputChange}
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="location"
              label="Location"
              value={newProject.location}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Construction Status</InputLabel>
              <Select
                name="construction_status"
                value={newProject.construction_status}
                onChange={handleSelectChange}
                label="Construction Status"
              >
                {construction_statuses.map(status => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="total_acres"
              label="Total Acres"
              type="number"
              value={newProject.total_acres}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="number_of_units"
              label="Number of Units"
              type="number"
              value={newProject.number_of_units}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Project Type</InputLabel>
              <Select
                name="project_type"
                value={newProject.project_type}
                onChange={handleSelectChange}
                label="Project Type"
              >
                {project_types.map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Project Subtype</InputLabel>
              <Select
                name="project_sub_type"
                value={newProject.project_sub_type}
                onChange={handleSelectChange}
                label="Project Subtype"
              >
                {project_sub_types.map(subtype => (
                  <MenuItem key={subtype} value={subtype}>
                    {subtype}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="last_updated"
              label="Last Updated"
              type="date"
              value={newProject.last_updated}
              onChange={handleInputChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Data Source</InputLabel>
              <Select
                name="data_source"
                value={newProject.data_source}
                onChange={handleSelectChange}
                label="Data Source"
              >
                {data_sources.map(source => (
                  <MenuItem key={source} value={source}>
                    {source}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="disclaimer"
              label="Project Disclaimer"
              value={newProject.disclaimer}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {selectedProject ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateProject}
                >
                  Update Project
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetForm}
                >
                  Cancel Edit
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddProject}
              >
                Add Project
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={handleCloseToast}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        content={confirmDialog.content}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Box>
  );
};

export default AdminProjectPanel;
