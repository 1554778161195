import WarningIcon from '@mui/icons-material/Warning';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
} from '@mui/material';
import React from 'react';

import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const ProjectInfoBadge = ({ projectDetails }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  if (!projectDetails) {
    return (
      <Card
        sx={{
          minWidth: 300,
          backgroundColor: 'var(--card)',
          color: 'var(--foreground)',
        }}
      >
        <CardContent>
          <Typography variant="h6">No project selected</Typography>
        </CardContent>
      </Card>
    );
  }

  const isDataSourceInternal = projectDetails.data_source === 'Internal';

  return (
    <>
      <Alert
        severity="warning"
        icon={<WarningIcon />}
        sx={{
          mb: 2,
          '& .MuiAlert-message': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Typography variant="body2">
          {projectDetails.disclaimer ||
            'Disclaimer: This is a sample disclaimer.'}
        </Typography>
      </Alert>
      <Card
        sx={{
          minWidth: 300,
          borderRadius: '8px',
          boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
          backgroundColor: 'var(--card)',
          color: 'var(--foreground)',
        }}
      >
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" fontWeight="bold" component="div">
              {projectDetails.name}
            </Typography>
            <Chip label="Active" color="success" size="large" />
          </Box>

          <Typography
            variant="body2"
            component="div"
            gutterBottom
            style={{ color: 'var(--foreground)' }}
          >
            Location: {projectDetails.location}
          </Typography>

          <Divider
            style={{
              margin: '12px 0',
              backgroundColor: 'var(--muted-foreground)',
            }}
            sx={{ my: 1 }}
          />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Typography variant="body2">
              Project Type: {projectDetails.project_type}
            </Typography>
            <Typography variant="body2" style={{ color: 'var(--foreground)' }}>
              {projectDetails.project_sub_type}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Box>
              <Typography
                variant="body2"
                style={{ color: 'var(--accent-color)' }}
              >
                Start Date:{' '}
                {new Date(projectDetails.start_date).toLocaleDateString()}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: 'var(--accent-color)' }}
              >
                End Date:{' '}
                {new Date(projectDetails.end_date).toLocaleDateString()}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Chip
                label={`Last Updated: ${projectDetails.last_updated}`}
                color="primary"
                size="small"
                // sx={{ mb: 1 }}
              />
              <Chip
                label={`Data Source: ${projectDetails.data_source || 'Internal'}`}
                size="small"
                // variant="outlined"
                sx={{
                  borderColor: 'var(--foreground)',
                  backgroundColor: 'var(--transparent)',
                  color: isDataSourceInternal ? 'green' : 'red',
                  fontWeight: 'lighter',
                  '& .MuiChip-label': {
                    color: 'inherit',
                  },
                }}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectInfoBadge;
