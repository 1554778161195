import React, { useState } from 'react';

import { subscribeToWaitlist } from '../../api/fetchers';
import Loading from './Loading';

const SignupModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    consent: false,
    agreeToTerms: false,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await subscribeToWaitlist(formData);
      setSuccess(
        'Thank you for signing up! You will be contacted soon about accessing the platform.',
      );
      setLoading(false);
      // Remove the onClose() call here
    } catch (error) {
      setError('Failed to sign up. Please try again.');
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white dark:bg-gray-900 p-8 rounded-lg shadow-lg z-10 max-w-md w-full">
        {loading ? (
          <Loading message="Signing up..." />
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-200 text-center">
              Sign Up for Beta Access
            </h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {success ? (
              <p className="text-gray-800 dark:text-gray-200 mb-4">{success}</p>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label
                    className="block text-gray-700 dark:text-gray-300 font-bold mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <label
                    className="block text-gray-700 dark:text-gray-300 font-bold mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <label
                    className="block text-gray-700 dark:text-gray-300 font-bold mb-2"
                    htmlFor="company"
                  >
                    Company
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                <div className="flex items-start">
                  <input
                    type="checkbox"
                    id="consent"
                    name="consent"
                    checked={formData.consent}
                    onChange={handleChange}
                    className="mt-1 mr-2"
                    required
                  />
                  <label
                    htmlFor="consent"
                    className="text-sm text-gray-600 dark:text-gray-400"
                  >
                    I agree to receive updates about the platform. My data will
                    only be used to contact me about accessing the platform.
                  </label>
                </div>
                <div className="flex items-start">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    name="agreeToTerms"
                    checked={formData.agreeToTerms}
                    onChange={handleChange}
                    className="mt-1 mr-2"
                    required
                  />
                  <label
                    htmlFor="agreeToTerms"
                    className="text-sm text-gray-600 dark:text-gray-400"
                  >
                    I agree to the{' '}
                    <a
                      href="/terms-of-service"
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      href="/privacy-policy"
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    .
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-700 focus:outline-none transition-colors duration-300"
                  disabled={!formData.consent || !formData.agreeToTerms}
                >
                  Sign Up
                </button>
              </form>
            )}
            {success && (
              <button
                onClick={onClose}
                className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-700 focus:outline-none transition-colors duration-300 mt-4"
              >
                Close
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SignupModal;
