import {
  HealthAndSafetyTwoTone as AdminShieldIcon,
  GppGoodTwoTone as ShieldIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAdminMode } from '../../contexts/AdminModeContext';
import useTheme from '../../hooks/useTheme';
import pythonAxios from '../../services/axiosConfigPython';
import '../../styles/index.css';

const CustomBackdrop = React.forwardRef(function CustomBackdrop(props, ref) {
  return (
    <div
      ref={ref}
      {...props}
      className="fixed inset-0 bg-black bg-opacity-80 z-50"
    />
  );
});

const AuthStatus = () => {
  const [status, setStatus] = useState('checking');
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const { isAdmin } = useAdminMode();

  const checkAuthStatus = async () => {
    try {
      await pythonAxios.get('/auth/check-auth');
      setStatus('authenticated');
      setIsAuthenticated(true);
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        setStatus('unauthenticated');
        setIsAuthenticated(false);
        setIsModalOpen(true);
      }
    }
  };

  useEffect(() => {
    checkAuthStatus();
    const interval = setInterval(checkAuthStatus, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const getTooltipTitle = () => {
    if (!isAuthenticated) return 'User not authenticated';
    return isAdmin ? 'Admin user authenticated' : 'User authenticated';
  };

  const IconComponent = isAdmin ? AdminShieldIcon : ShieldIcon;

  return (
    <>
      <Tooltip title={getTooltipTitle()} placement="bottom">
        <IconButton
          size="small"
          sx={{
            color: isAuthenticated ? 'success.main' : 'error.main',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <IconComponent
            sx={{
              fontSize: '1.2rem',
              opacity: isAuthenticated ? 0.7 : 1,
              animation: isAuthenticated ? 'pulse 2s infinite' : 'none',
              position: 'relative',
              top: '-0.5px',
            }}
          />
        </IconButton>
      </Tooltip>
      <Modal
        open={isModalOpen}
        slots={{
          backdrop: CustomBackdrop,
        }}
        disableEscapeKeyDown
        disableAutoFocus
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'var(--bg-secondary)',
            color: 'var(--foreground)',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            zIndex: 60,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 2, fontWeight: 'bold', color: 'var(--foreground)' }}
          >
            Session Expired
          </Typography>
          <Typography sx={{ mb: 3, color: 'var(--foreground)' }}>
            Your session has expired. Please log in again to continue.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleLogout}
              variant="contained"
              sx={{
                bgcolor: 'var(--bg-button-default)',
                '&:hover': {
                  bgcolor: 'var(--bg-button-secondary)',
                },
              }}
            >
              Log In
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AuthStatus;
