import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import '../../styles/index.css';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(1),
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    border: 0,
    '&:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
    },
    '&:first-of-type': {
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    '&:last-of-type': {
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  padding: theme.spacing(0.5, 1),
  fontSize: '0.75rem',
  fontWeight: 'bold',
  minWidth: '32px',
  minHeight: '36px',
}));

const DataGridQuickFilterButtonGroupDynamic = ({
  activeFilter,
  handleQuickFilter,
  kpis,
}) => {
  // Group KPIs
  const projectKpis = kpis.slice(0, 3);
  const otherKpis = kpis.slice(3);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '2px',
        mb: 2,
      }}
    >
      <StyledToggleButtonGroup
        size="small"
        value={activeFilter}
        exclusive
        onChange={(event, newFilter) => handleQuickFilter(newFilter)}
        aria-label="kpis filter"
      >
        {projectKpis.map(kpi => (
          <StyledToggleButton key={kpi} value={kpi} aria-label={kpi}>
            {kpi}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
      <StyledToggleButtonGroup
        size="small"
        value={activeFilter}
        exclusive
        onChange={(event, newFilter) => handleQuickFilter(newFilter)}
        aria-label="kpis filter"
      >
        {otherKpis.map(kpi => (
          <StyledToggleButton key={kpi} value={kpi} aria-label={kpi}>
            {kpi}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Box>
  );
};

export default DataGridQuickFilterButtonGroupDynamic;
