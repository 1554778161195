import { pythonAxios } from '../services/axiosConfigPython';

export const ACCESS_TOKEN = 'access_token';

export const loginUser = async (email, password) => {
  try {
    const response = await pythonAxios.post('/auth/login', {
      email: email,
      password: password,
    });
    sessionStorage.setItem(ACCESS_TOKEN, response.data.access_token);
    return response.data;
  } catch (error) {
    console.error(
      'Error logging in:',
      error.response ? error.response.data : error.message,
    );
    throw new Error('Login failed');
  }
};
