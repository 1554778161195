import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { fetchUserProjects } from '../../api/fetchers';
import ThemeContext from '../../contexts/ThemeContext';

const ProjectSelector = ({ userId, onProjectSelect, selectedProjectId }) => {
  const [projects, setProjects] = useState([]);
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const userProjects = await fetchUserProjects(userId);
        setProjects(userProjects);

        if (userProjects.length > 0 && !selectedProjectId) {
          const defaultProject = userProjects[0];
          onProjectSelect(defaultProject.id);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
        setProjects([]);
      }
    };

    if (userId) {
      fetchProjects();
    }
  }, [userId, onProjectSelect, selectedProjectId]);

  const handleProjectChange = event => {
    const projectId = event.target.value;
    onProjectSelect(projectId);
  };

  return (
    <FormControl
      fullWidth
      margin="normal"
      sx={{
        minWidth: 200,
        '& .MuiInputBase-root': {
          borderRadius: '8px',
        },
        '& .MuiSelect-icon': {
          right: 8,
          color: isDarkMode ? 'var(--border-light)' : 'inherit',
        },
      }}
    >
      <Typography
        variant="caption"
        sx={{
          marginBottom: '4px',
          color: isDarkMode ? 'var(--border-light)' : 'textPrimary',
        }}
      >
        Select Project
      </Typography>
      <Select
        value={selectedProjectId || ''}
        onChange={handleProjectChange}
        sx={{
          '& .MuiSelect-select': {
            padding: '8px 14px',
            backgroundColor: isDarkMode
              ? 'var(--bg-secondary-dark)'
              : 'var(--bg-secondary-light)',
            color: isDarkMode ? '#fff' : '#000',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
            borderColor: isDarkMode ? '#ffffff' : 'var(--border-dark)',
          },
        }}
      >
        {projects.map(project => (
          <MenuItem key={project.id} value={project.id}>
            {project.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProjectSelector;
